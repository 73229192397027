import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Dialog, Paper, AppBar, Toolbar, IconButton } from '@mui/material';
import { obtenerAbonos } from '../Services/facturasServices';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const AlertaAbono = ({ selectedValue, open, onClose }) => {
    const [datos, setDatos] = useState([])

    const obtenerData = async (idFacSec) => {
        const res = await obtenerAbonos(idFacSec)
        setDatos(res)
    }

    useEffect(() => {
        if (selectedValue) {
            obtenerData(selectedValue);
        } else {
            setDatos([]); // Limpia los datos cuando se borra `selectedValue`
        }
    }, [selectedValue]);

    const formatoFecha = useCallback((fechaISO) => {
        const fecha = new Date(fechaISO);
        return format(fecha, "d MMM, h:mm a", { locale: es });
    }, []);


    // Estilo de la cabezera de la tabla
    const getCommonMuiTableHeadCellProps = (backgroundColor) => ({
        align: 'left',
        sx: {
            backgroundColor,
        },
    });

    // Formato de moneda
    const formatoMoneda = useCallback((valor) => {
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0,
        }).format(valor);
    }, []);

    const columns = [
        {
            accessorKey: 'Documento',
            header: 'Factura',
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#F5F5F7'),
        },
        {
            accessorKey: 'tbwpref',
            header: 'Referencia',
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#F5F5F7'),
            Cell: ({ cell }) => {
                return (
                    <Typography noWrap variant='body2' title={cell.row.original.tbwpref}
                        style={{
                            textTransform: 'capitalize',
                            width: "300px", maxWidth: "300px"
                        }}>
                        {cell.row.original.tbwpref}
                    </Typography>
                )
            },
        },
        {
            accessorKey: 'tbwpfecfin',
            header: 'Fecha y hora',
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#F5F5F7'),
            Cell: ({ cell }) => (
                <Box display={'flex'} sx={{ gap: 1, alignItems: "center", }}>
                    <AccessTimeIcon />
                    <Typography variant='body2'>
                        {formatoFecha(cell.row.original.tbwpfecfin)}
                    </Typography>
                </Box>
            ),
        },
        {
            accessorKey: 'tbwprefval',
            header: 'Abono',
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#F5F5F7'),
            Cell: ({ cell }) => `${formatoMoneda(cell.row.original?.tbwprefval)}`,
        },
    ]

    const table = useMaterialReactTable({
        columns,
        data: datos,
        muiTablePaperProps: {
            elevation: 0
        },
        enableFilters: false, // Oculta los filtros de la tabla
        enableHiding: false, // Oculta la opción de ocultar columnas
        enableColumnFilters: false, // Oculta los filtros de las columnas
        enableDensityToggle: false, // Oculta el botón de densidad
        enableStickyFooter: true, // Fija el footer de la tabla
        enableStickyHeader: true, // Fija la cabecera de la tabla
        enableFullScreenToggle: false, // Oculta el botón de pantalla completa
        enableTopToolbar: false, // Oculta la barra de herramientas
        localization: MRT_Localization_ES,
        initialState: { density: 'compact' },
        muiTableContainerProps: { sx: { height: "50vh", flexGrow: 1 } }
    })
    return (
        <Dialog onClose={() => onClose()} open={open}
            // fullScreen
            maxWidth="lg"
            sx={{ margin: 5 }}
        >
            {/* <Typography>Hola mundo</Typography> */}

            <AppBar sx={{
                position: 'relative', height: "50px", alignContent: "center", justifyContent: "center",
                backgroundColor: "#1976d2", color: "white"
            }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => onClose()}
                        // onClose={}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} component="div">
                        Abonos de la factura
                    </Typography>
                    {/* <Button autoFocus color="inherit" onClose={onClose}>
                        save
                    </Button> */}
                </Toolbar>
            </AppBar>

            <Box sx={{ padding: 2 }}>
                <Paper variant="outlined" sx={{ padding: 1, marginTop: 1, borderRadius: "8px", }}>
                    <MaterialReactTable
                        table={table} //only pass in table instead of all table options
                        localization={MRT_Localization_ES}
                    />
                </Paper>
            </Box>
        </Dialog>
    );
};

export default AlertaAbono;