import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import PersonPinRoundedIcon from '@mui/icons-material/PersonPinRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import WalletIcon from '@mui/icons-material/Wallet';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CustomGridItem from '../../components/CustomGridItem';
import Grid from '@mui/material/Grid2';

const GestionComercial = () => {

    const gridItems = [
        {
            icon: <ApartmentRoundedIcon style={{ fontSize: "45px" }} />, title: "Visual empresa",
            link: "/Farmanet/GestionComercial/VisualEmpresa",
            ruta: "Gestión comercial"
        },
        {
            icon: <QueryStatsIcon style={{ fontSize: "45px" }} />, title: "Presupuesto",
            link: "/Farmanet/GestionComercial/PresupuestoComercial",
            ruta: "Gestión comercial"
        },
        {
            icon: <AttachMoneyRoundedIcon style={{ fontSize: "40px" }} />, title: "Ventas",
            link: "/Farmanet/GestionComercial/Ventas",
            ruta: "Gestión comercial"
        },
        {
            icon: <WalletIcon style={{ fontSize: "40px" }} />, title: "Cartera",
            link: "/Farmanet/GestionComercial/Cartera",
            ruta: "Gestión comercial"
        },
        // Add more grid items as needed
    ];

    return (
        <div>
            <Box >
                <Grid item xs={12} sm={6} md={12} >
                    <Box sx={{ alignContent: "center", justifyContent: "start", display: "flex", alignItems: "center" }}>
                        <Typography variant="body1" color="black" gutterBottom style={{
                            display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center",
                            marginBottom: "1em",
                        }}>
                            <PersonPinRoundedIcon style={{ marginRight: "5px", color: "#FE0000" }} />
                            Gestión Comerial
                        </Typography>
                    </Box>
                </Grid>

                <Grid container spacing={2}>
                    {gridItems.map((item, index) => (
                        <CustomGridItem key={index} {...item} />
                    ))}
                </Grid>
            </Box>


        </div>
    )
}

export default GestionComercial