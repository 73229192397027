import React, { useCallback } from 'react'
import Grid from '@mui/material/Grid2';
import { Alert, Box, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const DetalleFactura = ({ mostrarDetalles, datosReferencia,
    setDatosReferencia, setMostrarDetalles, loading, error }) => {

    // Esrtructura detalles del pago
    const TableRowData = ({ label, value }) => (
        <TableRow>
            <TableCell sx={{ maxWidth: 150, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                <Typography variant='body2' noWrap title={label}>
                    {label}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <Typography variant='body2' noWrap>
                    {value || 'No disponible'}
                </Typography>
            </TableCell>
        </TableRow>
    );

    // Estructura del formato de peso con decimales y el signo del peso $
    const formatoMonedaReferencia = useCallback((valor) => {
        // Divide el valor entre 100 para convertir de centavos a pesos
        const valorEnPesos = valor / 100;
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0,
        }).format(valorEnPesos);
    }, []);

    // Formato del estado de la transacción
    const formatoEstado = (estado, cruce) => {
        if (cruce === null && estado === "APPROVED") {
            // return "Pagada - Cruzada";
            return <div>
                Pagada - <strong>Cruzada</strong>
            </div>;
        } else {
            switch (estado) {
                case "APPROVED":
                    return "Pagada";
                case "DECLINED":
                    return "Declinada";
                case "ERROR":
                    return "Error";
                default:
                    return estado; // Retorna el valor original si no coincide con ningún caso
            }
        }
    };

    const formatoFecha = useCallback((fechaISO) => {
            const fecha = new Date(fechaISO);
            return format(fecha, "d MMM, h:mm a", { locale: es });
        }, []);

    return (
        <>
            <Grid size="grow">
                <Collapse in={mostrarDetalles} timeout="auto" >
                    <Paper variant="outlined"
                        sx={{
                            p: 2, borderRadius: "8px", marginTop: 1, height: "70vh", overflow: "auto",
                            WebkitBoxShadow: "0px 0px 30px -23px rgba(20,69,227,1)",
                            boxShadow: "0px 0px 30px -23px rgba(20,69,227,1)",
                        }}>
                        <Box sx={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                            <Typography>Detalles del pago</Typography>
                            <IconButton
                                onClick={() => {
                                    setDatosReferencia(null); // Limpia los datos del detalle
                                    setMostrarDetalles(false); // Oculta el panel de detalles
                                }}
                                aria-label="delete">
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        {/* Manejo de estados */}
                        {loading ? (
                            <Alert severity="info" style={{ marginTop: 2 }}>Cargando detalles...</Alert>
                        ) : error ? (
                            <Alert severity="error" style={{ marginTop: 2 }}>{error}</Alert>
                        ) : !datosReferencia ? (
                            <Alert severity="warning" style={{ marginTop: 2 }}>No hay detalles disponibles.</Alert>
                        ) : (
                            <>
                                {/* Detalles del pago */}
                                <TableContainer component={Paper} elevation={0}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            paddingLeft: 2, paddingRight: 2,
                                            background: "#e5f6fd", height: "45px", marginTop: 0.5, borderRadius: "4px"
                                        }}
                                    >
                                        <Typography noWrap sx={{ flex: 1, textAlign: "left", display: "flex", alignItems: "center", gap: 2 }}>
                                            <InfoOutlinedIcon sx={{ color: "#0288d1" }} />  Monto
                                        </Typography>
                                        <Typography noWrap sx={{ flex: 1, textAlign: "right" }}>
                                            COP {formatoMonedaReferencia(datosReferencia.amount_in_cents)}
                                        </Typography>
                                    </Box>

                                    <Table aria-label="simple table" size="small">
                                        <TableBody>
                                            <TableRowData label="Estado" value={formatoEstado(datosReferencia?.status)} />
                                            <TableRowData label="Fecha de finalización" value={formatoFecha(datosReferencia?.finalized_at)} />
                                            <TableRowData label="Transacción" value={datosReferencia?.id} />
                                            <TableRowData label="Medio de pago" value={datosReferencia?.payment_method_type} />
                                            <TableRowData label="Referencia" value={datosReferencia?.reference} />
                                        </TableBody>

                                        <Box sx={{ marginTop: "1em", marginBottom: "1em" }}>
                                            <Typography noWrap>Información del comprador:</Typography>
                                        </Box>

                                        <TableBody>
                                            <TableRowData label="Email" value={datosReferencia?.customer_email} />
                                            <TableRowData label="Nombres y apellidos" value={datosReferencia?.customer_data?.full_name || 'No disponible'} />
                                            <TableRowData label="Número telefónico o celular" value={datosReferencia?.customer_data?.phone_number || 'No disponible'} />
                                        </TableBody>

                                        <Box sx={{ marginTop: "1em", marginBottom: "1em" }}>
                                            <Typography noWrap>Detalles del autorizador:</Typography>
                                        </Box>

                                        <TableBody>
                                            <TableRowData label="ID Intención de compra en Bancolombia"
                                                value={datosReferencia?.payment_method?.extra?.external_identifier} />
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}
                    </Paper>
                </Collapse>
            </Grid>
        </>
    )
}

export default DetalleFactura