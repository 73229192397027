import { Box, Card, CardActionArea, CardContent, Divider, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate

const CustomGridItem = ({ icon, title, link, ruta, navigate }) => {
    const navigateUrl = useNavigate(); // Asegúrate de usar useNavigate aquí

   return (
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
            <Card variant="outlined" 
                sx={{ display: 'flex',
                    height: "150px",
                    borderRadius: "13px",
                    // borderWidth: 0,
                    // border: "none",
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                        transform: 'scale(1.01)', // Efecto de agrandamiento
                        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)', // Efecto de sombra más profundo
                    }
                }}
            >
                <CardActionArea onClick={() => navigateUrl(link)} sx={{ display: "flex" }}>
                    <Grid size={{ xs: 3, sm: 3, md: 3, lg: 3 }}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="100%"
                        >
                            {icon}
                        </Box>
                    </Grid>
                    <Divider orientation='vertical' style={{ height: "80px" }} />
                    <Grid
                        size={{ xs: 9, sm: 9, md: 9, lg: 9 }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography sx={{ fontSize: 14, color: "#FE0000", }} color="text.secondary" gutterBottom>
                                    {ruta}
                                </Typography>
                                <Typography variant="h5" component="div" style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                                }}>
                                    {title}
                                </Typography>
                            </CardContent>
                        </Box>
                    </Grid>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

export default CustomGridItem;