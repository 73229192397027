import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

const SelectorPanel = ({
    añoSelect, selectAñoIncial, añoInicial, meses, mes, handleChange,
    indicadoresNombre, selectedIndicador, handleChangeNombre, alignment, handleChangeTipo, nombreInd
}) => {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" }, // Column on mobile, row on larger screens
                    justifyContent: "space-between", // Space between elements for larger screens
                    alignItems: { xs: "flex-start", md: "center" }, // Align to the left on mobile
                    gap: 2, // Spacing between elements
                }}
            >
                {/* Left Section - Dropdown Selectors */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" }, // Stack vertically on mobile
                        gap: 2, // Add spacing between dropdowns
                    }}
                >
                    <FormControl
                        sx={{
                            width: { xs: "100%", md: "200px" },
                            background: "white",
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "#e0e0e0",
                                    borderRadius: "8px",
                                },
                            },
                        }}
                        size="small"
                    >
                        <InputLabel>Año</InputLabel>
                        <Select
                            label="Año"
                            value={selectAñoIncial}
                            onChange={(event) => añoInicial(event.target.value)}
                        >
                            {añoSelect.map((año) => (
                                <MenuItem value={año.PerMesano} key={año.PerMesano}>
                                    {año.PerMesano}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl
                        sx={{
                            width: { xs: "100%", md: "200px" },
                            background: "white",
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "#e0e0e0",
                                    borderRadius: "8px",
                                },
                            },
                        }}
                        size="small"
                    >
                        <InputLabel>Mes Inicial</InputLabel>
                        <Select
                            label="Mes Inicial"
                            value={mes}
                            onChange={handleChange}
                        >
                            {meses.map((item, index) => (
                                <MenuItem value={index + 1} key={index}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {/* Right Section - Buttons */}
                <Box
                    sx={{
                        display: "flex",
                        gap: 1, // Spacing between buttons
                        flexDirection: { xs: "column", md: "row" }, // Stack buttons vertically on mobile
                        width: { xs: "100%", md: "auto" }, // Full width on mobile, auto on larger screens
                        marginTop: { xs: "16px", md: 0 }, // Add margin on mobile for spacing
                    }}
                >
                    <Button
                        // size='small'
                        variant="outlined"
                        onClick={() => handleChangeTipo(null, 'ESTRA')}
                        sx={{
                            width: { xs: "100%", md: "200px", }, // 100% para xs y 200px para md y superior
                            borderRadius: "8px",
                            borderColor: "#e4e4e7",
                            borderWidth: "2px",
                            backgroundColor: alignment === 'ESTRA' ? "#1976d2" : "white",
                            color: alignment === 'ESTRA' ? "white" : "black",
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            '&:hover': {
                                borderColor: "#e4e4e7",
                                backgroundColor: alignment === 'ESTRA' ? "#115293" : "transparent",
                                boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)',
                            }
                        }}
                    >
                        Estrategicos
                    </Button>

                    <Button
                        // size='small'
                        variant="outlined"
                        onClick={() => handleChangeTipo(null, 'OPERA')}
                        sx={{
                            width: { xs: "100%", md: "200px", }, // 100% para xs y 200px para md y superior
                            borderRadius: "8px",
                            borderColor: "#e4e4e7",
                            borderWidth: "2px",
                            backgroundColor: alignment === 'OPERA' ? "#1976d2" : "white",
                            color: alignment === 'OPERA' ? "white" : "black",
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            '&:hover': {
                                borderColor: "#e4e4e7",
                                backgroundColor: alignment === 'OPERA' ? "#115293" : "transparent",
                                boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)',
                            }
                        }}
                    >
                        Operativos
                    </Button>
                </Box>
            </Box>


        </>
    );
};

export default SelectorPanel;

