import { Typography, Grid, Paper, Box, List, ListItem, ListItemText } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react'

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MultilineChartRoundedIcon from '@mui/icons-material/MultilineChartRounded';
import PaidIcon from '@mui/icons-material/Paid';

import newRequest from '../../../utils/newRequest';
import CardInfo from './CardInfo ';
import { GraficaBarras } from './Graficas/GraficaBarras';
import { GraficaTorta } from './Graficas/GraficaTorta';
import CircleIcon from '@mui/icons-material/Circle';
import { TablaTopVendedores } from './TablaTopVendedores/TablaTopVendedores';
import { AuthContext } from '../../../loginContext';

export const VistaVendedores = () => {
    const { currentUser } = useContext(AuthContext);

    const dia = new Date();
    const mes = dia.toLocaleString('default', { month: 'long' });
    const mesCapitalizado = mes.charAt(0).toUpperCase() + mes.slice(1);

    const [datVendedor, setDatVendedor] = useState([])
    const [datVendedorTabla, setDatVendedorTabla] = useState([])
    const [datVendedorGrafica, setDatVendedorGrafica] = useState([])
    const [datVendedorRanking, setDatVendedorRanking] = useState([])
    const [datVendedorProducto, setDatVendedorProducto] = useState([])

    const formatNumber = (value) => new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);

    const fetchData = useCallback(async () => {

        const datos = {
            vendedor: currentUser.userData?.UsuNom
        }

        try {
            const [
                { data: vendedorData },
                { data: tablaData },
                { data: graficaData },
                { data: rankingData },
                { data: productoData }
            ] = await Promise.all([
                newRequest.post('/api/Inicio/datoVendedores', { datos }),
                newRequest.post('/api/Inicio/datoVendedoresTabla', { datos }),
                newRequest.post('/api/Inicio/datoVendedorDiagrama', { datos }),
                newRequest.post('/api/Inicio/datoVendedorRanking', { datos }),
                newRequest.post('/api/Inicio/datoVendedorProducto', { datos })
            ]);

            setDatVendedor(vendedorData[0]);
            setDatVendedorTabla(tablaData);
            setDatVendedorGrafica(graficaData);
            setDatVendedorRanking(rankingData);
            setDatVendedorProducto(productoData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, [currentUser.userData?.UsuNom]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    const vendedorVenPre = datVendedor.Ventas2024 && datVendedor.Presupuesto2024
        ? (datVendedor.Ventas2024 / datVendedor.Presupuesto2024 * 100).toFixed(2)
        : 0;

    const cardsData = [
        {
            color: '#495eff',
            icon: AttachMoneyIcon,
            title: 'Total obtenidos del mes de Junio',
            value: `$${formatNumber(datVendedor.Ventas2024)}`,
            secondaryText: 'Total de ventas',
            link: "/Farmanet/GestionComercial/VisualEmpresa"
        },
        {
            color: '#33d774',
            icon: PaidIcon,
            title: '',
            value: `$${formatNumber(datVendedor.Presupuesto2024)}`,
            secondaryText: 'Total de presupuesto',
            link: "/Farmanet/GestionComercial/VisualEmpresa"
        },
        {
            color: '#ff9c27',
            icon: MultilineChartRoundedIcon,
            title: '',
            value: `${vendedorVenPre}%`,
            secondaryText: 'Cumpl.. presupuesto',
            link: "/Farmanet/GestionComercial/VisualEmpresa"
        }
    ];

    const colors = ['#495eff', '#33d774', '#ff9c27', '#ff5733', '#c70039', '#900c3f'];

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography sx={{ fontSize: 14, color: "#d4d4d4" }}
                    color="text.secondary" gutterBottom>
                    Total obtenidos del mes de:
                </Typography>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant='h2'>
                        {mesCapitalizado}
                    </Typography>
                </div>
            </Grid>

            {cardsData.map((card, index) => (
                <CardInfo
                    key={index}
                    color={card.color}
                    icon={card.icon}
                    title={card.title}
                    value={card.value}
                    secondaryText={card.secondaryText}
                    link={card.link}
                />
            ))}

            <Grid item xs={12} sm={6} md={6} lg={5}>
                <Paper elevation={0} variant='outlined' style={{ borderRadius: "12px", position: "relative" }}>
                    <GraficaBarras datVendedorTabla={datVendedorTabla} />
                </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4}>
                <Paper elevation={0} variant='outlined' style={{ borderRadius: "12px", position: "relative" }}>
                    <GraficaTorta datVendedorGrafica={datVendedorGrafica} />
                </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
                {/* marginTop: "1rem" */}
                <Paper elevation={0} variant='outlined' style={{ borderRadius: "12px", position: "relative", height: "47.8vh" }}>
                    <Typography variant='h6' fontSize={16} fontWeight="bold" sx={{ position: 'absolute', top: 10, left: 16 }}>
                        Top productos vendidos
                    </Typography>

                    <Box style={{ paddingTop: "2rem" }}>
                        <nav aria-label="secondary mailbox folders">
                            <List
                                sx={{
                                    width: '100%',
                                    height: "330px",
                                }}
                            >
                                {datVendedorProducto.map((item, index) => (
                                    <ListItem disablePadding key={index} sx={{ marginLeft: 1, marginTop: 2 }}
                                        secondaryAction={
                                            <div>
                                                ${formatNumber(item.Ventas2024)}
                                            </div>
                                        }
                                    >
                                        <CircleIcon
                                            sx={{
                                                fontSize: 14,
                                                marginRight: 1,
                                                color: colors[index % colors.length]
                                            }}
                                        />

                                        <ListItemText primary={
                                            <Typography
                                                noWrap
                                                style={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    width: '160px', // Ensure the text takes full width
                                                }}
                                            >
                                                {item.producto}
                                            </Typography>
                                        } />
                                    </ListItem>
                                ))}
                            </List>
                        </nav>

                    </Box>
                </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={12}>
                <Paper elevation={0} variant='outlined' style={{ borderRadius: "12px", position: "relative",  padding: "5px" }}>
                    <TablaTopVendedores datVendedorRanking={datVendedorRanking} formatNumber={formatNumber} />
                </Paper>
            </Grid>
        </Grid >
    )
}
