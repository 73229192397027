import React from 'react'
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import { Box, Breadcrumbs, Fab, Typography } from '@mui/material';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function Tickets() {

    const navigate = useNavigate();

    const handleBack = () => {
        navigate("/Farmanet/GerenciaVisual")
    }


    return (
        <Box>
            <Grid item xs={12} sm={6} md={12} >
                <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <Box sx={{
                        display: "flex", alignItems: "center",
                        justifyContent: "center", marginBottom: "1em"
                    }}>

                        <Fab onClick={handleBack} size='small' style={{
                            background: "white",
                            borderRadius: 13
                        }}>
                            <ChevronLeftIcon />
                        </Fab>

                        <Breadcrumbs aria-label="breadcrumb" style={{ paddingLeft: "15px" }}>
                            <Typography color="text.primary">Administrador</Typography>
                            <Typography color="text.primary">Tickets</Typography>
                        </Breadcrumbs>
                    </Box>
                </Box>
            </Grid>
        </Box>
    )
}

export default Tickets