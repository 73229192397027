import React, { useEffect, useState } from 'react'

import { useParams, useNavigate } from 'react-router-dom';
import { Box, Breadcrumbs, Paper, Typography, Fab, Skeleton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Grid from '@mui/material/Grid2';
import TablaResultadoIndicador from './TablaResultadoIndicador';
import GraficasIndicador from './GraficasIndicador';
import newRequest from '../../../../../../utils/newRequest';

const ResultadoIndicador = () => {

    const { indProEmpSec, tipo, indicador } = useParams();

    const [loading, setLoading] = useState(true);
    // const [loadingDos, setLoadingDos] = useState(true);

    const [datos, setdatos] = useState([])
    const [datosPorIndicador, setdatosPorIndicador] = useState([])
    const [refreshData, setRefreshData] = useState(false); // Estado para controlar el refresco

    const navigate = useNavigate();

    useEffect(() => {
        const ObtenerDatosIndicador = async () => {
            try {
                setLoading(true); // Marcar como cargando al iniciar la solicitud

                const res = await newRequest.get(`/api/GerenciaVisual/Indicadores/ObtenerIndicador/${indicador}/${indProEmpSec}`)

                if (res.data.length) {
                    setdatos(res.data[0])
                } else {
                    setdatos([])
                    // console.log("La respuesta no contiene datos :", res.data);
                }
            } catch (error) {
                console.log("error", error)
            }
            finally {
                setLoading(false); // Marcar como no cargando cuando la solicitud termina (ya sea éxito o error)
            }
        }

        const ResultadoPorIndicador = async () => {
            try {
                // setLoadingDos(true); // Marcar como cargando al iniciar la solicitud
                const res = await newRequest.get(`/api/GerenciaVisual/Indicadores/ResultadoPorIndicador/${indProEmpSec}/${indicador}`)
                if (res.data.length) {
                    setdatosPorIndicador(res.data);
                } else {
                    setdatosPorIndicador([]);
                    // console.log("La respuesta no contiene un array de indicadores:", res.data);
                }
            } catch (error) {
                console.log("error", error)
            }
        }

        ResultadoPorIndicador()
        ObtenerDatosIndicador();
    }, [indProEmpSec, tipo, indicador, refreshData])

    // console.log("Aqui viene los daots")


    const handleRefresh = () => {
        setRefreshData((prev) => !prev); // Alternar el estado para disparar el efecto
    };


    const handleBack = () => {
        navigate(-1)
    }


    return (
        <div>
            <Box>
                <Grid container spacing={2}>

                    <Grid size={12} >
                        <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                            <Box style={{
                                display: "flex", alignItems: "center",
                                justifyContent: "center",
                            }}>

                                <Fab onClick={handleBack} size='small'
                                    style={{ background: "white", borderRadius: 13 }}>
                                    <ChevronLeftIcon />
                                </Fab>


                                <Breadcrumbs maxItems={3} aria-label="breadcrumb" style={{ paddingLeft: "15px" }} >
                                    <Typography color="text.primary">Gerencia visual</Typography>
                                    <Typography color="text.primary">Indicadores</Typography>
                                    <Typography color="text.primary">Linea de tiempo</Typography>
                                    <Typography color="text.primary">Resultado por Indicador</Typography>
                                </Breadcrumbs>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid size={12} >

                        <Box sx={{ marginTop: "1em" }}>
                            <Grid container spacing={2} >
                                <Grid size={{ xs: 12, md: 3 }} sx={{ display: "flex", gap: 5 }}>
                                    <Box sx={{ width: "100%" }}>
                                        {loading ? (
                                            <Skeleton
                                                animation="wave"
                                                width="auto"
                                            />
                                        ) : (
                                            <Typography noWrap>
                                                <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                                                    Nombre indicador:
                                                </span>
                                                {datos?.IndProEmpNom}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>

                                <Grid size={{ xs: 12, md: 3 }}>
                                    <Box sx={{ width: "100%" }}>
                                        {loading ? (
                                            <Skeleton
                                                animation="wave"
                                                width="auto"
                                            />
                                        ) : (
                                            <Typography noWrap sx={{
                                                fontSize: "1.0rem",
                                            }} >
                                                <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                                                    Relación indicador
                                                </span>

                                                {datos?.IndProRel} {datos?.IndProSig} {datos?.IndProRelDos}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>

                                <Grid size={{ xs: 12, md: 3 }}>
                                    <Box sx={{ width: "100%" }}>
                                        {loading ? (
                                            <Skeleton
                                                animation="wave"
                                                width="auto"
                                            />
                                        ) : (
                                            <Typography noWrap sx={{ fontSize: "1.0rem", textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >
                                                <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                                                    Frecuencia:
                                                </span>
                                                {datos?.IndProFre}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>

                                <Grid size={{ xs: 12, md: 3 }}>
                                    <Box sx={{ width: "150px" }}>
                                        {loading ? (
                                            <Skeleton
                                                animation="wave"
                                                width="150px"
                                            />
                                        ) : (
                                            <Typography noWrap >
                                                <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                                                    Meta:
                                                </span>
                                                {datos?.IndProEmpresa} {datos?.IndProEmpresaDos ? ` - ${datos?.IndProEmpresaDos}` : ''}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>

                        <Box>
                            <Grid container spacing={2} sx={{ marginTop: "1rem", }}>
                                <Grid size={{ xs: 12, md: 5 }}>
                                    <Paper elevation={0} variant='outlined' sx={{
                                        borderRadius: "13px", padding: 1
                                    }}>
                                        <GraficasIndicador datos={datos}
                                            datosPorIndicador={datosPorIndicador} />
                                    </Paper>
                                </Grid>
                                <Grid size={{ xs: 12, md: 7 }}  >
                                    <Paper elevation={0} variant='outlined' sx={{
                                        borderRadius: "13px",
                                        padding: 1
                                    }}>
                                        {/* <TablaResultadoIndicador
                                            datosPorIndicador={datosPorIndicador}
                                            datos={datos} loading={loading} /> */}

                                        <TablaResultadoIndicador
                                            datosPorIndicador={datosPorIndicador}
                                            datos={datos}
                                            loading={loading}
                                            onRefresh={handleRefresh} // Pasar función de refresco al hijo
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                {/* )} */}
            </Box>



        </div>
    )
}

export default ResultadoIndicador