import React, { useEffect, useState } from "react";
import './Inicio.css'
import "swiper/css";
import "swiper/css/pagination";
import logoFarma from "../../img/logo-farmacomercial.png"
import neumuc from "../../img/Productos/farma/neumuc.png"
import resgripfar from "../../img/Productos/farma/resgripfar.jpg"
import {Typography,Button,} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import soloLogo from "../../img/soloLogo.png"
import newRequest from "../../../utils/newRequest";

import relacionUno from "../../img/relaciones/Asset-1.png"
import relacionDos from "../../img/relaciones/logo-light-2.png"
import relacionTres from "../../img/relaciones/unnamed.png"
import Footer from "../../../components/Footer/Footer";

const Inicio = () => {
    const [loading, setLoading] = useState(true);
    const [dataProductos, setDataProductos] = useState([]);

    useEffect(() => {
        const productos = async () => {
            try {
                setLoading(true);
                const res = await newRequest.get(`/api/formulario/ProductosFarmaComercial`, {
                    secure: true,
                });
                setDataProductos(res?.data);
            } catch (error) {
                console.error("Error al obtener datos:", error);
            } finally {
                setLoading(false);
            }
        };

        if (!dataProductos.length) {
            productos();
        }

        window.scrollTo(0, 0);
    }, [dataProductos]);

    console.log("dataProductos dataProductos", dataProductos)

    const convertBytesToImageUrl = (bytes) => {
        if (bytes && bytes.length > 0) {
            const blob = new Blob([new Uint8Array(bytes)], { type: 'image/jpeg' });
            return URL.createObjectURL(blob);
        }
        return null;
    };

    const AccordionItem = ({ targetId, title, items }) => (
        <div className="ud-single-faq wow fadeInUp" data-wow-delay=".1s">
            <div className="accordion">
                <button
                    className="ud-faq-btn collapsed w-100 text-start"
                    style={{ height: "50px" }}
                    data-bs-toggle="collapse"
                    data-bs-target={`#${targetId}`}
                >
                    <span className="icon flex-shrink-0">
                        <KeyboardArrowDownIcon />
                    </span>
                    <span>{title}</span>
                </button>
                <div id={targetId} className="accordion-collapse collapse">
                    <div className="ud-faq-body">
                        {items.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <section className="ud-hero" id="home">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-10 col-sm-12">
                            <div className="d-flex align-items-center flex-column flex-md-row">
                                <img src={soloLogo} alt="" style={{
                                    width: "auto",
                                    height: "150px"
                                }} />
                                <div className="text-center text-md-start ms-md-4">
                                    <h1 class="ud-hero-title" style={{ fontSize: "4em", margin: 0 }}>
                                        <span style={{ color: "#1e2f5e" }}>Laboratorio Farma </span>
                                        <span style={{ color: "#d02d3b" }}>Comercial</span>
                                    </h1>
                                    <h2 style={{ margin: 0 }}>Vivir más, Vivir mejor</h2>
                                    <p style={{ marginTop: "1em", fontSize: "1.2em" }}>Labfacom</p>
                                </div>
                            </div>
                            <div className="ud-hero-content mt-3">
                                {/* Puedes añadir contenido adicional aquí */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="testimonials" className="ud-testimonials">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ud-section-title mx-auto text-center">
                                <span>Productos</span>
                                <h2>Nuestros productos</h2>
                            </div>
                        </div>
                    </div>

                    <div
                        id="carouselExample"
                        className="carousel slide"
                        data-bs-ride="carousel"
                    >
                        <div className="carousel-inner">
                            {loading ? (
                                <p>Cargando...</p>
                            ) : (
                                dataProductos
                                    .reduce((acc, producto, index) => {
                                        const imageUrl = convertBytesToImageUrl(producto.ArtImg.data);

                                        // Cada 4 productos crea un nuevo "carousel-item"
                                        if (index % 4 === 0) {
                                            acc.push([]);
                                        }

                                        acc[acc.length - 1].push(
                                            <div
                                                className="col-lg-3 col-md-4 col-sm-6 col-12"
                                                key={index}
                                            >
                                                <div
                                                    className="card"
                                                    style={{
                                                        width: "100%",
                                                        border: "none",
                                                        background: "white",
                                                        marginBottom: "20px",
                                                    }}
                                                >
                                                    <div className="ud-testimonial-image">
                                                        <img
                                                            src={imageUrl || "assets/images/placeholder.png"}
                                                            alt={producto.ArtNom}
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="ud-testimonial-meta">
                                                            <h4>{producto.ArtNom}</h4>
                                                            <p>Producto</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );

                                        return acc;
                                    }, [])
                                    .map((group, groupIndex) => (
                                        <div
                                            className={`carousel-item ${groupIndex === 0 ? "active" : ""}`}
                                            key={groupIndex}
                                        >
                                            <div className="row g-3 justify-content-center">
                                                {group}
                                            </div>
                                        </div>
                                    ))
                            )}
                        </div>
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExample"
                            data-bs-slide="prev"
                        >
                            <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExample"
                            data-bs-slide="next"
                        >
                            <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </section>

            <section id="about" className="ud-about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div
                                className="ud-about-wrapper wow fadeInUp"
                                data-wow-delay=".2s"
                                style={{ borderRadius: "5px" }}
                            >
                                <div className="ud-about-content-wrapper p-3">
                                    <div className="d-flex flex-column flex-md-row align-items-center">
                                        <div className="col-md-8">
                                            <div className="ud-about-content">
                                                <h4 style={{ color: "#3056d3" }}>Neumuc</h4>
                                                <span style={{ color: "#ee8819", fontWeight: "bold" }}>
                                                    EXPECTORANTE MUCOLÍTICO
                                                </span>
                                                <p style={{ marginTop: "1em", textAlign: "justify" }}>
                                                    Expectorante Mucolítico, indicado para aliviar la tos
                                                    productiva producida por cuadros respiratorios agudos o
                                                    crónicos de origen infeccioso (bacteriano, viral),
                                                    alérgico.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 d-flex justify-content-center position-relative">
                                            <div
                                                style={{
                                                    width: "50px",
                                                    height: "50px",
                                                    background: "#ee8819",
                                                    borderRadius: "50%",
                                                    position: "absolute",
                                                    top: "0px",
                                                    right: "15px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "40px",
                                                        height: "40px",
                                                        background: "white",
                                                        borderRadius: "50%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    OTC
                                                </div>
                                            </div>
                                            <img
                                                src={neumuc}
                                                className="img-fluid"
                                                style={{ height: "150px" }}
                                                alt="Neumuc"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <AccordionItem
                                                targetId="collapseOneNeumuc"
                                                title="Acción"
                                                items={[
                                                    "Disminuye la mucosidad.",
                                                    "Expulsa las flemas",
                                                    "Facilita la expectoración, aliviando la tos.",
                                                ]}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <AccordionItem
                                                targetId="collapseTwoNeumuc"
                                                title="Dosificación"
                                                items={[
                                                    "Adultos y adolescentes 12 años o más: 2 cucharadas de 5ml 3 veces al día.",
                                                    "Niños de 6 a 12 años: 1 cucharada de 5ml 3 veces al día.",
                                                    "Niños de 2 a 6 años: 1/4 de cucharada de 5ml 3 veces al día.",
                                                ]}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-12 mt-3">
                                            <AccordionItem
                                                targetId="collapseThreeNeumuc"
                                                title="Composición"
                                                items={[
                                                    "Bromhexina HCI 4mg/5ml",
                                                    "Guaifesina 100mg/5ml",
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 mb-4">
                            <div
                                className="ud-about-wrapper wow fadeInUp"
                                data-wow-delay=".2s"
                            >
                                <div className="ud-about-content-wrapper p-3">
                                    <div className="d-flex flex-column flex-md-row align-items-center">
                                        <div className="col-md-8">
                                            <div className="ud-about-content">
                                                <h4 style={{ color: "#3056d3" }}>Resgripfar</h4>
                                                <span style={{ color: "#ee8819", fontWeight: "bold" }}>
                                                    Antigripal
                                                </span>
                                                <p style={{ marginTop: "1em", textAlign: "justify" }}>
                                                    Alivia los síntomas del resfriado común combinando la
                                                    acción farmacológica de Acetaminofén, Cetirizina
                                                    Diclorhidrato, Fenilefrina.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 d-flex justify-content-center position-relative">
                                            <img
                                                src={resgripfar}
                                                className="img-fluid"
                                                style={{ height: "150px" }}
                                                alt="Resgripfar"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <AccordionItem
                                                targetId="collapseOneResgripfar"
                                                title="Acción"
                                                items={[
                                                    "Dolor de cabeza.",
                                                    "Congestión nasal.",
                                                    "Estornudos.",
                                                    "Dolor de garganta.",
                                                ]}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <AccordionItem
                                                targetId="collapseTwoResgripfar"
                                                title="Dosificación"
                                                items={[
                                                    "Adultos y niños mayores de 12 años: Una tableta cada 12 horas.",
                                                ]}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-12 mt-3">
                                            <AccordionItem
                                                targetId="collapseThreeResgripfar"
                                                title="Composición"
                                                items={[
                                                    "Acetaminofén 500mg",
                                                    "Fenilefrina Clorhidrato 10mg",
                                                    "Cetirizina Diclorhidrato 5mg",
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ud-hero-brands-wrapper wow fadeInUp text-center mt-4">
                        <span style={{ fontWeight: 600, fontSize: "20px", color: "black", marginTop: "2em", }} >
                            Relaciones comerciales
                        </span>
                    </div>
                    <div className="ud-hero-image wow fadeInUp d-flex justify-content-center align-items-center flex-wrap mt-3">
                        <img src={relacionUno} className="mx-2 my-2" style={{ height: "80px", width: "auto" }}
                            alt="Relacion 1"
                        />
                        <img src={relacionDos} className="mx-2 my-2" style={{ height: "80px", width: "auto" }}
                            alt="Relacion 2"
                        />
                        <img src={relacionTres} className="mx-2 my-2" style={{ height: "80px", width: "auto" }}
                            alt="Relacion 3"
                        />
                    </div>

                </div>
            </section >

            <section id="features" className="ud-features py-5">
                <div className="container">
                    <div className="row align-items-center">
                        {/* Texto y Botón en una columna responsive */}
                        <div className="col-lg-6 col-md-12 ">
                            <div className="ud-section-title text-center text-lg-start">
                                <span>Conócenos</span>
                                <h2>¿Quiénes somos?</h2>
                                <Typography variant="h6" gutterBottom sx={{ marginBottom: "1.5rem" }}>
                                    Organización colombiana soportada en la experiencia de un equipo humano interdisciplinario altamente competente, pone a disposición
                                    y al alcance de toda la comunidad que desee
                                    <span style={{ fontWeight: "bold", marginLeft: "5px", marginRight: "5px" }}>Vivir más y Vivir mejor,</span> productos para la salud como un componente fundamental para su bienestar y mejora de calidad.
                                </Typography>
                                <div className="d-flex justify-content-center justify-content-lg-start">
                                    <Button variant="outlined">Más información</Button>
                                </div>
                            </div>
                        </div>

                        {/* Imagen en una columna responsive */}
                        <div className="col-lg-6 col-md-12 text-center">
                            <img src={logoFarma} className="img-fluid" style={{ maxHeight: "120px" }} alt="Logo Farma" />
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Inicio