import React, { useState } from 'react';
import { Table, TableBody, TableContainer, TableHead, TableRow, Checkbox, Paper, styled, CircularProgress, Typography, Box, IconButton, TextField, Toolbar } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { toast } from 'react-toastify';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AlertaAbono from './AlertaAbono';

const StyledTableRow = styled(TableRow)(({ theme, estado }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    height: "10px",
    backgroundColor: estado ? "#b0f2ae" : "inherit", // Aplica el color condicionalmente
}));


const formatCurrency = (value) => {
    return `$${new Intl.NumberFormat('es-ES').format(value)}`;
};

const FacturasTable = ({ facturasData, selectedRows, onCheckboxChange, onUpdateSaldo, loading }) => {
    const [editIndex, setEditIndex] = useState(null); // Índice de la fila en edición
    const [editValue, setEditValue] = useState(""); // Valor temporal en edición

    const [isScrolled, setIsScrolled] = useState(false); // Estado para saber si hay desplazamiento

    // Maneja el evento de desplazamiento
    const handleScroll = (e) => {
        if (e.target.scrollLeft > 0) {
            setIsScrolled(true); // La tabla se ha desplazado
        } else {
            setIsScrolled(false); // La tabla vuelve a la posición original
        }
    };

    // const handleEditClick = (index, saldo) => {
    //     setEditIndex(index);
    //     setEditValue(saldo);
    // };

    // Modify handleEditClick to add validation
    const handleEditClick = (index, saldo) => {
        // Check if current row is selected
        const isRowSelected = selectedRows.some(selectedRow => selectedRow.Documento === facturasData[index].Documento);
        if (isRowSelected) {
            toast.warning("No se puede editar una factura seleccionada para pago", {
                position: "bottom-left",
                theme: "dark",
                hideProgressBar: true,
            });
            return;
        }
        setEditIndex(index);
        setEditValue(saldo);
    };

    // const handleInputChange = (e) => {
    //     setEditValue(e.target.value);
    // };

    // const [showAlert, setShowAlert] = useState(false); // Estado para controlar la visibilidad de AlertaAbono
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState("");

    const handleClickOpen = (value) => {
        setOpen(true);
        setSelectedValue(value);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedValue(""); // Limpia el valor seleccionado
    };

    const handleSave = () => {
        const newValue = editValue === "" ? facturasData[editIndex].saldo : parseFloat(editValue); // Usa el valor original si está vacío
        onUpdateSaldo(editIndex, newValue);
        setEditIndex(null); // Finaliza la edición
    };

    const formatInputCurrency = (value) => {
        // Asegúrate de que el valor sea una cadena
        const stringValue = String(value || "");
        // Desformatea el valor (elimina todo excepto números)
        const rawValue = stringValue.replace(/[^\d]/g, "");
        // Formatea el valor con separadores de miles
        const formattedValue = new Intl.NumberFormat("es-ES").format(parseInt(rawValue || "0", 10));
        return formattedValue;
    };

    return (
        <Paper variant='outlined' elevation={0} sx={{ mb: 2, borderRadius: "12px", }}>

            <Toolbar>
                <Typography variant='body2'>
                    <CheckIcon fontSize="inherit" sx={{ marginRight: 1 }} />
                    Seleccione su factura a pagar
                </Typography>
            </Toolbar>

            <TableContainer
                elevation={0}
                variant='outlined'
                component={Paper}
                sx={{
                    // height: "100%",
                    height: {
                        xs: 'calc(100vh - 200px)', // Altura responsive para móviles
                        sm: 'calc(100vh - 180px)', // Tablets
                        md: 'calc(100vh - 160px)', // Desktop
                        // lg: 'calc(100vh - 140px)'  // Pantallas grandes
                        lg: 'calc(90vh - 140px)'  // Pantallas grandes
                    },
                    borderRadius: "12px",
                    border: "none",
                    overflowX: "auto",
                }}
                onScroll={handleScroll} // Evento de scroll
            >

                {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column', // Asegura que el spinner y el texto estén en columnas
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%', // Ocupa toda la altura del contenedor
                        }}
                    >
                        <CircularProgress />
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            Cargando Facturas
                        </Typography>
                    </Box>
                ) : (
                    <Table stickyHeader size='small' aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {/* <TableCell align='center'> */}
                                <TableCell
                                    align="center"
                                    sx={{
                                        position: "sticky",
                                        left: 0,
                                        backgroundColor: "white", // Asegura que tenga un fondo fijo
                                        zIndex: 99, // Para que se mantenga por encima de otras columnas
                                        ...(isScrolled && {
                                            boxShadow: "2px 0 5px -2px rgba(0, 0, 0, 0.3)",
                                            transform: "translateY(-1px)",

                                        }),
                                        padding: 1
                                    }}
                                >
                                    <Typography noWrap>
                                        Documentos
                                    </Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography noWrap>
                                        Fecha
                                    </Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography noWrap> Fecha Limite</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography noWrap>Mora</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography noWrap> Plazo</Typography>
                                </TableCell>

                                <TableCell align='center'>
                                    <Typography noWrap>Saldo total</Typography>
                                </TableCell>

                                <TableCell align='center'>
                                    <Typography noWrap>Saldo abonar</Typography>
                                </TableCell>
                                <TableCell align='center' width={5} sx={{ padding: 1 }}>
                                    <Typography noWrap>Abono</Typography>
                                </TableCell>
                                <TableCell align='center' width={5} sx={{ padding: 1 }}>
                                    <Typography noWrap>Seleccionar </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {facturasData.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            position: "sticky",
                                            left: 0,
                                            zIndex: 1,
                                            backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                                            // Sombra y elevación solo si se ha desplazado
                                            ...(isScrolled && {
                                                boxShadow: "2px 0 5px -2px rgba(0, 0, 0, 0.3)",
                                                // borderRight: "2px solid #ccc",
                                                transform: "translateY(-2px)",
                                            }),
                                            transition: "all 0.3s ease",
                                            padding: 1
                                        }}
                                    >
                                        {row.Documento}
                                    </TableCell>
                                    <TableCell align='center'>{new Date(row.Fecha).toLocaleDateString()}</TableCell>
                                    <TableCell align='center'>{new Date(row["Fecha Limite"]).toLocaleDateString()}</TableCell>
                                    <TableCell align='center'>
                                        <Box sx={{ gap: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <div>
                                                {row.Mora}
                                            </div>
                                            <div style={{ marginLeft: 1 }}>
                                                Dias
                                            </div>
                                        </Box>
                                    </TableCell>
                                    {/* <TableCell align='center'>{row["Plazo Dias"]} de Dias</TableCell> */}
                                    <TableCell align='center'>
                                        <Box sx={{ gap: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <div>
                                                {row["Plazo Dias"]}
                                            </div>
                                            <div style={{ marginLeft: 1 }}>
                                                Dias
                                            </div>
                                        </Box>
                                    </TableCell>
                                    {/* Columna Saldo total (estático) */}
                                    <TableCell>
                                        <Box>
                                            {formatCurrency(row.saldo)} {/* Muestra el valor original sin editar */}
                                        </Box>
                                    </TableCell>

                                    {/* <TableCell align="center" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}> */}
                                    <TableCell
                                        align="center"
                                        sx={{
                                            width: '100px',  // Fixed width
                                            minWidth: '100px',
                                            maxWidth: '100px',
                                            padding: 0,
                                            height: '55px'  // Fixed height
                                        }}
                                    >
                                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                            {row.Estado === "" && editIndex !== index ? (
                                                <IconButton size="small" onClick={() => handleEditClick(index, row.saldoAbonar)}>
                                                    <EditIcon fontSize="inherit" />
                                                </IconButton>
                                            ) : null}

                                            {editIndex === index ? (
                                                <TextField
                                                    value={formatInputCurrency(editValue)}
                                                    onChange={(e) => {
                                                        const rawValue = e.target.value.replace(/[^\d]/g, "");
                                                        const numericValue = parseInt(rawValue || "0", 10);

                                                        if (numericValue === 0) {
                                                            toast.warning("El valor no puede ser 0.", {
                                                                position: "bottom-left",
                                                                autoClose: 5000,
                                                                theme: "dark",
                                                                hideProgressBar: true,
                                                                closeOnClick: false,
                                                                pauseOnHover: false,
                                                                draggable: true,
                                                                progress: undefined,
                                                            });
                                                            setEditValue(facturasData[editIndex].saldo.toString()); // Reset to original value
                                                            return;
                                                        }

                                                        if (numericValue <= facturasData[editIndex].saldo) {
                                                            setEditValue(numericValue.toString());
                                                        } else {
                                                            toast.warning("El valor excede el monto actual.", {
                                                                position: "bottom-left",
                                                                autoClose: 5000,
                                                                theme: "dark",
                                                                hideProgressBar: true,
                                                                closeOnClick: false,
                                                                pauseOnHover: false,
                                                                draggable: true,
                                                                progress: undefined,
                                                            });
                                                            setEditValue(facturasData[editIndex].saldo.toString()); // Limita al saldo total
                                                        }
                                                    }}
                                                    onBlur={handleSave}
                                                    onKeyPress={(e) => e.key === "Enter" && handleSave()}
                                                    size="small"
                                                    variant="outlined"
                                                    helperText={
                                                        parseInt(editValue || "0", 10) > facturasData[editIndex]?.saldo
                                                            ? "El valor no puede superar el saldo total."
                                                            : ""
                                                    }
                                                    sx={{ height: "40px", padding: 0 }}
                                                    error={parseInt(editValue || "0", 10) > facturasData[editIndex]?.saldo}
                                                    autoFocus
                                                />
                                            ) : (
                                                <Box sx={{
                                                    display: "flex", alignItems: "start",
                                                    justifyContent: "start", gap: 1
                                                }}>
                                                    {formatCurrency(row.saldoAbonar)}
                                                </Box>
                                            )}
                                        </Box>
                                    </TableCell>

                                    {row.Abono > 0 ?
                                        <TableCell align='center' width={5} sx={{ padding: 1 }}>

                                            <IconButton aria-label="delete" size="small"
                                                onClick={() => handleClickOpen(row?.Documento)}
                                            >
                                                <ReceiptIcon fontSize="inherit" />
                                            </IconButton>
                                        </TableCell>
                                        :
                                        <TableCell align='center' width={5} sx={{ padding: 1 }}>
                                            -
                                        </TableCell>
                                    }
                                    <TableCell align='center' padding="checkbox" sx={{ padding: 1 }}>
                                        {row.Estado === "" ?
                                            <Checkbox
                                                checked={selectedRows.some(selectedRow => selectedRow.Documento === row.Documento)}
                                                onChange={() => onCheckboxChange(row)} // El saldoAbonar ya se pasa aquí
                                            />
                                            :
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Box sx={{
                                                    // width: "auto",
                                                    // margin: "10px",
                                                    paddingLeft: "5px",
                                                    paddingRight: "5px",
                                                    background: "#b0f2ae",
                                                    borderRadius: "4px", // Opcional para un mejor diseño
                                                }}>
                                                    Pagada
                                                </Box>
                                            </Box>
                                        }
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>

            <AlertaAbono
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
            />
        </Paper>
    )
};

export default FacturasTable;
