import newRequest from "../../../../utils/newRequest";

export const fetchFacturas = async (nitsec) => {
    if (!nitsec) {
        console.error("NitSec no proporcionado para fetchFacturas");
        return [];
    }
    try {
        const response = await newRequest.post('/api/login/facturasClientes', { nitsec });
        return response.data;
    } catch (error) {
        console.error("Error al obtener las facturas:", error);
        throw error;
    }
};

export const iniciarSesion = async (identificacion, documento) => {
    try {
        const response = await newRequest.post('/api/login/sesionCliente', {
            identificacion,
            codigo: documento,
        });
        fetchFacturas();
        return response.data;
    } catch (error) {
        console.error("Error en la autenticación:", error);
        throw error;
    }
};

export const insertarPrimeraFac = async ({ idFacSec, valorFac, referenciaFac, referenciaValFac,
    referenciaValorAbono
}) => {
    try {
        console.log("Insertando factura:", {
            idFacSec,
            valorFac,
            referenciaFac,
            referenciaValFac,
            referenciaValorAbono
        });

        await newRequest.post('/api/login/insertarPrimeraFactura', {
            factura: {
                idFacSec,
                valorFac,
                referenciaFac,
                referenciaValFac,
                referenciaValorAbono
            },
        });
    } catch (error) {
        console.error("Error al insertar factura:", error);
    }
};

export const obtenerAbonos = async (idFacSec) => {
    console.log("idFacSec idFacSec", idFacSec)
    try {
        const res = await newRequest.get(`api/login/consultarAbono/${idFacSec}`);
        return res.data;
    } catch (error) {
        console.error("Error al obtener los abonos:", error);
        throw error;
    }
}