import React from 'react'
import { Box, Breadcrumbs, Fab } from '@mui/material';
import Typography from '@mui/material/Typography';

import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import WalletIcon from '@mui/icons-material/Wallet';
import InventoryIcon from '@mui/icons-material/Inventory';
import PaidIcon from '@mui/icons-material/Paid';
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';
import HailIcon from '@mui/icons-material/Hail';
import CustomGridItem from '../../components/CustomGridItem';
import Grid from '@mui/material/Grid2';

// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import { useNavigate } from 'react-router-dom';

const GerencialVisualPrincipal = () => {

    // const navigate = useNavigate();

    const gridItems = [
        {
            icon: <TimelineRoundedIcon style={{ fontSize: "45px" }} />,
            title: "Profundidad al portafolio",
            link: "/Farmanet/GerenciaVisual/ProfundidadAlPortafolio",
            ruta: "Gerencia visual"
        },
        {
            icon: <ApartmentRoundedIcon style={{ fontSize: "45px" }} />, title: "Visual empresa",
            link: "/Farmanet/GerenciaVisual/VisualEmpresa",
            ruta: "Gerencia visual"
        },
        {
            icon: <PaidIcon style={{ fontSize: "40px" }} />, title: "Presupuesto",
            link: "/Farmanet/GerenciaVisual/Presupuesto",
            ruta: "Gerencia visual"
        },
        {
            icon: <AttachMoneyRoundedIcon style={{ fontSize: "40px" }} />, title: "Ventas",
            link: "/Farmanet/GerenciaVisual/Ventas",
            ruta: "Gerencia visual"
        },
        {
            icon: <InventoryIcon style={{ fontSize: "40px" }} />, title: "Recaudo por vendedor",
            link: "/Farmanet/GerenciaVisual/RecaudoPorVendedor",
            ruta: "Gerencia visual"
        },
        {
            icon: <HailIcon style={{ fontSize: "40px" }} />, title: "Seguimiento Asesor",
            link: "/Farmanet/GerenciaVisual/SeguimientoAsesor",
            ruta: "Gerencia visual"
        },
        {
            icon: <MultilineChartIcon style={{ fontSize: "40px" }} />, title: "Indicadores",
            link: "/Farmanet/GerenciaVisual/Indicadores",
            ruta: "Gerencia visual"
        },
        {
            icon: <WalletIcon style={{ fontSize: "40px" }} />, title: "Cartera",
            link: "/Farmanet/GerenciaVisual/Cartera",
            ruta: "Gerencia visual"
        },
        // Add more grid items as needed
    ];

    return (
        <Box>
            <Grid size={{ xs: 12, sm: 12, md: 12 }}  >
                <Box sx={{ alignContent: "center", justifyContent: "start", display: "flex", alignItems: "center" }}>
                    <Typography color="black" gutterBottom style={{
                        display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center",
                        marginBottom: "1em",
                    }}>
                        <BarChartRoundedIcon sx={{
                            marginRight: "5px",
                            color: "#FE0000",
                        }} />
                        Gerencia visual
                    </Typography>
                </Box>
            </Grid>

            <Grid container spacing={2}>
                {gridItems.map((item, index) => (
                    <CustomGridItem key={index} {...item}/> 
                ))}
            </Grid>
        </Box >
    )
}

export default GerencialVisualPrincipal