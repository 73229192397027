import { useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import {
    Box, Typography, IconButton, Tooltip, Dialog, DialogActions,
    DialogContent, DialogTitle, Button,
    Skeleton
} from '@mui/material';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CachedIcon from '@mui/icons-material/Cached';
import newRequest from '../../../../../../utils/newRequest';
import { toast } from 'react-toastify';

// Función para formatear números como dinero
const formatCurrency = (value) => {
    return `$${new Intl.NumberFormat('es-ES').format(value)}`;
};

const TablaResultadoIndicador = ({ datosPorIndicador, datos, loading, onRefresh }) => {
    
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
        'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciciembre'];

    // console.log("datos", datos.ProEmpSec, datos.IndProEmpSec)

    const actualizarIndicador = async () => {
        try {
            // console.log("✅✅✅✅✅✅✅✅✅✅✅", datos.ProEmpSec, datos.IndProEmpSec)
            await newRequest.post(`/api/GerenciaVisual/Indicadores/ObtenerConsultaIndicador`, {
                indicador: datos.ProEmpSec,
                procesoEmp: datos.IndProEmpSec,
            });
            onRefresh(); // Llamar a la función de refresco del padre
        } catch (error) {
            // console.error("Error al actualizar indicador:", error);
            if (error.response && error.response.status === 404) {
                // alert("No se encontraron resultados para los indicadores proporcionados.");

                toast.warning(`
                        No se enuentra una consulta para este 
                        indicador
                    `, {
                    position: "bottom-left",
                    autoClose: 4000,
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                });


            } else {
                console.error("Error al actualizar indicador:", error);
                alert("Ocurrió un error al actualizar el indicador.");
            }
        }
    };


    const [currentHeader, setCurrentHeader] = useState('');
    const [currentObservation, setCurrentObservation] = useState('');

    const duplicatedData = meses.map((mes, index) => {
        // Encuentra el dato correspondiente al mes actual o crea un objeto vacío si no hay datos
        const dataForMonth = datosPorIndicador.find(item => item.DatIndMes === index + 1) || {};
        return {
            Mes: mes,
            // 'DatIndDatUno': dataForMonth.DatIndDatUno || 0,
            'DatIndDatUno': dataForMonth.DatIndDatUno,
            'DatIndDatDos': dataForMonth.DatIndDatDos,
            DatIndRes: dataForMonth.DatIndRes,
            DatIndAcum: dataForMonth.DatIndAcum,
            'DatIndResPro': dataForMonth.DatIndResPro,
            'DatIndAnaObs': dataForMonth.DatIndAnaObs,
            'DatIndMes': dataForMonth.DatIndMes,
            'IndProAno': dataForMonth.IndProAno,
        };
    });

    // console.log("datosPorIndicador datosPorIndicador", datosPorIndicador)
    const [open, setOpen] = useState(false);

    const handleOpen = (observation, header) => {
        setCurrentObservation(observation);
        setCurrentHeader(header)
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const tipo = datos?.IndProTipCod2;

    const columns = [
        {
            accessorKey: 'A',
            header: 'A',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: '#1C64F2',
                    fontWeight: 'normal',
                    fontSize: '15px !important',
                    color: "white"
                }
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            size: 20,
            Cell: ({ cell, row }) => {
                const hasObservations = cell.row.original;
                const observation = hasObservations?.DatIndAnaObs?.[0] || '';
                const header = `${hasObservations?.IndProAno}-${hasObservations?.DatIndMes}`
                return (
                    <div>
                        {/* {duplicatedData.DatIndAnaObs  ? ('x'): ("-")} */}
                        {observation ? (<ManageSearchIcon onClick={() => handleOpen(observation, header)} />) : "-"}
                    </div>
                );
            },
        },
        {
            accessorKey: 'Mes',
            header: 'Mes',
            size: 10,
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    backgroundColor: '#E74C3C',
                    fontWeight: 'normal',
                    fontSize: '15px !important',
                    color: "white"
                }
            },
        },
        {
            accessorKey: 'DatIndDatUno',
            header: (
                <Tooltip title={datos?.IndProRel} arrow>
                    <Typography variant='body2' noWrap
                        sx={{ width: "150px" }}
                    >
                        {datos?.IndProRel}
                    </Typography>
                </Tooltip>
            ),
            size: 10,
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#1C64F2',
                    fontWeight: 'normal',
                    fontSize: '15px !important',
                    color: "white"
                }
            },
            Cell: ({ cell }) => (
                <Typography variant='subtitle2' style={{ textTransform: 'capitalize' }}>
                    {cell.row.original.DatIndDatUno >= 1000000 ? formatCurrency(cell.row.original.DatIndDatUno) : cell.row.original.DatIndDatUno}
                </Typography>
            ),
        },
        {
            accessorKey: 'DatIndDatDos',
            header: (
                <Tooltip title={datos?.IndProRelDos} arrow>
                    <Typography variant='body2' noWrap
                        sx={{ width: "150px" }}
                    >
                        {datos?.IndProRelDos}
                    </Typography>
                </Tooltip>
            ),
            size: 10,
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#1C64F2',
                    fontWeight: 'normal',
                    fontSize: '15px !important',
                    color: "white"
                }
            },
            Cell: ({ cell }) => (
                <Typography variant='subtitle2' style={{ textTransform: 'capitalize' }}>
                    {Math.abs(cell.row.original.DatIndDatDos) >= 1000000
                        ? formatCurrency(cell.row.original.DatIndDatDos)
                        : cell.row.original.DatIndDatDos}
                </Typography>
            ),
        },
        {
            accessorKey: 'DatIndRes',
            header: 'Resultado',
            size: 10,
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#1C64F2',
                    fontWeight: 'normal',
                    fontSize: '15px !important',
                    color: "white"
                }
            },
            muiTableBodyCellProps: ({ cell }) => {
                const porcentajeEmpresa = parseFloat((datos?.IndProEmpresa || '').replace('%', '')) || 0;
                // const porcentajeEmpresaDos = parseFloat((datos?.IndProEmpresaDos || '').replace('%', '')) || 0;
                const datIndRes = parseFloat(cell.row.original.DatIndRes);

                // Función para determinar el color de fondo
                const getBackgroundColor = (tipo, datIndRes) => {
                    // Verifica si datIndRes tiene un valor válido
                    if (!datIndRes || isNaN(datIndRes)) {
                        return 'white'; // Retorna blanco si no hay un valor válido
                    }

                    // Verificación para tipo "ULT"
                    if (tipo === "ULT") {
                        // Verifica el criterio de datos
                        switch (datos?.IndProMetCri) {
                            case 'Maximo':
                                if (porcentajeEmpresa >= datIndRes) {
                                    return '#8adab2'; // Verde si cumple la condición
                                } else {
                                    return '#ffcdd2'; // Rojo si no cumple la condición
                                }
                            case 'Minimo':
                                if (datIndRes <= porcentajeEmpresa) {
                                    return '#ffcdd2'; // Rojo si es menor
                                } else {
                                    return '#8adab2'; // Verde si es mayor o igual
                                }
                            default:
                                console.log("Criterio no encontrado");
                        }
                    }
                    return 'white'; // Color blanco por defecto si no cumple ninguna condición
                };

                return {
                    sx: {
                        fontWeight: "bold", // Aplica negrita
                        backgroundColor: getBackgroundColor(tipo, datIndRes), // Usa la función externa
                    },
                };
            },
            Cell: ({ cell, row }) => (
                <Box>
                    <Typography sx={{ fontSize: '0.9rem !important', }}>
                        {/* {cell.row.original.DatIndRes !== null && cell.row.original.DatIndRes !== undefined
                            ? (datos?.IndProEmpresa && datos?.IndProEmpresa.includes('%')) ||
                                (datos?.IndProEmpresaDos && datos?.IndProEmpresaDos.includes('%'))
                                ? `${cell.row.original.DatIndRes}%`
                                : cell.row.original.DatIndRes
                            : ''} */}
                        {cell.row.original.DatIndRes !== null && cell.row.original.DatIndRes !== undefined
                            ? (
                                (datos?.IndProEmpresa && datos?.IndProEmpresa.includes('%')) ||
                                (datos?.IndProEmpresaDos && datos?.IndProEmpresaDos.includes('%'))
                            )
                                ? `${cell.row.original?.DatIndRes}%`
                                : cell.row.original?.DatIndRes
                            : ''}
                    </Typography>
                </Box>
            ),
        },
        {
            accessorKey: 'DatIndAcum',
            header: 'Acumulado',
            size: 10,
            muiTableHeadCellProps: {

                sx: {
                    backgroundColor: '#1C64F2',
                    color: "white",
                    fontWeight: 'normal', fontSize: "15px",
                }
            },
            muiTableBodyCellProps: ({ cell }) => {
                // Convierte los valores de porcentaje a números
                const porcentajeEmpresa = parseFloat((datos?.IndProEmpresa || '').replace('%', '')) || 0;
                // const porcentajeEmpresaDos = parseFloat((datos?.IndProEmpresaDos || '').replace('%', '')) || 0;
                const datIndRes = parseFloat(cell.row.original.DatIndRes);

                // Función para determinar el color de fondo
                const getBackgroundColor = (tipo, datIndRes) => {
                    // Verifica si datIndRes tiene un valor válido
                    if (!datIndRes || isNaN(datIndRes)) {
                        return 'white'; // Retorna blanco si no hay un valor válido
                    }

                    // Verificación para tipo "ULT"
                    if (tipo === "ACU") {
                        // Verifica el criterio de datos
                        switch (datos?.IndProMetCri) {
                            case 'Maximo':
                                if (porcentajeEmpresa >= datIndRes) {
                                    return '#8adab2'; // Verde si cumple la condición
                                } else {
                                    return '#ffcdd2'; // Rojo si no cumple la condición
                                }
                            case 'Minimo':
                                if (datIndRes <= porcentajeEmpresa) {
                                    return '#ffcdd2'; // Rojo si es menor
                                } else {
                                    return '#8adab2'; // Verde si es mayor o igual
                                }
                            default:
                                console.log("Criterio no encontrado");
                        }
                    }
                    return 'white'; // Color blanco por defecto si no cumple ninguna condición
                };

                return {
                    sx: {
                        fontWeight: "bold", // Aplica negrita
                        backgroundColor: getBackgroundColor(tipo, datIndRes), // Usa la función externa
                    },
                };

            },
            Cell: ({ cell, column }) => (
                <Box>
                    <Typography sx={{ fontSize: '0.9rem !important', }}>
                        {cell.row.original.DatIndAcum !== null && cell.row.original.DatIndAcum !== undefined
                            ? (
                                (datos?.IndProEmpresa && datos?.IndProEmpresa.includes('%')) ||
                                (datos?.IndProEmpresaDos && datos?.IndProEmpresaDos.includes('%'))
                            )
                                ? `${cell.row.original.DatIndAcum}%`
                                : cell.row.original.DatIndAcum
                            : ''}
                    </Typography>

                </Box>
            ),
        },
        {
            accessorKey: 'DatIndResPro',
            header: 'Promedio',
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#27AE60',
                    color: "white",
                    fontWeight: 'normal', fontSize: "15px",
                }
            },
            size: 10,
            muiTableBodyCellProps: ({ cell }) => {
                const porcentajeEmpresa = parseFloat((datos?.IndProEmpresa || '').replace('%', '')) || 0;
                const porcentajeEmpresaDos = parseFloat((datos?.IndProEmpresaDos || '').replace('%', '')) || 0;
                const datIndRes = parseFloat(cell.row.original.DatIndRes);

                return {
                    sx: {
                        backgroundColor:
                            tipo === "PRO" ?
                                (datIndRes < porcentajeEmpresa || datIndRes < porcentajeEmpresaDos
                                    ? '#8ADAB2' // Verde si es menor
                                    : (datIndRes > porcentajeEmpresa || datIndRes > porcentajeEmpresaDos
                                        ? '#FFCDD2' // Rojo si es mayor
                                        : '')) // Sin color si no cumple ninguna condición
                                : "",
                    },
                };
            },
            Cell: ({ cell, column }) => (
                <Box>
                    <Typography sx={{ fontSize: '0.9rem !important', }}>
                        {cell.row.original.DatIndResPro !== null && cell.row.original.DatIndResPro !== undefined
                            ? (
                                (datos?.IndProEmpresa && datos?.IndProEmpresa.includes('%')) ||
                                (datos?.IndProEmpresaDos && datos?.IndProEmpresaDos.includes('%'))
                            )
                                ? `${cell.row.original.DatIndResPro}%`
                                : cell.row.original.DatIndResPro
                            : ''}
                    </Typography>
                </Box>
            ),
        },
        {
            accessorKey: 'Act',
            header: 'Act',
            muiTableHeadCellProps: {
                align: "center",
                sx: {
                    backgroundColor: '#27AE60',
                    color: "white",
                    fontWeight: 'normal', fontSize: "15px",
                }
            },
            size: 20,
            muiTableBodyCellProps: ({ row }) => ({
                align: 'center',
                sx: {
                    // backgroundColor: row.index < datosPorIndicador.length ? '#EC8F5E' : '',
                    // border: '1px solid rgba(224, 224, 224, 1)',
                }
            }),
            Cell: ({ cell, row }) => {

                const Mensual = datos?.IndProFre === "Mensual";
                const Bimestral = datos?.IndProFre === "Bimestral";
                const Trimestral = datos?.IndProFre === "Trimestral";
                const Cuatrimestral = datos?.IndProFre === "Cuatrimestral";
                const Semestral = datos?.IndProFre === "Semestral";
                const Anual = datos?.IndProFre === "Anual";

                // Determinar si el ícono debe mostrarse según el tipo de frecuencia
                const MostrarIcono =
                    (Mensual && (cell.row.index + 1) % 1 === 0) ||
                    (Bimestral && (cell.row.index + 1) % 2 === 0) ||
                    (Trimestral && (cell.row.index + 1) % 3 === 0) ||
                    (Cuatrimestral && (cell.row.index + 1) % 4 === 0) ||
                    (Semestral && (cell.row.index + 1) % 6 === 0) ||
                    (Anual && (cell.row.index + 1) % 12 === 0);
                return (
                    <div>

                        {row.index < datosPorIndicador.length ?
                            MostrarIcono && (
                                <Tooltip title="Recargar">
                                    <IconButton sx={{ background: "white" }}
                                    //  onClick={() => handleButtonClick(cell.row.original)}
                                    >
                                        <RotateLeftIcon sx={{
                                            fontSize: "19px", position: "absolute",
                                        }} />
                                    </IconButton>
                                </Tooltip>
                            )
                            : '-'}
                        {!MostrarIcono && (
                            <span>
                                {cell.value !== null && cell.value !== undefined ? cell.value : "-"}
                            </span>
                        )}
                    </div>
                );
            },
        },
    ];

    const table = useMaterialReactTable({
        state: { isLoading: loading },
        muiSkeletonProps: {
            animation: 'wave',
            width: "100%"
        },
        data: duplicatedData,
        columns,
        enableKeyboardShortcuts: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        enablePagination: false,
        enableSorting: false,

        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableHiding: false,
        enableGlobalFilter: false,
        enableBottomToolbar: false,
        initialState: {
            density: 'compact',
            pagination: { pageIndex: 0, pageSize: 12 },
        },
        muiTablePaperProps: {
            elevation: 0
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box sx={{ display: "flex", width: "100%" }}>
                <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
                    {loading ? (
                        <Skeleton
                            animation="wave"
                            width={165}
                            style={{ marginRight: "1em" }}
                        />
                    ) : (
                        <Typography
                            variant="subtitle1"
                            marginRight={1}
                            sx={{
                                fontSize: "1rem",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                            }}
                        >
                            <span style={{ fontWeight: "bold" }}>Tipo Medición:</span>
                            <span style={{ fontWeight: "normal", marginLeft: 2 }}>
                                {datos?.IndProTipCod === "ULT" ? "ULTIMO" : "" ||
                                    datos?.IndProTipCod === "ACU" ? "ACUMULADO" : "" ||
                                        datos?.IndProTipCod === "PRO" ? "PROMEDIO" : ""}
                            </span>
                        </Typography>
                    )}

                    {loading ? (
                        <Skeleton
                            animation="wave"
                            width={165}
                            style={{ marginRight: "1em" }}
                        />
                    ) : (
                        <>
                            <Typography
                                variant="subtitle1"
                                marginRight={1}
                                sx={{
                                    fontSize: "1rem",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                }}
                            >
                                <span style={{ fontWeight: "bold" }}>Tipo Resultado:</span>
                                <span style={{ fontWeight: "normal", marginLeft: 5 }}>
                                    {datos?.IndProTipCod2 === "ULT" && (
                                        <span style={{ textDecoration: "underline" }}>ULTIMO</span>
                                    )}
                                    {datos?.IndProTipCod2 === "ACU" && (
                                        <span style={{ textDecoration: "underline" }}>ACUMULADO</span>
                                    )}
                                    {datos?.IndProTipCod2 === "PRO" && (
                                        <span style={{ textDecoration: "underline" }}>PROMEDIO</span>
                                    )}
                                </span>
                            </Typography>
                        </>
                    )}

                    {loading ? (
                        <Skeleton
                            animation="wave"
                            width={165}
                            style={{ marginRight: "1em" }}
                        />
                    ) : (
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontSize: "1rem",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                            }}
                        >
                            <span style={{ fontWeight: "bold" }}>Criterio:</span>
                            <span
                                style={{
                                    textTransform: "uppercase",
                                    fontWeight: "normal",
                                    marginLeft: 5,
                                }}
                            >
                                {datos?.IndProMetCri}
                            </span>
                        </Typography>
                    )}
                </Box>

                {/* Alinea este componente a la derecha */}
                <Tooltip title="Recargar indicador">
                    <IconButton onClick={() => actualizarIndicador("Hola ")}>
                        <CachedIcon />
                    </IconButton>
                </Tooltip>

            </Box>

        ),
        muiTableContainerProps: { sx: { height: "63vh", width: "100%" } }
    })

    return (
        <div>
            <Dialog
                open={open}
                maxWidth={"lg"}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: { borderRadius: "14px", width: "600px", height: "280px", }
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {/* {"Use Google's location service?"} */}
                    Análisis Indicador {currentHeader}
                </DialogTitle>
                <DialogContent style={{ border: "1px solid #dddddd", marginRight: "1rem", marginLeft: "1rem", borderRadius: "8px" }}>

                    <Typography style={{ fontSize: "17px", marginLeft: "-12px", paddingTop: "10px" }}>
                        {currentObservation}
                    </Typography>

                </DialogContent>
                <DialogActions style={{ marginBottom: "0.5rem" }}>
                    <Button variant="contained" style={{ background: "#e85242", color: "white" }}>Cerrar</Button>
                </DialogActions>
            </Dialog>

            <MaterialReactTable
                table={table} //only pass in table instead of all table options
                localization={MRT_Localization_ES}
            />

        </div>
    )
}

export default TablaResultadoIndicador