import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Typography, Card, Paper, CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';
import IndicadorPorcentaje from './IndicadorPorcentaje';
import newRequest from '../../../../../utils/newRequest';
import { validacionData } from './formulaIndicadores.js';
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';

// Estilos optimizados con styled para la carta
const StyledPaper = styled(Paper)(({ theme }) => ({
    borderRadius: "8px",
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    // padding: "10px",
    // border: "1px solid #e4e4e7", // Aplicar el color del borde
    '&:hover': {
        transform: 'scale(1.01)', // Efecto de agrandamiento
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)', // Sombra más profunda al hacer hover
    },
}));
const CartaIndicador = React.memo(({ item, chartId, alignment, selectAñoIncial, mes }) => {

    const navigate = useNavigate();

    // Memorizar BodyConsultaInd para evitar recrearlo en cada render
    const BodyConsultaInd = useMemo(() => ({
        año: selectAñoIncial,
        mes: mes,
        codigoProceso: alignment,
        Idproceso: item.ProEmpSec,
    }), [selectAñoIncial, mes, alignment, item]);

    const [datosInd, setdatosInd] = useState([]);

    // Función optimizada para obtener los indicadores
    const ObtenerConsultaIndicadores = useCallback(async () => {
        try {
            const res = await newRequest.post(
                `/api/GerenciaVisual/Indicadores/ConsultaCmIndicador`,
                BodyConsultaInd,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            setdatosInd(res.data || []);
        } catch (error) {
            console.log("error", error);
        }
    }, [BodyConsultaInd]);

    const handleCardClick = () => {
        navigate(`/Farmanet/GerenciaVisual/Indicadores/LineaTiempoIndicador/${item.ProEmpSec}/${alignment}/${selectAñoIncial}/${mes}`);
    };

    useEffect(() => {
        ObtenerConsultaIndicadores();
    }, [ObtenerConsultaIndicadores]);

    const valorformula = validacionData(datosInd);

    return (
        <StyledPaper variant='outlined'>
            <Card elevation={0} style={{
                borderRadius: "8px",
                width: "100%"
            }}>
                <CardActionArea sx={{ padding: "10px" }} onClick={handleCardClick} >
                    <Grid size="grow">
                        <Grid size={12}>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography
                                    noWrap
                                    variant='h6'
                                    sx={{
                                        fontSize: "1.1em",
                                        width: "280px",
                                        // margin: "5px",
                                        // width: "100vh"
                                    }}
                                >
                                    {item.ProEmpNom}
                                </Typography>

                                <Typography noWrap sx={{ color: "#71717a" }}>
                                    id: {item.ProEmpSec}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid size={12}>
                            <Box>
                                <IndicadorPorcentaje chartId={chartId} porcentaje={valorformula.resultado} />
                            </Box>
                        </Grid>

                        <Grid size={12}>
                            <Box sx={{
                                display: "flex", justifyContent: "space-between",
                                justifyItems: "center", alignItems: "center"
                            }}>
                                <Typography style={{ fontSize: "1.7em", fontWeight: "bolder" }}>
                                    {valorformula.resultado}
                                </Typography>

                                <span style={{ fontSize: "15px", fontWeight: "normal", color: "#71717a" }}>
                                    ({valorformula.recalculopeso})
                                </span>
                            </Box>
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
        </StyledPaper>

    );
});

export default CartaIndicador;
