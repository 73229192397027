import { ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

export const CustomListItem = ({ to, text, icon: Icon, index, open, selectedIndex,
    handleListItemClick, handleDrawerClose }) => {

    const theme = useTheme();

    const listItemStyle = {
        height: 42,
        width: open ? 220 : 48,
        margin: 1,
        borderRadius: "12px",
        display: 'flex',
        alignItems: 'center',
        transition: theme.transitions.create(['width', 'transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '&.Mui-selected': {
            backgroundColor: '',
            color: 'white',
            '& .MuiListItemText-primary': {
                color: '#1565c0',
            },
            '& .MuiSvgIcon-root': {
                color: '#1565c0',
            },
        },
    };

    return (
        <Tooltip title={!open ? text : ""} placement="right">
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    component={Link}
                    to={to}
                    sx={{
                        ...listItemStyle,
                        justifyContent: 'center',
                        px: 2.5
                    }}
                    selected={selectedIndex === index}
                    onClick={(event) => {
                        handleListItemClick(event, index);
                        handleDrawerClose();
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 1 : 0,
                            justifyContent: 'center',
                            transition: theme.transitions.create(['margin'], {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.enteringScreen,
                            })
                        }}
                    >
                        <Icon />
                    </ListItemIcon>
                    <ListItemText
                        primary={text}
                        sx={{
                            opacity: open ? 1 : 0,
                            ml: open ? 1 : 0,
                            color: selectedIndex === index ? '#1565c0' : 'inherit',
                            transition: theme.transitions.create(['opacity', 'margin'], {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.enteringScreen,
                            })
                        }}
                    />
                </ListItemButton>
            </ListItem>
        </Tooltip>
    );
};

export default CustomListItem;