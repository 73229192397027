import {
    Box, Button, Divider, FormControl, IconButton, InputLabel, MenuItem, Select,
    styled, TextField, Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import newRequest from '../../utils/newRequest';
import { AuthContext } from '../../loginContext';

import CloseIcon from '@mui/icons-material/Close';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Mes (0-indexado, por eso +1)
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const FormularioReq = ({ onTicketCreated, onClose,
    datosFormulario,
    file, setFile, hasAdjunto, setHasAdjunto
}) => {

    const { currentUser } = React.useContext(AuthContext);
    const [tipoSolicitudes, settipoSolicitudes] = useState([]);

    const [formValues, setFormValues] = useState({
        selectedTipo: "",
        nombre: "",
        descripcion: "",
        file: null
    }); // Estado único para todos los campos

    const tipoSolicitud = async () => {
        try {
            const res = await newRequest.get('/api/requerimiento/tipoSolicitud');
            settipoSolicitudes(res.data.tipo)
        } catch (error) {
            console.log(error)
        }
    }

    const quitarArchivo = () => {
        setFile(null); // Limpia el archivo seleccionado
        setHasAdjunto(false); // Indica que no hay archivo adjunto
    };

    // Controlador general para manejar cambios en los campos
    const handleChange = (event) => {
        const { name, value } = event.target; // Obtiene el nombre y valor del campo
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value, // Actualiza dinámicamente el campo correspondiente
        }));
    };

    const crearRequerimiento = async () => {
        try {
            if (!formValues.selectedTipo || !formValues.nombre) {
                toast.error('Campos faltantes!', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    theme: "dark",
                    draggable: true,
                    progress: undefined,
                    // transition: Bounce,
                });
                return; // Detiene la ejecución si hay campos vacíos
            }

            // Crea un objeto FormData
            const formData = new FormData();
            formData.append("usuario", currentUser.userData?.UsuCod);
            formData.append("tipo", formValues.selectedTipo);
            formData.append("nombre", formValues.nombre);
            formData.append("descripcion", formValues.descripcion);
            formData.append("estado", 1);
            formData.append("fecha", getCurrentDateTime());

            if (file) {
                formData.append("archivo", file);
                formData.append("nombreArchivo", file.name); // Agrega el nombre del archivo
                formData.append("tipoMime", file.type); // Agrega el tipo MIME del archivo
            }

            // Enviar la solicitud POST
            const res = await newRequest.post('/api/requerimiento/NuevoRequerimiento', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (res.status === 200 || res.status === 201) {
                onClose(); // Cerramos ventana para editar
                toast.success('Ticket creado!', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    theme: "dark",
                    draggable: true,
                    progress: undefined,
                    // transition: Bounce,
                });

                // Limpiar los campos del formulario y el archivo
                setFormValues({
                    selectedTipo: "",
                    nombre: "",
                    descripcion: "",
                });
                setFile(null); // Limpia el archivo adjunto

                if (onTicketCreated) {
                    onTicketCreated(); // Notifica al componente padre
                }
            }
        } catch (error) {
            console.error("Error al crear el requerimiento:", error);
        }
    };

    const mostrarArchivo = () => {
        if (datosFormulario && datosFormulario.adjunto) {
            const { buffer, tipo, nombre } = datosFormulario.adjunto;

            // Crear una URL a partir del contenido Base64
            const fileUrl = `data:${tipo};base64,${buffer}`;

            // Abrir el archivo dependiendo de su tipo
            if (tipo.startsWith("application/pdf") || tipo.startsWith("image/")) {
                // Mostrar archivos como PDF o imágenes en un visor
                const newWindow = window.open("", "_blank");
                newWindow.document.write(
                    `<iframe src="${fileUrl}" width="100%" height="100%" style="border:none;"></iframe>`
                );
            } else {
                // Descargar otros tipos de archivo
                const link = document.createElement("a");
                link.href = fileUrl;
                link.download = nombre || "archivo"; // Nombre sugerido para la descarga
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } else {
            toast.error("No hay archivo adjunto disponible.");
        }
    };

    const actualizarRequerimiento = async () => {
        try {
            if (!formValues.selectedTipo || !formValues.nombre) {
                toast.error('Campos faltantes!', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    theme: "dark",
                    draggable: true,
                    progress: undefined,
                });
                return;
            }

            const formData = new FormData();
            formData.append("nombre", formValues.nombre);
            formData.append("descripcion", formValues.descripcion);
            formData.append("idTipoSolicitud", formValues.selectedTipo);
            formData.append("fecha", getCurrentDateTime());

            // Solo incluye el archivo si se ha seleccionado uno nuevo
            if (file) {
                formData.append("archivo", file);
                formData.append("nombreArchivo", file.name); // Agrega el nombre del archivo
                formData.append("tipoMime", file.type); // Agrega el tipo MIME del archivo
            }

            const res = await newRequest.put(
                `/api/requerimiento/actualizarTicket/${datosFormulario.id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (res.status === 200) {
                onClose(); // Cerramos ventana para editar
                toast.success('Ticket actualizado!', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    theme: "dark",
                    draggable: true,
                    progress: undefined,
                });

                if (onTicketCreated) {
                    onTicketCreated(); // Notifica al componente padre
                }
            }
        } catch (error) {
            console.error("Error al actualizar el requerimiento:", error);
        }
    };

    useEffect(() => {
        if (datosFormulario && datosFormulario.id) {
            setFormValues({
                selectedTipo: datosFormulario.idTipoSolicitud || "",
                nombre: datosFormulario.nombre || "",
                descripcion: datosFormulario.descripcion || "",
            });
            setHasAdjunto(!!datosFormulario?.adjunto); // Asegura que sea booleano
        } else {
            // Si no hay datos o se cierra el formulario (se resetea),
            // asegurate de reiniciar los valores a vacíos
            setFormValues({
                selectedTipo: "",
                nombre: "",
                descripcion: "",
            });
        }
    }, [datosFormulario, setHasAdjunto]);

    useEffect(() => {
        tipoSolicitud();
    }, [])

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            // Verificar el tamaño del archivo (1MB = 1 * 1024 * 1024 bytes)
            if (selectedFile.size > 1 * 1024 * 1024) {
                toast.error('El archivo excede el tamaño máximo permitido de 1MB.', {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    theme: "dark",
                    draggable: true,
                    progress: undefined,
                });
                return;
            }
            setFile(selectedFile);
            setHasAdjunto(true);
        }
    };

    return (
        <>
            <Box>
                <Box sx={{
                    display: "flex", alignContent: "center", alignItems: "center",
                    justifyContent: "space-between",
                }}>
                    {datosFormulario && datosFormulario.id ?
                        <Typography variant='h6' sx={{ fontWeight: "bold" }}>Modificar ticket</Typography>
                        :
                        <Typography variant='h6' sx={{ fontWeight: "bold" }}>Creación de ticket</Typography>
                    }
                    <IconButton
                        aria-label="close"
                        sx={{ color: "#323338", borderRadius: "10px" }}
                        onClick={onClose} // Llama a la función de cierre
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Divider sx={{ marginBottom: "0.5em", marginTop: "0.5em" }} />

                <Box sx={{ marginBottom: "0.5em" }} >
                    <Typography >Usuario que solicita:
                        <span style={{ marginLeft: "5px", color: "gray" }}>
                            {currentUser.userData?.UsuCod}
                        </span>
                    </Typography>
                </Box>

                <Box sx={{ marginTop: "1em" }}>
                    <FormControl fullWidth size='small'>
                        <InputLabel id="demo-simple-select-label">Tipo de solicitud *</InputLabel>
                        <Select
                            required
                            name="selectedTipo" // Nombre del campo en el estado
                            label="Tipo de solicitud *"
                            value={formValues.selectedTipo} // Valor tomado del estado
                            onChange={handleChange} // Controlador general
                            sx={{
                                backgroundColor: "white",
                                borderRadius: "8px",
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px",
                                },
                            }}
                        >
                            {tipoSolicitudes.map((tipo) => (
                                <MenuItem key={tipo.id} value={tipo.id}>
                                    {tipo.nombre}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ marginTop: "1em" }}>
                    <Typography>Nombre de la solicitud *</Typography>
                    <TextField
                        required
                        autoComplete="off"
                        fullWidth
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                            },
                        }}
                        size="small"
                        placeholder="Nombre de la solicitud"
                        variant="outlined"
                        name="nombre" // Nombre del campo en el estado
                        value={formValues.nombre} // Valor tomado del estado
                        onChange={handleChange} // Controlador general
                    />
                </Box>
                <Box sx={{ marginTop: "1em" }}>
                    <Typography>Descripción</Typography>
                    <TextField
                        autoComplete="off"
                        multiline
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                            },
                        }}
                        fullWidth
                        rows={4}
                        placeholder="Ingrese la descripción de la solicitud"
                        name="descripcion" // Nombre del campo en el estado
                        value={formValues.descripcion} // Valor tomado del estado
                        onChange={handleChange} // Controlador general
                    />
                </Box>

                <Box sx={{ marginTop: "1em" }}>
                    {hasAdjunto ? (
                        <Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    // gap: 0.5,
                                    alignItems: "center", // Centra los elementos verticalmente
                                    borderWidth: 1,
                                    borderColor: "#e4e4e7",
                                    borderStyle: "solid",
                                    padding: "2px",
                                    borderRadius: 2,
                                }}
                            >
                                {file?.name ?
                                    <Typography noWrap
                                        sx={{
                                            flexGrow: 1,          // Ocupa el espacio disponible
                                            textAlign: "center",  // Centra el texto dentro del Typography
                                            marginLeft: 2,       // Espacio entre el texto y el IconButton
                                        }}
                                    >
                                        {file?.name}
                                    </Typography>
                                    :
                                    // Mostrar el botón para Ver/Descargar si ya hay un archivo adjunto 
                                    <Button variant="text" sx={{ marginLeft: 4 }}
                                        fullWidth onClick={mostrarArchivo} startIcon={<CloudUploadIcon />}>
                                        {/* Ver/Descargar Archivo */}
                                        <Typography noWrap >
                                            {datosFormulario?.adjunto?.nombre}
                                        </Typography>
                                    </Button>
                                }
                                <IconButton
                                    aria-label="delete"
                                    sx={{
                                        color: "#FA4032",
                                        borderRadius: "10px",
                                    }}
                                    // onClick={() => {
                                    //     setFile(null); // Limpia el archivo seleccionado (nuevo)
                                    //     setHasAdjunto(false); // Indica que no hay archivo actual
                                    // }}
                                    onClick={quitarArchivo}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    ) : (
                        // Mostrar la opción para adjuntar un archivo si no hay uno existente
                        <Box>
                            <Button
                                component="label"
                                role={undefined}
                                variant="outlined"
                                tabIndex={-1}
                                fullWidth
                                startIcon={<CloudUploadIcon />}
                            >
                                Cargar un archivo
                                {/* <VisuallyHiddenInput
                                    type="file"
                                    onChange={(event) => {
                                        const selectedFile = event.target.files[0]; // Captura el archivo
                                        setFile(selectedFile); // Almacena el archivo en el estado
                                        setHasAdjunto(true); // Marca que ahora hay un archivo
                                    }}
                                    multiple={false} // Asegúrate de que solo se permita un archivo
                                /> */}
                                <VisuallyHiddenInput
                                    type="file"
                                    onChange={handleFileChange} // Usar el nuevo manejador
                                    multiple={false}
                                />

                            </Button>
                        </Box>
                    )}
                </Box>

                <Box sx={{ marginTop: "1em" }}>
                    <Button
                        onClick={() => {
                            if (datosFormulario.id) {
                                actualizarRequerimiento(); // Llama a la función de actualización si hay un ID
                            } else {
                                crearRequerimiento(); // Llama a la función de creación si no hay ID
                            }
                        }}
                        variant="contained"
                        sx={{
                            width: "100%",
                            borderRadius: "8px",
                            borderColor: "#e4e4e7",
                            borderWidth: "2px",
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            '&:hover': {
                                borderColor: "#e4e4e7",
                                backgroundColor: "#115293",
                                boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)',
                            }
                        }}
                    >
                        <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
                            {datosFormulario.id ? "Editar ticket" : "Crear ticket"} </Typography>
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default FormularioReq