import { Menu, MenuItem, MenuList, Paper, ListItemIcon, ListItemText, Divider } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DrawIcon from '@mui/icons-material/Draw';
import LogoutIcon from '@mui/icons-material/Logout';

export const SettingsMenu = ({
    anchorEl,
    open,
    onClose,
    onQrOpen,
    onFirmaClick,
    onLogout
}) => {
    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <Paper elevation={0} sx={{ width: 250, maxWidth: '100%', height: "100%" }}>
                <MenuList sx={{ padding: 1 }}>
                    <MenuItem onClick={onQrOpen}>
                        <ListItemIcon>
                            <PersonIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Mi perfil</ListItemText>
                    </MenuItem>

                    <MenuItem onClick={onQrOpen}>
                        <ListItemIcon>
                            <QrCodeIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Crear Qr</ListItemText>
                    </MenuItem>

                    <MenuItem onClick={onFirmaClick}>
                        <ListItemIcon>
                            <DrawIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Crear firma digital</ListItemText>
                    </MenuItem>

                    <Divider />

                    <MenuItem onClick={onLogout}>
                        <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Cerrar sesión</ListItemText>
                    </MenuItem>
                </MenuList>
            </Paper>
        </Menu>
    );
};

export default SettingsMenu;