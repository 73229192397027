import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Modal, Card, CardContent, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { fromByteArray } from 'base64-js';
// import Skeleton from '@mui/material/Skeleton';
// import BarsChart from './Graficas/BarsChart';
import { MaterialReactTable, MRT_ShowHideColumnsButton, MRT_ToggleGlobalFilterButton, useMaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import newRequest from '../../../../utils/newRequest';
import ImageComponent from '../ImageComponent';
import BarsChart from '../Graficas/BarsChart';
// import ImageComponent from './ImageComponent';
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx'; // Importar XLSX
import { saveAs } from 'file-saver'; // Importar file-saver para guardar el archivo

const fetchImage = async (data1) => {
    const resImagen = await newRequest.post('/api/GerenciaVisual/profundidadNombreImagen', {
        nombreImagen: data1
    });
    if (resImagen.data && resImagen.data.length > 0 && resImagen.data[0].ArtImg) {
        const base64String = fromByteArray(new Uint8Array(resImagen.data[0].ArtImg.data));
        return base64String;
    }
    return null;
};

const formatCurrency = (value) => {
    // Formatea el valor como moneda colombiana
    return new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP', // Código de moneda para el peso colombiano
    }).format(value);
};

const ProfundidadAlPortafolioTabla = ({ datosParametros, fechaInicial, fechaFinal, actualizarFechas, tipoValor }) => {
    const [muestraTabla, setMuestraTabla] = useState([]);
    const [imagenBase64, setImagenBase64] = useState('');
    const [selectedRowName, setSelectedRowName] = useState('');
    const [selectedRowValue, setSelectedRowValue] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = useCallback((isOpen, type = 'default', row = {}) => {
        if (isOpen && type === 'default') {
            const fetchDataForModal = async () => {
                try {
                    const imgBase64 = await fetchImage(row.header);
                    setImagenBase64(imgBase64);
                    const filteredDatax = Object.entries(row.original).filter(([key]) =>
                        ['Ciudad', 'NitCliente', 'NombreVendedor', 'Zona', 'NombreCliente'].includes(key)
                    ).map(([key, value]) => ({ key, value }));

                    setFilteredData(filteredDatax);
                    setSelectedRowName(row.header);
                    setSelectedRowValue(row.value);
                } catch (error) {
                    console.error('Error loading image or data:', error);
                }
            };
            fetchDataForModal();
        }
        setIsModalOpen(isOpen);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await newRequest.post('/api/GerenciaVisual/profundidadTabla', {
                    ...datosParametros,
                    fechaInicial,
                    fechaFinal
                });
                const cleanedData = res.data.map(item => {
                    const cleanedItem = {};
                    for (const key in item) {
                        if (item.hasOwnProperty(key)) {
                            const value = item[key];
                            cleanedItem[key] = typeof value === 'string' ? value.replace(/\//g, '+') : value;
                        }
                    }
                    return cleanedItem;
                });
                setMuestraTabla(cleanedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [actualizarFechas, datosParametros, fechaInicial, fechaFinal]);

    const fixedColumns = [
        {
            accessorKey: 'NitCliente',
            header: <Typography variant='body2' noWrap title="NitCliente">NitCliente</Typography>,
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#2666CF',
                    color: "white"
                }
            },
        },
        {
            accessorKey: 'NombreVendedor',
            header: <Typography variant='body2' noWrap title="NombreVendedor">NombreVendedor</Typography>,
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#2666CF',
                    color: "white"
                }
            },
        },
        {
            accessorKey: 'NombreCliente',
            header: <Typography variant='body2' noWrap title="NombreCliente">NombreCliente</Typography>,
            size: 30,
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#2666CF',
                    color: "white"
                }
            },
        },
        {
            accessorKey: 'Zona',
            header: <Typography variant='body2' noWrap title="Zona">Zona</Typography>,
            size: 30,
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#2666CF',
                    color: "white"
                }
            },
        },
        {
            accessorKey: 'Ciudad',
            header: <Typography variant='body2' noWrap title="Ciudad">Ciudad</Typography>,
            size: 30,
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#2666CF',
                    color: "white"
                }
            },
        },
    ];

    const dynamicColumns = muestraTabla.length > 0
        ? Object.keys(muestraTabla[0])
            .filter(key => !fixedColumns.some(col => col.accessorKey === key))
            .map((key, index) => ({
                accessorKey: key, // Reemplazar espacios con guiones bajos y eliminar caracteres especiales
                header: <Typography variant='body2' noWrap title={key} width={120}>{key}</Typography>,
                // size: 60,
                muiTableHeadCellProps: {
                    align: 'left',
                    sx: {
                        color: "white",
                        backgroundColor: index < 2 ? '#2666CF' : (index % 2 === 0) ? '#27AE60' : '#FF5722',
                    }
                },
                Cell: ({ cell, row }) => {
                    const totalIndex = fixedColumns.length + index; // Índice total considerando fixedColumns
                    // console.log("first", totalIndex)
                    return (
                        <div
                            style={{ cursor: 'pointer', overflow: "auto" }}
                            onClick={() => {
                                if (totalIndex >= 5) {
                                    toggleModal(true, 'default', { header: key, original: row.original, value: cell.getValue() });
                                } else {
                                    alert("No se puede hacer la búsqueda en esta columna");
                                }
                            }}
                        >
                            {tipoValor ?
                                formatCurrency(cell.getValue())
                                :
                                cell.getValue()
                            }
                        </div>
                    );
                },
                // muiTableBodyCellProps: {
                //     align: 'left',
                //     fontSize: '25px !important',
                //     overflowX: 'auto', // Agrega desplazamiento horizontal
                // },
            }))
        : [];

    const totalColumn = {
        accessorKey: 'Total',
        header: <Typography sx={{ fontWeight: "bold" }} variant='body2' noWrap>Total</Typography>,
        muiTableHeadCellProps: {
            align: 'center',
            sx: {
                backgroundColor: '#E74C3C',
                color: "white",
            }
        },
        size: 30,
        // Cell: ({ row }) => {
        //     const total = Object.values(row.original).slice(5).reduce((acc, value) => acc + (typeof value === "number" ? value : 0), 0);
        //     return (
        //         <div style={{ cursor: 'pointer' }} onClick={() => toggleModal(true, 'total', { value: total })}>
        //             {total.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        //         </div>
        //     );
        // },
        Cell: ({ cell, row }) => {
            const total = Object.values(row.original).slice(5).reduce((acc, value) => acc + (typeof value === "number" ? value : 0), 0);
            return (
                <div
                    style={{ cursor: 'pointer' }}
                >
                    {tipoValor ?
                        // formatCurrency(cell.getValue())
                        total.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                        :
                        total
                    }
                </div>
            );
        },
    };

    fixedColumns.push(totalColumn);
    // const columns = [...fixedColumns];
    const columns = [...fixedColumns, ...dynamicColumns,];


    // Función para exportar a Excel en orden: 'linea', los meses y 'Total', rellenando vacíos con 0
    const exportToExcel = () => {

        const dataToExport = muestraTabla.map(row => {
            const formattedRow = {};

            fixedColumns.forEach(col => {
                if (col.accessorKey !== 'Total') {
                    formattedRow[col.accessorKey] = row[col.accessorKey] ||
                        (typeof row[col.accessorKey] === 'number' ? 0 : '');
                }
            });

            Object.keys(row).forEach(key => {
                if (!fixedColumns.some(col => col.accessorKey === key)) {
                    const value = row[key];
                    if (tipoValor) {
                        formattedRow[key] = value ? formatCurrency(value) : formatCurrency(0);
                    } else {
                        formattedRow[key] = typeof value === 'number' ? (value || 0) : (value || 0);
                    }
                }
            });

            // Add total at the end
            const total = Object.values(row).slice(5).reduce((acc, value) =>
                acc + (typeof value === "number" ? value : 0), 0
            );

            formattedRow['Total'] = tipoValor ?
                total.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                }) :
                total;
            return formattedRow;
        });

        // Create worksheet
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);

        // Create workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Profundidad Medicamento');

        // Generate Excel file
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        // Save file
        const fileName = `Profundidad_Medicamento_${new Date().toISOString().split('T')[0]}.xlsx`;
        const blob = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        saveAs(blob, fileName);
    };


    const tableInstance = useMaterialReactTable({
        columns,
        data: muestraTabla,
        localization: MRT_Localization_ES,
        positionToolbarAlertBanner: 'bottom',
        muiTablePaperProps: { elevation: 0 },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        initialState: {
            density: 'compact',
            columnPinning: { left: ['NitCliente', 'NombreVendedor'], right: ['Total'] }
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                <Typography variant='body1'>
                    Profundidad Medicamento
                </Typography>
            </Box>
        ),
        renderToolbarInternalActions: ({ table }) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* <MRT_GlobalFilterTextInput table={table}  /> */}
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <IconButton onClick={exportToExcel}>
                    <DownloadIcon />
                </IconButton>
            </Box>
        ),
        muiTableContainerProps: {
            sx: {
                height: {
                    xs: '20vh', // Altura en teléfonos móviles
                    sm: '30vh', // Altura en tablets
                    md: '36vh', // Altura en pantallas pequeñas de escritorio
                    lg: "38vh"
                },
                // width: " 100%",
                overflowX: 'auto', // Agrega desplazamiento horizontal

            }
        },
    });
    return (
        <div >
            <div style={{ overflowX: "auto", overflowY: "auto", maxWidth: "100%" }}>
                <MaterialReactTable table={tableInstance} />
            </div>

            <Modal open={isModalOpen} onClose={() => toggleModal(false)} aria-labelledby="modal-title">
                <Box sx={{ borderRadius: "24px", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', outline: 0 }}>
                    <Card sx={{ borderRadius: "24px", boxShadow: 24, p: 4, bgcolor: '#F9F9F9', width: "50rem" }}>
                        <Grid container spacing={1}>
                            <Grid size={{ xs: 6, md: 8 }} >
                                <CardContent sx={{ flex: '1 0 auto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    <Typography component="div" variant="h6">
                                        <span style={{ color: "#FF5959" }}>{selectedRowName}</span>
                                    </Typography>
                                    <Typography variant="body2" display="block" gutterBottom color="text.secondary"
                                        component="div" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {datosParametros.tipo ? "Valor" : "Cantidad"} : {selectedRowValue}
                                    </Typography>
                                    {filteredData.map((item, index) => (
                                        <Typography key={index} color="black" variant="body2"
                                            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                                            display="block" gutterBottom>
                                            {`${item.key}: ${item.value}`}
                                        </Typography>
                                    ))}
                                </CardContent>
                            </Grid>

                            <Grid size={{ xs: 2, md: 4 }}>
                                <ImageComponent imageUrl={`data:image/png;base64, ${imagenBase64}`} isLoading={!imagenBase64} />
                            </Grid>
                        </Grid>

                        <Grid size={{ xs: 12 }}>
                            <BarsChart
                                fechaInicial={fechaInicial}
                                fechaFinal={fechaFinal}
                                filteredData={filteredData}
                                selectedRowName={selectedRowName}
                                datosParametros={datosParametros}
                            />
                        </Grid>
                    </Card>
                </Box>
            </Modal>
        </div >
    );
};

export default ProfundidadAlPortafolioTabla;
