import React, { useContext } from 'react';
import { AuthContext } from "./loginContext";

const PermisoRuta = ({ rolesPermitidos, element, ...props }) => {
    const { currentUser } = useContext(AuthContext);

    const userHasPermission = (roles, allowedRoles) => {
        return roles.some(role => allowedRoles.includes(role));
    };

    const tienePermiso = userHasPermission(currentUser.userData.PerUsuCod, rolesPermitidos);

    return tienePermiso ? React.cloneElement(element, props) :
        <h3>No tienes acceso a esta pantalla</h3>
        ;
};

export default PermisoRuta;