import React, { useCallback, useEffect, useState } from 'react'
import newRequest from '../../utils/newRequest';
import {
    Box, Button, Card, CardActions, CardContent, CardHeader, Chip, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, Divider, IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    MenuList,
    Paper, styled, TextField, ToggleButton, Typography
} from '@mui/material';
import { AuthContext } from '../../loginContext';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ToggleButtonGroup, {
    toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';
import Grid from '@mui/material/Grid2';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SearchIcon from '@mui/icons-material/Search';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PersonIcon from '@mui/icons-material/Person';

// function ListaRequerimientos({ refresh, toggleFormularioReq, showFormularioReq, idTicket }) {
function ListaTickets({ idEstado, requerimiento,
    searchQuery, selectedDate,
    refresh, toggleFormularioReq, showFormularioReq,
    onSelectTicket, onSelectHiloId, datosHilo, idHiloTicket }) {

    const { currentUser } = React.useContext(AuthContext);

    // const [requerimiento, setRequerimiento] = useState([])
    const [estadoSolicitud, setEstadoSolicitud] = useState([]);

    // const [idEstado, setidEstado] = useState(1)

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [idDos, setIdDos] = useState(null)

    const [openDialogThread, setopenDialogThread] = useState(false)

    const [selectedTicket, setSelectedTicket] = useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event, ticket) => {
        setAnchorEl(event.currentTarget);
        setSelectedTicket(ticket); // Guarda los datos del ticket seleccionado
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedTicket(null); // Resetea el ticket seleccionado
        setIdDos(null)
    };

    const handleOpenConfirmDialog = (row) => {
        //Pasamos el parametro que recibimos a un estado para que pueda compartirse
        setIdDos(row)
        //Abrimos el modal
        setOpenConfirmDialog(true);
    };

    const abrirHiloTickets = () => {
        setopenDialogThread(true)
    }

    const obtenerEstado = useCallback(async () => {
        try {
            const res = await newRequest.post('/api/requerimiento/estadosolicitud', {
                usuario: currentUser.userData?.UsuCod,
            });
            setEstadoSolicitud(res.data.tipo);
        } catch (error) {
            setEstadoSolicitud([])
            console.error('Error al obtener los requerimientos:', error);
        }
    }, [currentUser.userData?.UsuCod]);

    const eliminarRequerimiento = async () => {
        try {
            await newRequest.delete(`/api/requerimiento/eliminarRequerimiento/${idDos}`);
            // obtenerRequerimiento();
            // Cierra el menú antes de proceder
            handleClose();
        } catch (error) {
            console.error("Error al eliminar el requerimiento:", error.response?.data || error.message);
            alert("Error al eliminar el requerimiento");
        }
    };

    useEffect(() => {
        obtenerEstado();
    }, [idEstado, refresh, obtenerEstado]);

    const filteredRequerimientos = requerimiento.filter((req) => {
        const matchesSearchQuery =
            req.id.toString().includes(searchQuery) ||
            req.nombre.toLowerCase().includes(searchQuery.toLowerCase());
        // Filtrar por fecha seleccionada
        const matchesDate = selectedDate
            ? new Date(req.fecha) <= new Date(selectedDate + "T23:59:59")
            : true; // Si no hay fecha seleccionada, aceptar todos
        // Devolver aquellos que cumplan ambos criterios
        return matchesSearchQuery && matchesDate;
    });

    // console.log("filteredRequerimientos", filteredRequerimientos)

    const formateador = new Intl.DateTimeFormat("es-ES", {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    });

    const formatDate = (fecha) => {
        if (!fecha) return 'Fecha no disponible';
        const date = new Date(fecha);
        return isNaN(date) ? 'Fecha no válida' : formateador.format(date);
    };


    console.log("datosHilo", filteredRequerimientos)

    return (
        <>
            <Dialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant='h5'>
                        Confirmar Eliminación
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro de que desea eliminar este proceso?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button sx={{ color: "red" }} onClick={() => {
                        setOpenConfirmDialog(false);
                        // Llamar a la función para eliminar el proceso aquí
                        eliminarRequerimiento();
                    }} color="primary" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* <Box sx={{ overflow: "auto", height: "75vh", marginTop: 1, paddingRight: 1, margin: 0 }}> */}
            {/* <Grid container spacing={2}>
                {filteredRequerimientos.length > 0 ? (
                    filteredRequerimientos.map((req, index) => (
                        <Grid size={{ xs: idHiloTicket ? 12 : 6, md: idHiloTicket ? 12 : 4 }} key={req.id || index}>
                            <Card
                                // sx={{
                                //     borderRadius: "8px", position: "relative", // Para la posición absoluta de los elementos internos
                                //     border: "solid red 0.1px"
                                // }}

                                sx={{
                                    borderRadius: "8px",
                                    position: "relative", // Para la posición absoluta de los elementos internos
                                    // borderLeft: "solid red 4px", // Solo el borde izquierdo
                                    borderLeft: `solid ${req.Color_estado} 3px`, // Solo el borde izquierdo
                                    height: "100px"
                                }}


                                variant="outlined">
                                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography noWrap variant='body2'>
                                            Ticket: # {req.id}
                                        </Typography>
                                        <Box sx={{ gap: 1, display: "flex", alignItems: "center" }}>
                                            <Box sx={{
                                                marginRight: -0.5,
                                                marginTop: -0.5,
                                                transform: "rotate(40deg)", // Rotación del ícono
                                                transition: "transform 0.3s ease", // Transición suave para rotación
                                            }}>
                                                {req.adjunto?.data?.length > 0 ? <AttachFileIcon sx={{ width: "18px" }} /> : ""}
                                            </Box>
                                            {req.prioridad_nombre ?
                                                <Chip size='small' sx={{ background: req.prioridad_color, color: "white" }} label={req.prioridad_nombre} /> :
                                                ""
                                            }
                                            <Chip size='small' sx={{ background: req.Color_estado, color: "white" }} label={req.Estado_solicitud} />
                                        </Box>
                                    </Box>

                                    <Typography noWrap variant='body2' sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                        {req.nombre}
                                    </Typography>

                                    <Typography variant="subtitle2" color="textSecondary">
                                        <span style={{ marginRight: 5 }}>Creado:</span>
                                        {new Date(req.fecha).toLocaleDateString("es-ES", {
                                            day: "2-digit",
                                            month: "long",
                                            year: "numeric",
                                        })}
                                    </Typography>

                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                                        <Typography noWrap variant="body1" title={req.descripcion}
                                            sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                            {req.descripcion}
                                        </Typography>

                                        <IconButton
                                            id="basic-button"
                                            aria-controls={openMenu ? "basic-menu" : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openMenu ? "true" : undefined}
                                            onClick={(event) => handleClick(event, req)} // Pasa el ticket al abrir el menú
                                            sx={{ borderRadius: "10px" }}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Typography>No hay requerimientos disponibles</Typography>
                    </Grid>
                )}
            </Grid> */}
            {/* </Box> */}

            <Grid container spacing={2} sx={{ margin: 0, paddingRight: 1 }}>
                {filteredRequerimientos.length > 0 ? (
                    filteredRequerimientos.map((req, index) => (
                        <Grid size={{ xs: idHiloTicket ? 12 : 6, md: idHiloTicket ? 12 : 4 }} key={req.id || index}>
                            <Card
                                sx={{
                                    borderRadius: "8px",
                                    position: "relative",
                                    borderLeft: `solid ${req.Color_estado} 3px`, // Solo el borde izquierdo
                                    height: "110px", // Altura asignada
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between", // Ajusta el contenido para que ocupe el espacio vertical
                                }}
                                variant="outlined"
                            >
                                <CardContent
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        height: "100%", // Asegura que ocupe el espacio disponible
                                        overflow: "hidden", // Controla el desbordamiento
                                    }}
                                >
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography noWrap variant="body2">
                                            Ticket: # {req.id}
                                        </Typography>
                                        <Box sx={{ gap: 1, display: "flex", alignItems: "center" }}>
                                            <Box
                                                sx={{
                                                    marginRight: -0.5,
                                                    marginTop: -0.5,
                                                    transform: "rotate(40deg)", // Rotación del ícono
                                                    transition: "transform 0.3s ease", // Transición suave para rotación
                                                }}
                                            >
                                                {req.adjunto?.data?.length > 0 ? <AttachFileIcon sx={{ width: "18px" }} /> : ""}
                                            </Box>
                                            {req.prioridad_nombre ? (
                                                <Chip
                                                    size="small"
                                                    sx={{ background: req.prioridad_color, color: "white" }}
                                                    label={req.prioridad_nombre}
                                                />
                                            ) : (
                                                ""
                                            )}
                                            <Chip size="small" sx={{ background: req.Color_estado, color: "white" }} label={req.Estado_solicitud} />
                                        </Box>
                                    </Box>

                                    <Typography
                                        noWrap
                                        variant="body2"
                                        sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                    >
                                        {req.nombre}
                                    </Typography>

                                    <Typography variant="subtitle2" color="textSecondary">
                                        <span style={{ marginRight: 5 }}>Creado:</span>
                                        {new Date(req.fecha).toLocaleDateString("es-ES", {
                                            day: "2-digit",
                                            month: "long",
                                            year: "numeric",
                                        })}
                                    </Typography>

                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography
                                            noWrap
                                            variant="body1"
                                            title={req.descripcion}
                                            sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                        >
                                            {req.descripcion}
                                        </Typography>

                                        <IconButton
                                            id="basic-button"
                                            aria-controls={openMenu ? "basic-menu" : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openMenu ? "true" : undefined}
                                            onClick={(event) => handleClick(event, req)} // Pasa el ticket al abrir el menú
                                            sx={{ borderRadius: "10px" }}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Typography>No hay requerimientos disponibles</Typography>
                    </Grid>
                )}
            </Grid>

            <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClick={() => {
                    handleClose(); // Cierra el menú después de seleccionar el ticket
                }}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuList sx={{ padding: 1 }}>
                    <MenuItem sx={{ gap: 1 }} onClick={() => {
                        onSelectHiloId(selectedTicket?.id)
                    }}>
                        <BookmarkBorderOutlinedIcon sx={{ width: "18px", }} />
                        Detalle del ticket
                    </MenuItem> 
                    <MenuItem
                        sx={{ gap: 1 }}
                        onClick={() => {
                            onSelectTicket(selectedTicket?.id); // Pasa el ID del ticket seleccionado
                        }}
                    >
                        <EditOutlinedIcon sx={{ width: "18px" }} />
                        Modificar ticket
                    </MenuItem>

                    {/* <Divider />
                    <MenuItem
                        sx={{ color: "red", gap: 1 }}
                        onClick={() => {
                            handleOpenConfirmDialog(selectedTicket?.id); // Pasa el ID del ticket seleccionado
                        }}
                    >
                        <DeleteOutlineOutlinedIcon sx={{ width: "18px" }} />
                        Eliminar ticket
                    </MenuItem> */}
                </MenuList>
            </Menu>

        </>
    )
}

export default ListaTickets