import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import SettingsIcon from '@mui/icons-material/Settings';
import CustomGridItem from '../../components/CustomGridItem';
import Grid from '@mui/material/Grid2';

const ImportacionesPrincipal = () => {
    const gridItems = [
        {
            icon: <DirectionsBoatFilledIcon style={{ fontSize: "45px" }} />, title: "Calidad",
            link: "/Farmanet/ContenedoresImportaciones",
            ruta: "Importaciones"
        },
        {
            icon: <SettingsIcon style={{ fontSize: "45px" }} />,
            title: " Insertar ",
            link: "/Farmanet/ParametrosImportaciones",
            ruta: "Importaciones"
        },
    ];

    return (
        <div>
            <Grid size={{ xs: 12, sm: 6, md: 12 }} >
                <Box sx={{ alignContent: "center", justifyContent: "start", display: "flex", alignItems: "center" }}>
                    <Typography variant="body1" color="black" gutterBottom style={{
                        display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center",
                        marginBottom: "1em",
                    }}>
                        <DirectionsBoatFilledIcon style={{ marginRight: "10px", color: "#FE0000" }} />
                        Importaciones
                    </Typography>
                </Box>
            </Grid>

            <Grid container spacing={2}>
                {gridItems.map((item, index) => (
                    <CustomGridItem key={index} {...item} />
                ))}
            </Grid>

        </div>
    )
}

export default ImportacionesPrincipal