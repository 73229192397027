import { createContext, useEffect, useState } from "react";
import newRequest from "./utils/newRequest";
import Cookies from "js-cookie";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null);
    const initialIsAuthenticated = !!currentUser;
    const [isAuthenticated, setIsAuthenticated] = useState(initialIsAuthenticated);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Estado inicial basado en el tamaño de la ventana

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Solo corre una vez para establecer el listener de redimensionamiento

    const login = async (inputs) => {
        const res = await newRequest.post('/api/login/sesion', inputs, {
            withCredentials: true,
        });
        if (res.status === 200) {
            setIsAuthenticated(true);
            setCurrentUser(res.data);
        }
    };

    const logout = () => {
        Cookies.remove("token");
        Cookies.remove("session_id");
        setCurrentUser(null);
        setIsAuthenticated(false);
        localStorage.clear();
        newRequest.post('/api/login/cerrarSesion', { withCredentials: true });
    };

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        if (storedUser) {
            setCurrentUser(storedUser);
            setIsAuthenticated(true);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(currentUser));
    }, [currentUser]);

    return (
        <AuthContext.Provider value={{ currentUser, login, setCurrentUser, logout, isAuthenticated, isMobile }}>
            {children}
        </AuthContext.Provider>
    );
};
