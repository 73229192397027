import { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import newRequest from '../../../../utils/newRequest';
import { es } from 'date-fns/locale';

const usePagosWompi = () => {
    // Estados generales
    const [datos, setDatos] = useState([]);
    const [referencia, setReferencia] = useState("");
    const [checked, setChecked] = useState(true);
    const [estado, setEstado] = useState("");
    const [datosReferencia, setDatosReferencia] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const hoy = new Date();
    const diaAnterior = new Date();
    diaAnterior.setDate(hoy.getDate() - 1);

    const [fechaInicio, setFechaInicio] = useState(format(diaAnterior, "yyyy-MM-dd"));
    const [fechaFin, setFechaFin] = useState(format(hoy, "yyyy-MM-dd"));

    const [mostrarDetalles, setMostrarDetalles] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    // Obtener transferencias
    const obtenerTransferencia = useCallback(async () => {
        try {
            const params = {
                referencia,
                estado,
                checked,
                ...(fechaInicio && fechaFin ? { fechaInicio, fechaFin } : {}),
            };
            const res = await newRequest.get(`/api/Financiera/financieraRoutes/obtenerTransferencias`, {
                params,
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                },
            });
            setDatos(res.data);
        } catch (error) {
            console.error("Error al obtener transferencias:", error);
        }
    }, [fechaInicio, fechaFin, referencia, estado, checked]);

    // Obtener referencia de factura seleccionada
    const obtenerReferencia = useCallback(async (ref) => {
        setLoading(true);
        setError(null);
        try {
            const res = await newRequest.get(`/api/Financiera/financieraRoutes/obtenerReferencia`, {
                params: { referencia: ref },
                headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache' },
            });
            const data = res.data?.data?.[0] || null;
            setDatosReferencia(data);
            setMostrarDetalles(true);
        } catch (error) {
            console.error("Error al obtener referencia:", error);
            setError('No se pudo cargar la referencia.');
        } finally {
            setLoading(false);
        }
    }, []);

    // Validar fechas
    const validarFechas = (inicio, fin) => {
        if (new Date(inicio) > new Date(fin)) {
            alert("La fecha inicial no puede ser mayor que la fecha final.");
            return false;
        }
        return true;
    };

    // Handlers
    const handleFechaInicioChange = (e) => {
        const nuevaFechaInicio = e.target.value;
        if (validarFechas(nuevaFechaInicio, fechaFin)) setFechaInicio(nuevaFechaInicio);
    };

    const handleFechaFinChange = (e) => {
        const nuevaFechaFin = e.target.value;
        if (validarFechas(fechaInicio, nuevaFechaFin)) setFechaFin(nuevaFechaFin);
    };

    const handleSearchChange = useCallback((e) => setReferencia(e.target.value), []);
    const handleEstadoChange = (e) => setEstado(e.target.value);
    const handleChange = useCallback((e) => setChecked(e.target.checked), []);

    useEffect(() => {
        obtenerTransferencia();
    }, [obtenerTransferencia]);

    return {
        datos,
        referencia,
        handleSearchChange,
        fechaInicio,
        handleFechaInicioChange,
        fechaFin,
        handleFechaFinChange,
        estado,
        handleEstadoChange,
        checked,
        handleChange,
        datosReferencia,
        mostrarDetalles,
        obtenerReferencia,
        loading,
        setLoading,
        error,
        setError,
        selectedRows,
        setDatosReferencia,
        setMostrarDetalles,
    };
};

export default usePagosWompi;
