import { Box, Button, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import dos from "../../../images/logo-farmacomercial.png";
import facturas from "../../../images/facturas.jpg";
import newRequest from '../../../utils/newRequest';

import LogoutIcon from '@mui/icons-material/Logout';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { fetchFacturas, iniciarSesion, insertarPrimeraFac } from './Services/facturasServices';
import FacturasTable from './components/TableFacture';
import WompiButton from './components/WompiButton';
import LoginClient from './components/LoginClient';
import { toast } from 'react-toastify';

import logo from "../../../images/siglasFarma.png"
const PagoFacturas = () => {

    const [documento, setDocumento] = useState("31");
    const [identificacion, setIdentificacion] = useState("");
    const [usuario, setUsuario] = useState(null);
    const [loading, setLoading] = useState(true);
    const [facturasData, setFacturasData] = useState([]);
    const [totalSeleccionado, setTotalSeleccionado] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);

    const [wompiData, setWompiData] = useState({
        hash: null,
        referencias: null,
        sumaSaldo: null,
    });

    useEffect(() => {
        const savedSelections = JSON.parse(sessionStorage.getItem('selectedRows')) || [];
        // Filtrar filas que ya no existen en facturasData
        const validSelections = savedSelections.filter(savedRow =>
            facturasData.some(row => row.Documento === savedRow.Documento)
        );
        setSelectedRows(validSelections);
        // const total = validSelections.reduce((sum, row) => sum + row.saldo, 0); // Saldo principal
        const total = validSelections.reduce((sum, row) => sum + row.saldoAbonar, 0); // Saldo abonar
        setTotalSeleccionado(total);
        // Actualizar sessionStorage con las selecciones válidas
        sessionStorage.setItem('selectedRows', JSON.stringify(validSelections));
    }, [facturasData]);

    useEffect(() => {
        // Guardar las filas seleccionadas en sessionStorage cada vez que cambien
        sessionStorage.setItem('selectedRows', JSON.stringify(selectedRows));
    }, [selectedRows]);

    const handleIniciarSesion = async () => { // Inicio de sesión
        try {
            const usuarioData = await iniciarSesion(identificacion, documento);
            if (usuarioData) {
                // alert("Sesión iniciada correctamente");
                toast.success("Sesión iniciada correctamente", {
                    position: "bottom-left",
                    autoClose: 5000,
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });

                setUsuario(usuarioData.data);
                sessionStorage.setItem('usuario', JSON.stringify(usuarioData.data));

                // Cargar las facturas inmediatamente después de iniciar sesión
                await loadFacturas(usuarioData.data[0]?.nitsec);
            } else {
                alert(usuarioData.data.message || "Error en la autenticación");
            }
        } catch (error) {
            console.error("Error al iniciar sesión:", error);
            alert("Ocurrió un error al intentar iniciar sesión. Por favor verifica los datos ingresados.");
        }
    };

    const loadFacturas = async (nitsec) => {
        try {
            setLoading(true);

            if (!nitsec) {
                console.error("NitSec no disponible para cargar facturas");
                return;
            }

            console.log("Cargando facturas para NitSec:", nitsec);
            const data = await fetchFacturas(nitsec);

            const dataWithSaldoAbonar = data.map((item) => ({  // Agrega el campo 'saldoAbonar' inicializado con el valor de 'saldo'
                ...item,
                saldoAbonar: item.saldo, // Nuevo campo editable
            }));

            setFacturasData(dataWithSaldoAbonar); // Guarda los datos en el estado
        } catch (error) {
            console.error("Error al cargar las facturas:", error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        const usuarioData = sessionStorage.getItem('usuario');
        if (usuarioData) {
            const parsedUser = JSON.parse(usuarioData);
            setUsuario(parsedUser);
            const nitsec = parsedUser[0]?.nitsec;
            loadFacturas(nitsec); // Carga las facturas si el usuario ya está logueado
        } else {
            setLoading(false);
        }
    }, []);


    const formatCurrency = (value) => {
        return `$${new Intl.NumberFormat('es-ES').format(value)}`;
    };

    const handleLogout = () => {
        sessionStorage.removeItem('usuario');
        sessionStorage.removeItem('selectedRows');
        setUsuario(null);
        setFacturasData([]);
        setTotalSeleccionado(0);
        setSelectedRows([]);
    };

    const handleCheckboxChange = (row) => { // Check que selecciona las facturas
        const isSelected = selectedRows.find(selectedRow => selectedRow.Documento === row.Documento);
        let updatedSelectedRows;

        if (isSelected) {
            updatedSelectedRows = selectedRows.filter(selectedRow => selectedRow.Documento !== row.Documento);
        } else {
            updatedSelectedRows = [...selectedRows, row];
        }

        setSelectedRows(updatedSelectedRows);

        // const total = updatedSelectedRows.reduce((sum, selectedRow) => sum + selectedRow.saldo, 0); // Saldo principal
        const total = updatedSelectedRows.reduce((sum, selectedRow) => sum + selectedRow.saldoAbonar, 0); // Saldo abonado
        setTotalSeleccionado(total);
    };

    // Pago de wompi
    const ejecutarPago = async () => {
        // Pasa el test de integridad que es la llave del pago
        // const idIntegridad = `test_integrity_5vqK7WqBKI6QuUXzwz5J31FmnpRujoL6`; //Modo prueba
        const idIntegridad = `prod_integrity_q9ndEjjqW3Zys1jEFjJph0Xs7x6w5W7p`; // Modo producción
        try {
            const response = await newRequest.post('/api/transaccion', {
                integridad: idIntegridad,
                factura: selectedRows,
                nombreCliente: usuario[0]?.nitcom,
                identificacion: usuario[0]?.NitIde,
            });

            if (response.data && response.data.hashHex) {
                const { hashHex, sumaSaldos, referenciaUnica, detalleAbono } = response.data;

                // Actualiza el estado con los valores necesarios para el botón Wompi
                setWompiData({
                    hash: hashHex,
                    referencias: referenciaUnica,
                    sumaSaldo: sumaSaldos,
                });

                // Divide detalleAbono en valores individuales
                const abonos = detalleAbono.split(",").map(value => parseInt(value.trim(), 10)); // Divide y limpia espacios

                // Valida que la cantidad de facturas coincida con los abonos
                if (abonos.length !== selectedRows.length) {
                    console.error("La cantidad de abonos no coincide con las facturas seleccionadas");
                    return;
                }

                // Itera sobre cada factura seleccionada e inserta en la base de datos
                for (let i = 0; i < selectedRows.length; i++) {
                    const factura = selectedRows[i];
                    const valorAbonar = abonos[i];
                    await insertarPrimeraFac({
                        idFacSec: factura.facsec, // Valor único de la factura
                        valorFac: factura.saldo, // Valor único de la factura
                        referenciaFac: referenciaUnica, // Valor compartido
                        referenciaValFac: sumaSaldos, // Valor compartido
                        referenciaValorAbono: valorAbonar,  // Valor abono individual

                    });
                }
            }
        } catch (error) {
            console.error("Error al ejecutar el pago:", error);
        }
    };

    const handleUpdateSaldo = (index, newSaldo) => {
        setFacturasData((prevData) => {
            const updatedData = [...prevData]; // Clona el arreglo original
            updatedData[index] = { ...updatedData[index], saldoAbonar: newSaldo }; // Modifica solo saldoAbonar
            return updatedData;
        });
    };

    return (
        <>
            {usuario ? (
                <Container maxWidth="xl">
                    <Grid container spacing={2} sx={{ mt: 3, }}>

                        {/* Mobile Layout - Logo and Button */}
                        <Grid container size={"grow"} sx={{
                            display: { xs: 'flex', sm: 'none' },
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2
                        }}>
                            <Grid item size={{ xs: 6 }}>
                                <img src={logo} alt='Logo' style={{ width: '100%', maxWidth: '200px', height: 'auto' }} />
                            </Grid>
                            <Grid item size={{ xs: 6 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    sx={{ color: "red" }}
                                    onClick={() => handleLogout()}
                                    startIcon={<LogoutIcon />}
                                >
                                    Salir
                                </Button>
                            </Grid>
                        </Grid>

                        {/* Desktop Layout - Logo */}
                        <Grid size={{ xs: 12, sm: 3 }} sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <img src={logo} alt='Logo' style={{ width: '100%', maxWidth: '200px', height: 'auto' }} />
                        </Grid>

                        {/* Welcome Text - Both Layouts */}
                        <Grid size={{ xs: 12, sm: 6 }} sx={{ marginLeft: "2em" }}>
                            <Box sx={{ textAlign: "center", width: '100%', px: 2 }}>
                                <Typography variant='body2' sx={{
                                    textAlign: "center",
                                    lineHeight: 1.4,
                                    wordWrap: 'break-word',
                                    maxWidth: { xs: '100%', sm: '500px' },
                                    mx: 'auto'
                                }}>
                                    Hola 👋, {usuario[0]?.nitcom || "Usuario"}
                                </Typography>
                                <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' }, mt: 1 }}>
                                    ¡Bienvenido a nuestra plataforma de pago!
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid size="grow" sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'flex-end' }}>
                            <Button sx={{ color: "red" }} onClick={() => handleLogout()}
                                startIcon={<LogoutIcon />}
                            >
                                Salir
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{
                        height: '85vh', // Toma el alto completo de la ventana
                        paddingTop: "1em"
                    }}>
                        <Grid size={{ xs: 12, md: 8 }} >
                            <FacturasTable
                                facturasData={facturasData}
                                selectedRows={selectedRows}
                                onCheckboxChange={handleCheckboxChange}
                                onUpdateSaldo={handleUpdateSaldo} // Nueva función
                                loading={loading}
                            />
                        </Grid>

                        <Grid size={{ xs: 12, md: 4 }} sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2
                        }}>
                            <Card variant="outlined" sx={{
                                flex: 1,
                                borderRadius: "12px",
                                display: 'flex',
                                flexDirection: 'column',
                                height: 'calc(50% - 1em)',
                                minHeight: '250px'
                            }}>
                                <CardContent sx={{
                                    flex: 1,
                                    overflow: 'auto'
                                }}>
                                    <Typography noWrap variant='h5' textAlign="center">
                                        Verificar Pago
                                    </Typography>

                                    <Typography sx={{ marginTop: "1em" }}>
                                        Identificación: <br />
                                        {usuario[0]?.NitIde || "Usuario"}
                                    </Typography>

                                    <Typography noWrap sx={{ marginTop: "1em" }}>
                                        Nombre: <br />
                                        {usuario[0]?.nitcom || "Usuario"}
                                    </Typography>
                                    <Typography noWrap sx={{ marginTop: "1em" }}>
                                        Total del pago seleccionado: <br />
                                        {formatCurrency(totalSeleccionado)}
                                    </Typography>
                                </CardContent>

                                <CardActions sx={{ p: 2 }}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        size="small"
                                        onClick={ejecutarPago}
                                    >
                                        Continuar con el pago
                                    </Button>
                                </CardActions>

                                {wompiData.hash && wompiData.referencias && wompiData.sumaSaldo && (
                                    <WompiButton
                                        hash={wompiData.hash}
                                        referencias={wompiData.referencias}
                                        sumaSaldo={wompiData.sumaSaldo}
                                        autoClick={true}
                                    />
                                )}
                            </Card>

                            <Card variant="outlined" sx={{
                                flex: 1,
                                borderRadius: "12px",
                                display: 'flex',
                                flexDirection: 'column',
                                height: 'calc(50% - 1em)',
                                minHeight: '250px'
                            }}>
                                <CardContent sx={{
                                    flex: 1,
                                    overflow: 'auto'
                                }}>
                                    <Typography variant='h5' textAlign="center">
                                        Facturas seleccionadas
                                    </Typography>
                                    {selectedRows.length > 0 ? (
                                        <Box sx={{ mt: 2 }}>
                                            {selectedRows.map((factura, index) => (
                                                <Box key={index} sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                                                    <Typography variant="body1">Documento: {factura.Documento}</Typography>
                                                    <Typography variant="body1">Saldo: {formatCurrency(factura.saldoAbonar)}</Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                    ) : (
                                        <Typography variant="body2" color="textSecondary" textAlign="center" sx={{ mt: 2 }}>
                                            No hay facturas seleccionadas
                                        </Typography>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            ) : (
                <section id="features">
                    <LoginClient
                        facturas={facturas}
                        dos={dos}
                        documento={documento}
                        setDocumento={setDocumento}
                        identificacion={identificacion}
                        setIdentificacion={setIdentificacion}
                        handleIniciarSesion={handleIniciarSesion}
                    />
                </section >
            )}

        </>
    );
}

export default PagoFacturas;
