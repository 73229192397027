import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Tooltip, useMediaQuery } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import logoFarma from "../../images/siglasFarma.png";
import { AuthContext } from '../../loginContext';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Index from './Components/QrModal';
import SettingsMenu from './Components/SettingsMenu';
import DrawerList from './Components/DrawerList';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    variants: [
        {
            props: ({ open }) => open,
            style: {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
    ],
}));

function AppbarMenu() {

    const location = useLocation();

    // Mapeo de rutas a índices
    const routeToIndex = {
        '/Farmanet': 0,
        '/Farmanet/GerenciaVisual': 1,
        '/Farmanet/Calidad': 2,
        '/Farmanet/GestionComercial': 3,
        '/Farmanet/Importaciones': 4,
        '/Farmanet/Financiera': 5,
        '/Farmanet/Tecnica': 6
    };

    // Función para obtener el índice basado en la ruta actual
    const getIndexFromPath = (pathname) => {
        return routeToIndex[pathname] ?? 0; // retorna 0 si no encuentra la ruta
    };

    // Modificar la inicialización del selectedIndex
    const [selectedIndex, setSelectedIndex] = React.useState(() => {
        const storedIndex = sessionStorage.getItem('selectedIndex');
        if (storedIndex) {
            return parseInt(storedIndex);
        }
        return getIndexFromPath(location.pathname);
    });

    const { currentUser, logout } = React.useContext(AuthContext);
    const navigate = useNavigate();

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openMobile, setOpenMobile] = React.useState(false);
    const openMenu = Boolean(anchorEl);
    const [openQr, setOpenQr] = React.useState(false);

    const [openDrawer, setOpenDrawer] = React.useState(false); // Estado para abrir y cerrar el modal

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const toggleDrawer = (newOpen) => () => {
        setOpenMobile(newOpen);
    };

    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detecta si es un dispositivo móvil

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleOpenQr = () => setOpenQr(true);

    const handleCloseQr = () => {
        setOpenQr(false)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Efecto para cerrar el drawer si no es un dispositivo móvil
    React.useEffect(() => {
        if (!isMobile) {
            setOpenMobile(false); // Cierra el drawer si detecta que no es móvil
            setOpen(false); // Opcionalmente cierra también el drawer lateral
        }
    }, [isMobile]);

    // Efecto para actualizar el índice cuando cambia la ruta
    React.useEffect(() => {
        const currentIndex = getIndexFromPath(location.pathname);
        setSelectedIndex(currentIndex);
        sessionStorage.setItem('selectedIndex', currentIndex.toString());
    }, [location.pathname]);

    const navegarFirma = () => {
        setSelectedIndex('')
        handleClose();
        navigate("/Farmanet/FirmaDigital")
    }

    const CreacionReq = () => {
        navigate("/Farmanet/CreacionTickets")
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBar
                elevation={0}
                position="fixed"
                style={{
                    background: "white",
                    borderBottomWidth: 1,
                    borderBottomColor: "#e0e0e0",
                    borderBottomStyle: "solid"
                }}>
                <Toolbar style={{ backgroundColor: "white" }}>
                    {/* Vista desde el celular */}
                    {isMobile ? <>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer(true)} // Condicional para abrir/cerrar
                            edge="start"
                        >
                            {openMobile ? <ChevronLeftIcon sx={{ color: "#757575" }} />
                                : <MenuIcon sx={{ color: "#757575" }} />} {/* Condiciona el ícono según el estado */}
                        </IconButton >

                        <div style={{
                            display: "flex",
                            alignItems: "center", // Centra los elementos verticalmente
                            justifyContent: "space-between", // Asegura que los elementos se mantengan alineados correctamente
                            // padding: "10px", // Añade un padding opcional para espaciar los elementos del borde
                            paddingLeft: "20px",
                            width: "100%", // Asegura que el contenedor ocupe todo el ancho
                            boxSizing: "border-box"
                        }}>
                            <img style={{ marginRight: 5 }} src={logoFarma} alt='Logo Farma'
                                height={35} />

                            <div style={{ display: "flex", alignItems: "center" }}>



                                <Tooltip title="Crear solicitud">
                                    <IconButton
                                        aria-label="settings"
                                        sx={{ color: "#323338", borderRadius: "10px" }}
                                        onClick={() => setOpenDrawer(true)}
                                    >
                                        <AddCircleIcon />
                                    </IconButton>
                                </Tooltip>

                                <IconButton
                                    aria-label="settings"
                                    sx={{ color: "#323338", borderRadius: "10px" }}
                                >
                                    <NotificationsNoneIcon />
                                </IconButton>


                                <IconButton
                                    aria-label="settings"
                                    sx={{ color: "black", borderRadius: "100%" }}
                                    onClick={handleClick} // Asegúrate de manejar el click event aquí
                                >
                                    <SettingsIcon />
                                </IconButton>
                            </div>
                        </div>
                    </> : <>
                        {/* Vista desde el pc */}
                        <>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={open ? handleDrawerClose : handleDrawerOpen} // Condicional para abrir/cerrar
                                edge="start"

                            >
                                {open ? <ChevronLeftIcon sx={{ color: "#757575" }} /> : <MenuIcon sx={{ color: "#757575" }} />} {/* Condiciona el ícono según el estado */}
                            </IconButton>

                            <div style={{
                                display: "flex",
                                alignItems: "center", // Centra los elementos verticalmente
                                justifyContent: "space-between", // Asegura que los elementos se mantengan alineados correctamente
                                // padding: "10px", // Añade un padding opcional para espaciar los elementos del borde
                                paddingLeft: "20px",
                                width: "100%", // Asegura que el contenedor ocupe todo el ancho
                                boxSizing: "border-box"
                            }}>
                                {/* Logo y nombre a la izquierda */}
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <img style={{ marginRight: 5 }} src={logoFarma} alt='Logo Farma'
                                        height={40} />
                                </div>

                                {/* Nombre de usuario y el icono de configuración a la derecha */}
                                <div style={{ display: "flex", alignItems: "center" }}>


                                    <Tooltip title="Tickets">
                                        <IconButton
                                            aria-label="settings"
                                            sx={{ color: "#323338", borderRadius: "10px" }}
                                            // onClick={() => setOpenDrawer(true)}
                                            onClick={() => CreacionReq()}
                                        >
                                            {/* <AddCircleIcon /> */}
                                            <ConfirmationNumberIcon />

                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Ver mesanjes">
                                        <IconButton
                                            aria-label="settings"
                                            sx={{ color: "#323338", borderRadius: "10px" }}
                                        >
                                            <NotificationsNoneIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Configuraciones">
                                        <IconButton
                                            aria-label="settings"
                                            sx={{ color: "#323338", borderRadius: "10px" }}
                                            onClick={handleClick} // Asegúrate de manejar el click event aquí
                                        >
                                            <SettingsIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            {toggleDrawer(false)}
                        </></>}
                </Toolbar>
            </AppBar>

            <DrawerList
                open={open}
                isMobile={isMobile}
                selectedIndex={selectedIndex}
                handleListItemClick={handleListItemClick}
                handleDrawerClose={handleDrawerClose}
                toggleDrawer={toggleDrawer}
                openMobile={openMobile}
            />

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 2,
                    overflow: 'auto', // Permite el desplazamiento si el contenido excede el tamaño
                    maxHeight: '100vh', // Limita la altura máxima al tamaño de la ventana
                    boxSizing: 'border-box', // Asegura que el padding esté incluido en el tamaño total
                }}
            >
                <DrawerHeader />
                <Outlet />
            </Box>

            {/* Modal Qr */}
            {/* <Modalqr open={openQr} handleClose={handleCloseQr} /> */}
            <Index open={openQr} handleClose={handleCloseQr} />

            {/* Menu */}
            <SettingsMenu anchorEl={anchorEl} open={openMenu} onClose={handleClose}
                onQrOpen={handleOpenQr} onFirmaClick={navegarFirma} onLogout={logout} />

        </Box>
    );
}

export default AppbarMenu;