import React, { useCallback, useContext, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import FormularioReq from './FormularioReq'
import {
    Box, Breadcrumbs, Button, CircularProgress, Divider, Fab, InputAdornment,
    Paper, styled, TextField, ToggleButton, Typography
} from '@mui/material';

import ListaTickets from './ListaTickets';
// import ListaRequerimientos from './ListaTickets';

import newRequest from '../../utils/newRequest';
// import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ToggleButtonGroup, {
    toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';
import { AuthContext } from '../../loginContext';
import SearchIcon from '@mui/icons-material/Search';
import DetalleTickets from './DetalleTickets';


// Estilos con styled-components
// Estilos personalizados para ToggleButtonGroup
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

function CreacionTickets() {

    // const { currentUser } = React.useContext(AuthContext);

    // const [refresh, setRefresh] = useState(false); // Estado compartido para refrescar
    // const [showFormularioReq, setShowFormularioReq] = useState(false); // Controla la visibilidad del formulario
    // const [idTicket, setidTicket] = useState(null);
    // const [idHiloTicket, setIdHiloTicket] = useState(null)
    // const [hiloTicket, setHiloTicket] = useState([])
    // const [formulario, setFormulario] = useState([]);
    // const [file, setFile] = useState(null); // Estado para almacenar el archivo seleccionado
    // const [hasAdjunto, setHasAdjunto] = useState(false); // Estado para indicar si hay archivo adjunto
    // const [idEstado, setidEstado] = useState(1)
    // const [estadoSolicitud, setEstadoSolicitud] = useState([]);
    // const [searchQuery, setSearchQuery] = useState("");
    // const [selectedDate, setSelectedDate] = useState(null);
    // const [requerimiento, setRequerimiento] = useState([])

    // const navigate = useNavigate();

    // const handleRefresh = () => {
    //     setRefresh((prev) => !prev);
    // };

    // const toggleFormularioReq = () => {
    //     setFile(null); // Limpia el archivo seleccionado
    //     setShowFormularioReq((prev) => {
    //         if (prev === true) {
    //             // Si estábamos mostrando el formulario y ahora lo cerramos:
    //             setFormulario([]);
    //             setidTicket(null); // Limpia también el idTicket

    //             setFile(null); // Limpia el archivo seleccionado
    //             setHasAdjunto(false); // Indica que no hay archivo adjunto

    //         }
    //         return !prev;
    //     });
    // };

    // const obtenerIdTicket = useCallback(async () => {
    //     if (!idTicket) return; // No cargar si no hay un ID definido
    //     try {
    //         const res = await newRequest.get(`/api/requerimiento/obtenerIdTicket/${idTicket}`);
    //         const ticketData = res.data.data; // Accede a `data`
    //         // console.log("Datos del formulario:", ticketData);
    //         setFormulario(ticketData || []); // Asegúrate de que sea un objeto válido
    //         // En lugar de togglear, lo forzamos a que se abra
    //         setShowFormularioReq(true);
    //     } catch (error) {
    //         console.error("Error al obtener el ticket:", error);
    //         setFormulario([]);
    //     }
    // }, [idTicket]);

    // const obtenerHiloTicket = useCallback(async () => {
    //     if (!idHiloTicket) return; // No cargar si no hay un ID definido
    //     try {
    //         const res = await newRequest.get(`/api/requerimiento/obtenerHiloTicket/${idHiloTicket}`);
    //         const ticketData = res.data.data; // Accede a `data`
    //         console.log("Datos del formulario:", ticketData);
    //         setHiloTicket(ticketData || []); // Asegúrate de que sea un objeto válido
    //         // En lugar de togglear, lo forzamos a que se abra
    //         // setShowFormularioReq(true);
    //     } catch (error) {
    //         console.error("Error al obtener el ticket:", error);
    //         setHiloTicket([]);
    //     }
    // }, [idHiloTicket]);

    // const obtenerEstado = useCallback(async () => {
    //     try {
    //         const res = await newRequest.post('/api/requerimiento/estadosolicitud', {
    //             usuario: currentUser.userData?.UsuCod,
    //         });
    //         setEstadoSolicitud(res.data.tipo);
    //     } catch (error) {
    //         setEstadoSolicitud([])
    //         console.error('Error al obtener los requerimientos:', error);
    //     }
    // }, [currentUser.userData?.UsuCod]);

    // const obtenerRequerimiento = useCallback(async () => {
    //     try {
    //         const res = await newRequest.post('/api/requerimiento/obtenerRequerimiento', {
    //             idEstado: idEstado,
    //             usuario: currentUser.userData?.UsuCod,
    //         });
    //         // Si la respuesta es válida, actualiza los requerimientos
    //         setRequerimiento(res.data.dbData);
    //     } catch (error) {
    //         console.error('Error al obtener los requerimientos:', error);
    //         // Si hay un error, limpia el estado de requerimientos
    //         setRequerimiento([]);
    //     }
    // }, [idEstado, currentUser.userData?.UsuCod]);

    // const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    //     margin: theme.spacing(0.5),
    // }));

    // useEffect(() => {
    //     obtenerRequerimiento();
    //     obtenerEstado();

    //     obtenerIdTicket();
    //     obtenerHiloTicket();
    // }, [idEstado, refresh, obtenerRequerimiento, obtenerEstado,
    //     obtenerIdTicket, obtenerHiloTicket
    // ]);

    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();

    // Estados principales agrupados
    const [refresh, setRefresh] = useState(false);
    const [showFormularioReq, setShowFormularioReq] = useState(false);
    const [idTicket, setIdTicket] = useState(null);
    const [idHiloTicket, setIdHiloTicket] = useState(null);
    const [hiloTicket, setHiloTicket] = useState([]);
    const [formulario, setFormulario] = useState([]);
    const [file, setFile] = useState(null);
    const [hasAdjunto, setHasAdjunto] = useState(false);
    const [idEstado, setIdEstado] = useState(1);
    const [estadoSolicitud, setEstadoSolicitud] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [requerimiento, setRequerimiento] = useState([]);

    // Estados de carga y error
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Función para manejar el refresco
    const handleRefresh = () => {
        setRefresh((prev) => !prev);
    };

    // Función para togglear el formulario
    // const toggleFormularioReq = () => {
    //     if (showFormularioReq) {
    //         // Si se cierra el formulario, limpiar ciertos estados
    //         setFormulario([]);
    //         setIdTicket(null);
    //         setFile(null);
    //         setHasAdjunto(false);
    //     }
    //     setShowFormularioReq((prev) => !prev);
    // };

    // Función para togglear el formulario
    const toggleFormularioReq = () => {
        if (showFormularioReq) {
            // Si se cierra el formulario, limpiar ciertos estados
            setFormulario([]);
            setIdTicket(null);
            setFile(null);
            setHasAdjunto(false);
        } else {
            // Si se abre el formulario, cerrar DetalleTickets si está abierto
            if (idHiloTicket) {
                setIdHiloTicket(null);
                setHiloTicket([]);
            }
        }
        setShowFormularioReq((prev) => !prev);
    };

    // Función para obtener requerimientos y estados en paralelo
    const fetchRequerimientosYEstados = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const [estadoRes, requerimientoRes] = await Promise.all([
                newRequest.post('/api/requerimiento/estadosolicitud', {
                    usuario: currentUser.userData?.UsuCod,
                }),
                newRequest.post('/api/requerimiento/obtenerRequerimiento', {
                    idEstado: idEstado,
                    usuario: currentUser.userData?.UsuCod,
                }),
            ]);

            const nuevosEstados = estadoRes.data.tipo || [];
            setEstadoSolicitud((prev) => (nuevosEstados.length > 0 ? nuevosEstados : prev));
            setRequerimiento(requerimientoRes.data.dbData);
        } catch (err) {
            setRequerimiento([])
            console.error('Error al obtener los requerimientos:', err);
            setError('No se pudieron cargar los requerimientos.');
        } finally {
            setLoading(false);
        }
    }, [currentUser.userData?.UsuCod, idEstado]);


    // Función para obtener detalles del ticket
    const obtenerIdTicket = useCallback(async () => {
        if (!idTicket) return;
        try {
            const res = await newRequest.get(`/api/requerimiento/obtenerIdTicket/${idTicket}`);
            const ticketData = res.data.data || [];
            setFormulario(ticketData);
            setShowFormularioReq(true);
        } catch (err) {
            console.error('Error al obtener el ticket:', err);
            setFormulario([]);
        }
    }, [idTicket]);

    // Función para obtener el hilo del ticket
    const obtenerHiloTicket = useCallback(async () => {
        if (!idHiloTicket) return;
        try {
            const res = await newRequest.get(`/api/requerimiento/obtenerHiloTicket/${idHiloTicket}`);
            const ticketData = res.data.data || [];
            setHiloTicket(ticketData);
        } catch (err) {
            console.error('Error al obtener el hilo del ticket:', err);
            setHiloTicket([]);
        }
    }, [idHiloTicket]);

    // Función manejadora para seleccionar un hilo de ticket
    // const handleSelectHiloId = useCallback((id) => {
    //     setIdHiloTicket(id);
    //     setShowFormularioReq(false); // Cierra el formulario de nuevo ticket
    // }, []);

    // Función manejadora para seleccionar un hilo de ticket
    const handleSelectHiloId = useCallback((id) => {
        setIdHiloTicket(id);
        setShowFormularioReq(false); // Cierra el formulario de nuevo ticket
    }, []);

    // useEffect para obtener requerimientos y estados
    useEffect(() => {
        fetchRequerimientosYEstados();
    }, [fetchRequerimientosYEstados, refresh]);

    // useEffect para obtener formulario cuando idTicket cambia
    useEffect(() => {
        obtenerIdTicket();
    }, [obtenerIdTicket]);

    // useEffect para obtener hilo del ticket cuando idHiloTicket cambia
    useEffect(() => {
        obtenerHiloTicket();
    }, [obtenerHiloTicket]);

    // Funciones memorizadas para evitar re-renderizados innecesarios
    // const handleSelectTicket = useCallback((id) => {
    //     setIdTicket(id);
    // }, []);

    // const handleSelectHilo = useCallback((idHilo) => {
    //     setIdHiloTicket(idHilo);
    // }, []);


    const handleSelectHilo = useCallback((idHilo) => {
        handleSelectHiloId(idHilo);
    }, [handleSelectHiloId]);

    // useEffect(() => {
    //     obtenerIdTicket();
    //     obtenerHiloTicket();
    // }, [obtenerIdTicket, obtenerHiloTicket])

    console.log("hiloTicket", hiloTicket)

    return (
        <>
            <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 12, sm: 6 }}>
                    <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "1em" }}>

                            <Fab onClick={() => navigate(-1)} size='small'
                                style={{ background: "white", borderRadius: 13 }}>
                                <ChevronLeftIcon />
                            </Fab>

                            <Breadcrumbs aria-label="breadcrumb" style={{ paddingLeft: "15px" }}>
                                <Typography color="text.primary">Gestión de Tickets</Typography>
                            </Breadcrumbs>
                        </Box>

                        <Grid container spacing={1}
                            justifyContent="flex-end" // Alinea los elementos secundarios a la derecha
                        >
                            <Grid size="auto">
                                <Paper
                                    elevation={0}
                                    sx={(theme) => ({
                                        display: 'flex',
                                        border: `1px solid ${theme.palette.divider}`,
                                        flexWrap: 'wrap',
                                        borderRadius: "8px",
                                        marginTop: "-3px"
                                    })}
                                >

                                    <StyledToggleButtonGroup
                                        size="small"
                                        value={idEstado}
                                        exclusive
                                    >
                                        {estadoSolicitud.map((req, index) => (
                                            <ToggleButton
                                                key={req.idEstadoSolicitud || index}
                                                value={req.idEstadoSolicitud}
                                                aria-label={req.estado}
                                                onClick={() => setIdEstado(req.idEstadoSolicitud)}
                                                sx={{ color: req.color, border: "none" }}
                                            >
                                                {req.estado} ({req.cantidad})
                                            </ToggleButton>
                                        ))}
                                    </StyledToggleButtonGroup>
                                </Paper>
                            </Grid>
                            <Grid size="auto">
                                <TextField
                                    size="small"
                                    type="date"
                                    sx={{
                                        backgroundColor: "white",
                                        borderRadius: "8px", width: "180px",
                                        "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                                    }}
                                    fullWidth
                                    value={selectedDate}
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                />
                            </Grid>

                            <Grid size="auto">
                                <TextField
                                    sx={{
                                        backgroundColor: "white",
                                        borderRadius: "8px",
                                        width: "180px",
                                        "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                                    }}

                                    fullWidth
                                    autoComplete="off"
                                    size="small"
                                    label="Buscar tickets..."
                                    variant="outlined"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid size="auto">
                                <Button variant="contained" onClick={toggleFormularioReq}
                                    startIcon={showFormularioReq ? <CloseIcon /> : <ControlPointIcon />}
                                    sx={{
                                        borderRadius: "8px",
                                        borderColor: "#e4e4e7",
                                        borderWidth: "2px",
                                        // marginTop: 0.5,
                                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                        '&:hover': {
                                            borderColor: "#e4e4e7",
                                            backgroundColor: "#115293",
                                            boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)',
                                        }
                                    }}
                                >
                                    {showFormularioReq ? "Cerrar" : "Crear"}
                                </Button>
                            </Grid>
                        </Grid>


                    </Box>
                </Grid>
            </Grid>

            <Paper variant='outlined' sx={{
                padding: 2, borderRadius: 2, overflow: "auto"
            }}>
                <Grid container spacing={2} >
                    <Grid size={{ xs: 12, md: 3 }} sx={{
                        display: showFormularioReq ? 'block' : 'none'
                    }}
                    >
                        <FormularioReq
                            onTicketCreated={handleRefresh}
                            onClose={toggleFormularioReq}
                            datosFormulario={formulario}
                            file={file} // Pasamos el estado file
                            setFile={setFile} // Pasamos la función actualizadora setFile
                            hasAdjunto={hasAdjunto} // Pasamos el estado hasAdjunto
                            setHasAdjunto={setHasAdjunto} // Pasamos la función actualizadora setHasAdjunto
                        />
                    </Grid>

                    {/* Solo mostramos el Divider si el formulario está abierto */}
                    {showFormularioReq && <Divider orientation='vertical' flexItem />}

                    <Grid size={showFormularioReq ? 'grow' : 'grow'} >
                        {loading ? (
                            // Muestra el loader mientras loading es true
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "75vh",
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Box sx={{ overflow: "auto", height: "75vh" }}>
                                {/* // Renderiza ListaTickets una vez que loading sea false */}
                                <ListaTickets
                                    idEstado={idEstado}
                                    requerimiento={requerimiento}
                                    searchQuery={searchQuery}
                                    selectedDate={selectedDate}
                                    refresh={refresh}
                                    onSelectTicket={setIdTicket} // Pasamos el setter
                                    onSelectHiloId={setIdHiloTicket}
                                    toggleFormularioReq={toggleFormularioReq}
                                    idHiloTicket={idHiloTicket}
                                />
                            </Box>
                        )}
                        {/* <ListaTickets
                            idEstado={idEstado}
                            requerimiento={requerimiento}
                            searchQuery={searchQuery}
                            selectedDate={selectedDate}
                            refresh={refresh}
                            onSelectTicket={setIdTicket} // Pasamos el setter
                            onSelectHiloId={setIdHiloTicket}
                            toggleFormularioReq={toggleFormularioReq}
                            idHiloTicket={idHiloTicket}
                        // formulario={formulario} // Pasamos los datos del formulario con el mismo id
                        /> */}
                    </Grid>

                    {/* Solo mostramos el Divider si el formulario está abierto */}
                    {idHiloTicket && <Divider orientation='vertical' flexItem />}


                    <Grid size={{ xs: 12, md: 9 }} sx={{
                        display: idHiloTicket ? 'block' : 'none'
                    }}
                    >
                        <DetalleTickets
                            datosHilo={hiloTicket}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default CreacionTickets