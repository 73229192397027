import React, { useRef, useEffect } from 'react';
import { Box, Button, IconButton, TextField, Typography, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CommentIcon from '@mui/icons-material/Comment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
const DetalleTickets = ({ datosHilo }) => {
    const messagesEndRef = useRef(null);
    const formateador = new Intl.DateTimeFormat("es-ES", {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    });

    const formatDate = (fecha) => {
        if (!fecha) return 'Fecha no disponible';
        const date = new Date(fecha);
        return isNaN(date) ? 'Fecha no válida' : formateador.format(date);
    };

    // Scroll al final de la conversación
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    }, [datosHilo]);

    if (!datosHilo || datosHilo.length === 0) {
        return <Typography>No hay detalles disponibles para este ticket.</Typography>;
    }


    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", padding: 2 }}>
            {/* Header */}
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
                <Typography variant="h6">
                    <CommentIcon sx={{ width: "18px", marginRight: 1 }} />
                    Detalles del ticket
                </Typography>
                <Typography>Usuario: {datosHilo?.usuario}</Typography>
            </Box>

            {/* Contenedor de Mensajes */}
            <Box
                sx={{
                    flexGrow: 1,
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column-reverse",
                    // gap: 2,
                    padding: 2,
                    // border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    // backgroundColor: "#fbfbfb",
                }}
            >
                {/* Mensaje Principal */}
                <Box sx={{ display: "flex", gap: 2 }}>
                    <AccountCircleIcon color="textSecondary" />
                    <Box>
                        <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }} color="textSecondary">
                            <CalendarTodayIcon sx={{ width: "18px", marginRight: 1 }} />
                            Creado: {formatDate(datosHilo?.fecha)}
                        </Typography>
                        <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
                            {datosHilo.descripcion}
                        </Typography>
                    </Box>
                </Box>

                {/* Mensajes adicionales */}
                {datosHilo.messages?.map((msg, index) => (
                    <Box key={index} sx={{ display: "flex", gap: 2 }}>
                        <AccountCircleIcon color="textSecondary" />
                        <Box>
                            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                {msg.usuario}
                            </Typography>
                            <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
                                {msg.texto}
                            </Typography>
                            <Typography sx={{ fontSize: "0.8rem", color: "gray" }}>
                                {formatDate(msg.fecha)}
                            </Typography>
                        </Box>
                    </Box>
                ))}

                {/* Elemento invisible para el scroll */}
                <div ref={messagesEndRef} />
            </Box>

            <Divider />

            <Box sx={{
                display: "flex",
                alignItems: "center",
                gap: 1, paddingTop: 1,
                paddingBottom: 1, flexDirection: { xs: "column", sm: "row" }, // Responsivo
            }}>
                <IconButton
                    sx={{
                        borderRadius: "8px", // Hace el botón circular
                        borderWidth: 0.1,
                        borderColor: "#c4c4c4",
                        borderStyle: "solid",
                    }}
                >
                    <AttachFileIcon />
                </IconButton>

                <TextField id="outlined-basic" variant="outlined"
                    fullWidth size='small'
                    sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                    }}
                    placeholder='Escribe tu mensaje' />

                <Button variant='contained'
                    sx={{
                        borderRadius: "8px",
                        borderColor: "#e4e4e7",
                        borderWidth: "2px",
                        // marginTop: 0.5,
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                            borderColor: "#e4e4e7",
                            backgroundColor: "#115293",
                            boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)',
                        }
                    }}
                    startIcon={
                        // <SendIcon sx={{ rotate: "10%" }} />
                        <SendOutlinedIcon
                            sx={{
                                marginLeft: 1,
                                marginTop: -0.5,
                                transform: "rotate(-40deg)", // Rotación del ícono
                                transition: "transform 0.3s ease", // Transición suave para rotación
                            }}
                        />
                    }
                >
                    Enviar
                </Button>
            </Box>
        </Box>
    );
};

export default DetalleTickets;
