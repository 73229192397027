export const ROUTES = {
  dashboard: '/Farmanet',
  gerenciaVisual: '/Farmanet/GerenciaVisual',
  calidad: '/Farmanet/Calidad',
  gestionComercial: '/Farmanet/GestionComercial',
  importaciones: '/Farmanet/Importaciones',
  financiera: '/Farmanet/Financiera',
  tecnica: '/Farmanet/Tecnica',
  firmaDigital: '/Farmanet/FirmaDigital',
  creacionTickets: '/Farmanet/CreacionTickets'
};