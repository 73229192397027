import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import InfoIcon from '@mui/icons-material/Info';

import IconButton from '@mui/material/IconButton';


const TablaActivo = ({ data }) => {

    const formatCurrency = useCallback((value) => {
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
        }).format(value);
    }, []);

    const calculateTotal = (rows, prefixes) => {
        return rows
            .filter(row => prefixes.some(prefix => row.puccod.startsWith(prefix)))
            .reduce((sum, row) => sum + row.Total, 0);
    };

    // Calcular el total global
    const calculateGrandTotal = (rows) => {
        return rows.reduce((sum, row) => sum + row.Total, 0);
    };

    // Calcular totales
    // const totalIngresos = useMemo(() => Math.abs(calculateTotal(data, "11")), [data]);
    // Calcula la suma de los prefijos "11", "13" y "14"
    const totalPrefijos = useMemo(() => calculateTotal(data, ["11", "13", "14"]), [data]);
    const totalActivoNoCorriente = useMemo(() => calculateTotal(data, ["15", "16", "17"]), [data]);
    const totalActivo = useMemo(() => calculateTotal(data, ["11", "13", "14", "15", "16", "17"]), [data]);

    // const totaldata = useMemo(() => calculateTotal(data, ["15",]), [data]);
    // console.log("totaldata totaldata", totaldata)

    const renderSection = (rows, label, prefix) => {
        let total = 0;
        const sectionRows = rows.filter(row => row.puccod.startsWith(prefix));

        const rowElements = sectionRows.map((row) => {
            total += row.Total;
            return (
                <TableRow key={row.puccod} sx={{ border: 'none' }}>
                    <TableCell align='left' sx={{ border: 'none' }}> {row.puccod}</TableCell>
                    <TableCell align="left" sx={{ border: 'none' }} >
                        <Typography noWrap width={"250px"} title={row.pucNom}>
                            {row.pucNom}
                        </Typography>
                    </TableCell>
                    <TableCell sx={{ border: 'none' }} align="right">{formatCurrency(row.Total)}</TableCell>
                </TableRow >
            );
        });

        return (
            <>
                <TableRow sx={{ border: 'none' }}>
                    <TableCell align="left" colSpan={2} sx={{ border: 'none', fontWeight: 'bold', backgroundColor: '#F5F5F5' }}>
                        {label}
                    </TableCell>
                    <TableCell align="right" colSpan={1} sx={{ border: 'none', fontWeight: 'bold', backgroundColor: '#F5F5F5' }}>
                        {formatCurrency(total)}
                    </TableCell>
                </TableRow>
                {rowElements}
            </>
        );
    };

    return (
        // <TableContainer >
        <div>
            <Typography variant="h6" style={{
                fontWeight: "bold",
                marginBottom: "0.5em", fontSize: "18px"
            }}>
                Activos
            </Typography>
            <Table stickyHeader sx={{}} size="small">
                <TableHead>
                    <TableRow >
                        <TableCell sx={{ fontWeight: "bold", color: "#71717a" }} >Cuenta</TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "#71717a" }}>Descripción</TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "#71717a" }} align='right' >Valor</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {renderSection(data, "Disponible", "11")}
                    {renderSection(data, "Inversiones", "12")}
                    {renderSection(data, "Deudores", "13")}
                    {renderSection(data, "Inventarios", "14")}

                    <TableRow>
                        <TableCell align="left" colSpan={2} sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#5AB2FF', height: "5px", position: "relative"
                        }}>
                            <div style={{ display: "inline-block", position: "relative", paddingRight: "40px" }}>
                                Activo corriente
                                <Tooltip title={`Disponibles + Deudores + inventarios`}>
                                    <IconButton
                                        sx={{
                                            position: "absolute",
                                            right: 0, // Ajustar al borde derecho
                                            top: "50%", // Centrado verticalmente
                                            transform: "translateY(-50%)"
                                        }}
                                    >
                                        <InfoIcon sx={{ width: "20px", height: "20px", color: "gray" }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </TableCell>
                        <TableCell align="right" sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#5AB2FF'
                        }}>
                            {formatCurrency(totalPrefijos)}
                        </TableCell>
                    </TableRow>

                    {renderSection(data, "Propiedad planta y equipo", "15")}
                    {renderSection(data, "Intangible", "16")}
                    {renderSection(data, "Diferidos", "17")}

                    <TableRow>
                        <TableCell align="left" colSpan={2} sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#5AB2FF', height: "5px", position: "relative"
                        }}>
                            <div style={{ display: "inline-block", position: "relative", paddingRight: "40px" }}>
                                Activo no corriente
                                <Tooltip title={`Disponibles + Deudores + inventarios`}>
                                    <IconButton
                                        sx={{
                                            position: "absolute",
                                            right: 0, // Ajustar al borde derecho
                                            top: "50%", // Centrado verticalmente
                                            transform: "translateY(-50%)"
                                        }}
                                    >
                                        <InfoIcon sx={{ width: "20px", height: "20px", color: "gray" }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </TableCell>
                        <TableCell align="right" sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#5AB2FF'
                        }}>
                            {formatCurrency(totalActivoNoCorriente)}
                        </TableCell>
                    </TableRow>

                </TableBody>
                
                <TableFooter>
                    <TableRow>
                        <TableCell
                            colSpan={3}
                            sx={{
                                position: 'sticky',
                                bottom: 0,
                                height: 5,
                                backgroundColor: '#B7B7B7',
                                // zIndex: 1,
                                // boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
                                fontWeight: "bold"
                            }}
                        >
                            <Typography variant='h6' sx={{ textAlign: 'left', fontSize: 16, fontWeight: "bold", color: "black" }}>
                                Total activos: {formatCurrency(totalActivo)}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </div>
    )
}

export default TablaActivo;