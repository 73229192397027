import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    Button, ListItemText, Typography, Box, Menu, MenuItem, TextField, Checkbox,
    FormControlLabel, FormGroup, Stack, Alert, Paper, MenuList, ListItemIcon
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import uno from "../../images/siglasFarma.png";
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { AuthContext } from '../../loginContext';
import MenuIcon from '@mui/icons-material/Menu';
import 'react-toastify/dist/ReactToastify.css';
import "./navbarInicio.css";
import { toast } from 'react-toastify';

import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

function ElevationScroll({ children, window }) {
    const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0, target: window ? window() : undefined });
    return React.cloneElement(children, { elevation: trigger ? 4 : 0 });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const NavbarInicio = () => {
    const { isAuthenticated, currentUser, logout, login } = useContext(AuthContext);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const menuRef = useRef(null);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [datax, setInputs] = useState({ username: "", password: "" });
    const [err, setErr] = useState(null);
    const [isAuthInitialized, setAuthInitialized] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const menuItems = useMemo(() => [
        { text: 'Inicio', path: '/' },
        { text: '¿Quienes somos?', path: '/QuienesSomos' },
        { text: 'Portafolio', path: '/Portafolio' },
        { text: 'Contactenos', path: '/Contacto' },
        { text: 'Paga tus facturas', path: '/PagosFactura' },
    ], []);

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const cerrarSesion = useCallback(async () => {
        try {
            logout();
            navigate("/");
        } catch (error) {
            console.error("Error al cerrar sesión:", error.response ? error.response.data : error.message);
        }
    }, [logout, navigate]);

    const handleChange = (e) => setInputs(prev => ({ ...prev, [e.target.name]: e.target.value }));

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await login(datax);
            setAuthInitialized(true);
            navigate("/Plataformas");
            toast.info("Elija la plataforma", {
                width: "350px",
                position: "bottom-left",
                autoClose: 3000,
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
            });
        } catch (err) {
            setErr(err.response?.data?.message || "Error de inicio de sesión");
        }
    };

    useEffect(() => {
        if (isAuthenticated && isAuthInitialized) navigate("/Plataformas");
    }, [isAuthenticated, isAuthInitialized, navigate]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <header className="ud-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="navbar navbar-expand-lg" ref={menuRef}>
                                <a class="navbar-brand" href="index.html">
                                    <img src={uno} width="150px" style={{ marginTop: "15px" }} alt="Farma Comercial" />
                                </a>
                                <MenuIcon className="navbar-toggler" onClick={() => setIsMenuOpen(!isMenuOpen)} style={{ fontSize: "30px" }} />
                                <div className={`navbar-collapse ${isMenuOpen ? 'show' : ''}`}>
                                    <ul id="nav" className="navbar-nav mx-auto">
                                        {menuItems.map((item, index) => (
                                            <li className="nav-item" key={index}>
                                                {item.text === 'Paga tus facturas' ? (
                                                    <a
                                                        href={item.path}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="ud-menu-scroll"
                                                    >
                                                        {item.text}
                                                    </a>
                                                ) : (
                                                    <Link
                                                        className="ud-menu-scroll"
                                                        to={item.path}
                                                        component={Link}
                                                        underline="none"
                                                        color="inherit"
                                                    >
                                                        {item.text}
                                                    </Link>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="navbar-btn d-none d-sm-inline-block" style={{ marginTop: "0.5em" }}>
                                    {isAuthenticated ? (
                                        <>
                                            <Button
                                                aria-label="more"
                                                aria-controls={open ? 'basic-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                sx={{ color: "black" }}
                                                onClick={handleClick}
                                            >

                                                <Typography sx={{ display: "flex", alignItems: "center", gap: 1, color: "black" }}>
                                                    {currentUser.userData?.UsuCod}
                                                    <PersonIcon />
                                                </Typography>
                                            </Button>

                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}

                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                            >

                                                <Paper elevation={0} sx={{ width: 250, maxWidth: '100%', height: "100%", }}>
                                                    <MenuList sx={{ padding: 1 }}>

                                                        <MenuItem component={Link} to="/Plataformas">
                                                            <ListItemIcon>
                                                                <HomeWorkIcon fontSize="small" />
                                                            </ListItemIcon>
                                                            <ListItemText>Plataformas</ListItemText>
                                                        </MenuItem>

                                                        <MenuItem onClick={cerrarSesion}>
                                                            <ListItemIcon>
                                                                <LogoutIcon fontSize="small" />
                                                            </ListItemIcon>
                                                            Cerrar sesión
                                                        </MenuItem>
                                                    </MenuList>
                                                </Paper>
                                            </Menu>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                id="basic-button"
                                                aria-controls={open ? 'basic-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                onClick={handleClick}
                                                sx={{ color: "black" }}

                                            >
                                                <Typography variant='body1'  >
                                                    Iniciar Sesión
                                                </Typography>
                                            </Button>

                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                slotProps={{
                                                    paper: {
                                                        elevation: 0,
                                                        sx: {
                                                            width: "300px",
                                                            borderRadius: "8px",
                                                            overflow: 'visible',
                                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                            mt: 1.5,
                                                            '&::before': {
                                                                content: '""',
                                                                display: 'block',
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 14,
                                                                width: 10,
                                                                height: 10,
                                                                bgcolor: 'background.paper',
                                                                transform: 'translateY(-50%) rotate(45deg)',
                                                                zIndex: 0,
                                                            },
                                                        },
                                                    },
                                                }}
                                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                            >
                                                <Box sx={{ display: "grid", gap: "1em", padding: "1em", borderRadius: "14px" }}>
                                                    <TextField
                                                        size="small"
                                                        onChange={handleChange}
                                                        name="usuario"
                                                        label="Usuario"
                                                        type="text"
                                                        autoComplete="off"
                                                    />
                                                    <TextField
                                                        size="small"
                                                        label="Contraseña"
                                                        onChange={handleChange}
                                                        name="clave"
                                                        type={showPassword ? 'text' : 'password'}
                                                    />
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={<Checkbox onClick={() => setShowPassword(!showPassword)} />}
                                                            label="Mostrar contraseña"
                                                        />
                                                    </FormGroup>

                                                    {err && (
                                                        <Stack sx={{ width: '100%' }} >
                                                            <Alert severity="warning">{err}</Alert>
                                                        </Stack>
                                                    )}

                                                    <Button variant="contained" fullWidth onClick={handleLogin}>
                                                        Iniciar Sesión
                                                    </Button>
                                                </Box>
                                            </Menu>


                                        </>
                                    )}
                                </div>
                            </nav>
                        </div>
                    </div>
                </div >
            </header >
        </>
    );
};
export default NavbarInicio;