import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField, Button, Typography, Container, Paper, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import uno from "./images/logo-farmacomercial.png"
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import { AuthContext } from './loginContext';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const Login = () => {

    const { isAuthenticated, login } = useContext(AuthContext);

    const [datax, setInputs] = useState({
        username: "",
        password: "",
    });

    const [err, setErr] = useState(null);
    const [isAuthInitialized, setAuthInitialized] = useState(false);

    // Muestra la contraseña
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const navigate = useNavigate()

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await login(datax);
            setAuthInitialized(true); // Marcar la autenticación como inicializada después del login
            navigate("/Farmanet")
            toast.info("Bienvenido a FarmaNet", {
                position: "bottom-left",
                autoClose: 5000,
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } catch (err) {
            setErr(err.response.data.message);
        }
    };

    useEffect(() => {
        // Si el usuario está autenticado y la autenticación ya se ha inicializado, redirigir a otra ruta (por ejemplo, "/")
        if (isAuthenticated && isAuthInitialized) {
            navigate("/Farmanet");
        }
    }, [isAuthenticated, isAuthInitialized, navigate]);

    return (
        <Box sx={{
            display: "flex", justifyContent: "center",
            alignItems: "center", height: "100vh", // Ocupa toda la altura del viewport
            backgroundColor: "#f5f5f5", // Fondo para visualización
        }} >
            <Paper variant="outlined" sx={{
                padding: 3,
                borderRadius: 3, height: "65vh", // Altura del contenedor
                position: "relative", // Necesario para posicionar hijos absolutos
            }} >
                <Container component="main" maxWidth="sm" sx={{ width: 400,  }}>

                    <Box textAlign="center">
                        <img src={uno} alt=""
                            style={{
                                width: 400, maxWidth: "100%",
                                maxHeight: "100%", objectFit: "cover",
                                marginTop: 5,
                            }}
                        />

                        <Box sx={{ mt: 5, width: "100%", maxWidth: 400, mx: "auto" }}>
                            <Typography variant="h6" sx={{ textAlign: "left", marginBottom: 2 }}>Inicio de sesión</Typography>

                            <form>
                                <FormControl fullWidth sx={{ mb: 2 }} variant="outlined">
                                    <TextField id="usuario" name="usuario"
                                        size="small" label="Usuario" placeholder="Usuario"
                                        onChange={handleChange} fullWidth
                                        variant="outlined" sx={{
                                            backgroundColor: "white",
                                            borderRadius: "8px",
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "8px",
                                            },
                                        }}
                                        autoComplete="off"
                                    />
                                </FormControl>

                                <FormControl fullWidth sx={{ mb: 1 }} variant="outlined">
                                    <TextField id="clave" name="clave" size="small"
                                        label="Ingrese su contraseña" placeholder="Ingrese su contraseña"
                                        type={showPassword ? "text" : "password"}
                                        onChange={handleChange} fullWidth
                                        variant="outlined" sx={{
                                            backgroundColor: "white",
                                            borderRadius: "8px",
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "8px",
                                            },
                                        }}
                                        autoComplete="off"
                                    />
                                </FormControl>

                                <FormGroup>
                                    <FormControlLabel control={<Checkbox
                                        onClick={handleClickShowPassword}
                                    />} label="Mostrar contraseña" />
                                </FormGroup>

                                {err && (
                                    <Alert severity="warning">{err}</Alert>
                                )}
                            </form>
                        </Box>
                    </Box>

                    {/* Botón posicionado al fondo */}
                    <Box
                        sx={{
                            position: "absolute", bottom: 16,
                            left: "50%", transform: "translateX(-50%)",
                            width: "100%", maxWidth: 400,
                        }}
                    >
                        <Button onClick={handleLogin} variant="contained"
                            sx={{
                                width: '100%', borderRadius: "8px",
                                borderColor: "#2A3335", background: "#2A3335",
                                borderWidth: "2px", marginBottom: 2,
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                '&:hover': {
                                    borderColor: "#2A3335",
                                    boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)',
                                }
                            }}
                            size="medium"
                        >
                            Iniciar sesión
                        </Button>
                    </Box>
                </Container>
            </Paper>
        </Box>
    )
}

export default Login;