import React, { useEffect, useState } from 'react'
import {
    Box, Breadcrumbs, Paper, Typography,
    Fab, Snackbar, Alert,
    MenuItem,
    Select,
    FormControl,
    InputLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import newRequest from '../../../utils/newRequest';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ImportExportIcon from '@mui/icons-material/ImportExport';
import PorActivo from './PorActivo/PorActivo';
import PorPasivo from './porPasivo/PorPasivo';
import PorPatrimonio from './porPatrimonio/PorPatrimonio';
// import PorActivoPasivo from './PorActivoPasivo/PorActivoPasivo';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Grid from '@mui/material/Grid2';

const currentYear = new Date().getFullYear(); // Obtiene el año actual
const currentMonth = new Date().getMonth() + 1; // Obtén el mes actual (0-11)

const meses = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre",
];

function FinancieraActPas() {

    const navigate = useNavigate();

    const [añoSelect, setAñoSelect] = useState([]);
    const [selectAñoIncial, setAñoInical] = useState(currentYear);
    const [selectedMesInicial, setSelectedMesInicial] = useState(currentMonth);
    const [open, setOpen] = useState(false);
    const [messageInfo, setMessageInfo] = useState({ message: '', severity: 'info' });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleOpenSnackbar = (message, severity) => {
        setMessageInfo({ message, severity });
        setOpen(true);
    };

    const añoInicial = (year) => {
        if (year <= currentYear) {
            setAñoInical(year);
            setSelectedMesInicial(1);
        } else {
            toast.warning(`La selección no es válida`, {
                position: "bottom-left",
                autoClose: 4000,
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
            });
        }
    };

    const handleMesInicial = (event) => {
        const month = event; // Extraer el mes seleccionado
        if (selectAñoIncial === currentYear && month > currentMonth) {
            handleOpenSnackbar("El mes seleccionado supera al mes actual", "warning");
        } else {
            setSelectedMesInicial(month);
        }
    };

    const fetchData = async () => {
        try {
            const res = await newRequest.get('/api/GerenciaVisual/profundidadSelectAno', {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache'
                }
            });
            setAñoSelect(res.data.reverse());
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={messageInfo.severity}
                    sx={{
                        width: '100%', background: "#222831", color: "white"
                    }}
                >
                    {messageInfo.message}
                </Alert>
            </Snackbar>

            <Grid container >
                <Grid xs={12} sm={6} md={12}>
                    <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center", marginBottom: "1em" }}>
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                            <Fab onClick={() => navigate(-1)} size='small'
                                style={{ background: "white", borderRadius: 13 }}>
                                <ChevronLeftIcon />
                            </Fab>

                            <Breadcrumbs aria-label="breadcrumb" style={{ paddingLeft: "15px" }}>
                                <Typography color="text.primary">Financiera</Typography>
                                <Typography color="text.primary">Balance de prueba</Typography>
                            </Breadcrumbs>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid size={12}>
                    <Grid container spacing={1} alignItems="center" sx={{ marginTop: 1 }}>
                        <Grid item xs={2}>
                            <FormControl style={{ width: "200px", background: "white", borderRadius: "8px" }} size="small">
                                <InputLabel id="demo-simple-select-label">Año</InputLabel>
                                <Select
                                    labelId="select-año-label"
                                    id="select-año"
                                    label="Año"
                                    value={selectAñoIncial}
                                    onChange={(event) => añoInicial(event.target.value)}
                                    style={{ borderRadius: "8px" }}
                                >
                                    {añoSelect.map((año) => (
                                        <MenuItem value={año.PerMesano} key={año.PerMesano}>
                                            {año.PerMesano}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Meses */}
                        <Grid item xs={10}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sx={{ display: "flex", gap: 1 }}>
                                    <FormControl style={{ width: "300px", background: "white", borderRadius: "8px" }} size="small">
                                        <InputLabel>Mes</InputLabel>
                                        <Select
                                            label="Mes"
                                            value={selectedMesInicial}
                                            onChange={(e) => handleMesInicial(e.target.value)}
                                            startAdornment={
                                                <CalendarMonthIcon style={{ width: "20px", height: "20px", marginRight: "5px" }} />
                                            }
                                        >
                                            {meses.map((item, index) => (
                                                <MenuItem value={index + 1} key={index}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Box sx={{ marginTop: "1em" }}>
                        <Grid container spacing={2}>
                            {/* Activos */}
                            <Grid size={6}>
                                <Paper variant="outlined" sx={{ p: 1, borderRadius: "8px", marginTop: 1 }}>
                                    <div style={{ maxHeight: 500, overflow: "auto", paddingRight: "1rem" }}>
                                        <PorActivo
                                            selectedMesInicial={selectedMesInicial} selectAñoIncial={selectAñoIncial}
                                        />
                                    </div>
                                </Paper>
                            </Grid>

                            {/* Pasivos */}
                            <Grid size={6}>
                                <Paper variant="outlined" sx={{ p: 1, borderRadius: "8px", marginTop: 1 }}>
                                    <div style={{ maxHeight: 500, overflow: "auto", paddingRight: "1rem" }}>
                                        <PorPasivo selectedMesInicial={selectedMesInicial} selectAñoIncial={selectAñoIncial} />
                                    </div>
                                </Paper>
                            </Grid>

                            {/* Patrimonio */}
                            <Grid size={12}>
                                <Paper variant="outlined" sx={{ p: 1, borderRadius: "8px", marginTop: 1 }}>
                                    <div style={{ maxHeight: 500, overflow: "auto", paddingRight: "1rem" }}>
                                        <PorPatrimonio selectedMesInicial={selectedMesInicial} selectAñoIncial={selectAñoIncial} />
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>


        </div >
    )
}

export default FinancieraActPas