import React from 'react'
import Grid from '@mui/material/Grid2';
import { Button, Checkbox, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArticleIcon from '@mui/icons-material/Article';

const Filters = ({ referencia, handleSearchChange,
    fechaInicio, handleFechaInicioChange, fechaFin, handleFechaFinChange,
    estado, handleEstadoChange, selectedRows, exportToExcel, checked, handleChange }) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid size={3}>
                    <TextField
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                            },
                        }} autoComplete="off"

                        fullWidth
                        size="small"
                        label="Número de referencia"
                        variant="outlined"
                        value={referencia} // Asocia el estado
                        onChange={handleSearchChange} // Actualiza el estado
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Grid size={2}>
                    <TextField
                        helperText="Rango inicial"
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                            "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                        }}
                        fullWidth
                        size="small"
                        type="date"
                        value={fechaInicio}
                        onChange={handleFechaInicioChange} // Usa el manejador con validación
                    />
                </Grid>

                <Grid size={2}>
                    <TextField
                        helperText="Rango Final"
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                            "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                        }}
                        fullWidth
                        size="small"
                        type="date"
                        value={fechaFin}
                        onChange={handleFechaFinChange} // Usa el manejador con validación
                    />
                </Grid>

                <Grid size={2}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-select-small-label">Estado</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={estado}
                            label="Estado"
                            onChange={handleEstadoChange} // Llama a la función cuando cambie el valor
                            sx={{
                                backgroundColor: "white",
                                borderRadius: "8px",
                                "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                            }}
                        >
                            <MenuItem value="">Ninguno</MenuItem>
                            <MenuItem value="APPROVED">Pagado</MenuItem>
                            <MenuItem value="DECLINED">Declinada</MenuItem>
                            <MenuItem value="ERROR">Error</MenuItem>
                        </Select>
                    </FormControl>

                </Grid>

                {selectedRows?.length ?
                    <Grid size={"auto"}>
                        {/* sx={{ background: "#118B50" }} */}
                        <Button fullWidth variant='contained'
                            onClick={exportToExcel}
                            startIcon={<ArticleIcon />}
                            sx={{
                                backgroundColor: "#118B50",
                                borderRadius: "8px",
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px",
                                },
                            }}>
                            Descargar excel
                        </Button>
                    </Grid>
                    :
                    ""
                }

                <Grid size={"grow"}>
                    <FormControlLabel control={
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                        />
                    } label="Cruzado" labelPlacement="end" />
                </Grid>
            </Grid>
        </>
    )
}

export default Filters