import { Box, Typography } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import React, { useContext, useMemo } from 'react'
import { AuthContext } from '../../../../loginContext';

export const TablaTopVendedores = ({ datVendedorRanking, formatNumber }) => {

    const { currentUser } = useContext(AuthContext);
    const columns = useMemo(() => [
        {
            accessorKey: 'vendedor', header: 'vendedor',
            Cell: ({ cell }) => {
                const vendedor = cell.row.original.vendedor;
                // console.log("currentUser", currentUser.userData?.UsuNom)
                return (
                    <Typography title={vendedor} variant="body2" style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap", overflow: "hidden",
                    }}>
                        {vendedor}
                    </Typography>
                    // </div>
                );
            },
        },
        {
            accessorKey: 'Ventas2024', header: 'Ventas2024',
            Cell: ({ cell }) => {
                const Ventas2024 = cell.row.original.Ventas2024;
                return (
                    <Typography title={Ventas2024} variant="body2" style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap", overflow: "hidden",
                    }}>
                        ${formatNumber(Ventas2024)}
                    </Typography>
                );
            },

        },
        { accessorKey: 'zona', header: 'zona' },
        {
            accessorKey: 'Presupuesto2024', header: 'Presupuesto2024',
            Cell: ({ cell }) => {
                const Presupuesto2024 = cell.row.original.Presupuesto2024;
                return (
                    <Typography title={Presupuesto2024} variant="body2" style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap", overflow: "hidden",
                    }}>
                        ${formatNumber(Presupuesto2024)}
                    </Typography>
                );
            },
        },
        { accessorKey: 'Periodo', header: 'Periodo' },
    ], [formatNumber]);

    const table = useMaterialReactTable({
        columns,
        data: datVendedorRanking,
        enableRowNumbers: true,
        initialState: {
            // density: 'compact'
        },


        enableColumnActions: false,
        enableColumnFilters: false,
        enablePagination: false,
        enableSorting: false,
        enableRowSelection: false,

        enableStickyHeader: true,
        enableStickyFooter: true,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableFilters: false,
        enableHiding: false,
        enableBottomToolbar: false, //Oculta el pie de la pagina
        muiTablePaperProps: {
            elevation: 0,
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography variant='h6' fontSize={16} fontWeight="bold"># Ranking vendedor</Typography>
                </div>
            </Box>
        ),
        muiTableBodyRowProps: ({ row }) => {
            const isCurrentUser = row.original.vendedor.trim() === currentUser.userData?.UsuNom.trim();
            return {
                hover: false,
                sx: {
                    backgroundColor: isCurrentUser ? "#33d77426" : "transparent",
                    borderRadius: isCurrentUser ? "12px" : "0",
                },
            };
        },
        muiTableContainerProps: { sx: { height: "19.5rem", width: "100%" } }
    });

    return (
        <div>
            <MaterialReactTable table={table} />
        </div>
    )
}
