import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    Box, Breadcrumbs, Typography,
    FormControl, InputLabel,
    Select, MenuItem, Fab, LinearProgress,
    Button
    // Skeleton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VistaEmpresa from './VistaEmpresa/VistaEmpresa';
import newRequest from '../../../utils/newRequest';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Grid from '@mui/material/Grid2';

const currentYear = new Date().getFullYear(); // Obtiene el año actual
const currentMonth = new Date().getMonth() + 1; // Obtén el mes actual (0-12)

const Indicadores = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // Estado de loading
    const [añoSelect, setAñoSelect] = useState([]);
    const [selectAñoIncial, setAñoInical] = useState(currentYear);
    const [indicadores, setIndicadores] = useState([]);
    const [mes, setMes] = useState(currentMonth);
    const [alignment, setAlignment] = useState('ESTRA');

    const meses = useMemo(() => [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre",
    ], []);

    const handleChange = (event) => setMes(event.target.value);

    const añoInicial = useCallback((year) => {
        if (year <= currentYear) setAñoInical(year);
        else console.log("Error en el añoIncial", añoInicial);
    }, []);

    const DataAct = useMemo(() => ({
        proceso: alignment,
        año: selectAñoIncial
    }), [alignment, selectAñoIncial]);

    const indiC = useCallback(async () => {
        setLoading(true); // Indica que comienza la carga
        try {
            const res = await newRequest.post('/api/GerenciaVisual/Indicadores/MostrarIndicadores', DataAct, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            setIndicadores(res.data.length ? res.data : []);
        } catch (error) {
            console.log("error 🦋", error);
        } finally {
            setLoading(false); // Indica que termina la carga
        }
    }, [DataAct]);

    const fetchData = useCallback(async () => {
        setLoading(true); // Indica que comienza la carga
        try {
            const res = await newRequest.get('/api/GerenciaVisual/profundidadSelectAno');
            setAñoSelect(res.data.reverse());
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Indica que termina la carga
        }
    }, []);

    useEffect(() => {
        fetchData();
        indiC();
    }, [fetchData, indiC]);

    const handleBack = () => navigate("/Farmanet/GerenciaVisual");

    return (
        <Box >
            <Grid item xs={12} sm={6} md={12} >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between", // Espacio entre los elementos
                        alignItems: "center", // Alinea verticalmente los elementos en el centro
                        marginBottom: "2em",
                    }}
                >
                    {/* Sección izquierda: Botón y breadcrumbs */}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Fab size='small'
                            onClick={handleBack}
                            style={{
                                background: "white",
                                borderRadius: 13,
                            }}
                        >
                            <ChevronLeftIcon />
                        </Fab>
                        <Breadcrumbs aria-label="breadcrumb" sx={{ marginLeft: "15px" }}>
                            <Typography color="text.primary">Gerencia visual</Typography>
                            <Typography color="text.primary">Indicadores</Typography>
                        </Breadcrumbs>
                    </Box>

                </Box>
            </Grid>

            {loading ? (
                <LinearProgress />
            ) : (
                <Grid container spacing={2}>
                    <Grid size={12}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                            <Grid container spacing={2} sx={{ flex: 1 }}>
                                <Grid size={{ xs: 12, md: 6 }}>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                        <FormControl
                                            sx={{
                                                width: { xs: "100%", md: "200px", borderRadius: "8px", }, // 100% para xs y 200px para md y superior
                                                background: "white",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#e0e0e0", // Borde verde
                                                        borderRadius: "8px",
                                                    },
                                                },
                                            }}
                                            size="small">
                                            <InputLabel>Año</InputLabel>
                                            <Select
                                                sx={{
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        // borderRadius: "8px", // Aplica el mismo borderRadius al Select
                                                    }
                                                }}
                                                label="Mes"
                                                value={selectAñoIncial}
                                                onChange={(event) => añoInicial(event.target.value)}
                                            >
                                                {añoSelect.map((año) => (
                                                    <MenuItem value={año.PerMesano} key={año.PerMesano}>
                                                        {año.PerMesano}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <FormControl
                                            sx={{
                                                width: { xs: "100%", md: "200px", borderRadius: "8px", }, // 100% para xs y 200px para md y superior
                                                background: "white",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#e0e0e0", // Borde verde
                                                        borderRadius: "8px",
                                                    },
                                                },
                                            }}
                                            size="small"
                                        >
                                            <InputLabel>Mes</InputLabel>
                                            <Select
                                                label="Mes"
                                                value={mes}
                                                onChange={handleChange}
                                                startAdornment={
                                                    <CalendarMonthIcon style={{ width: "20px", height: "20px", marginRight: "8px" }} />
                                                }
                                            >
                                                {meses.map((item, index) => (
                                                    <MenuItem value={index + 1} key={index}>
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid size={{ xs: 12, md: 6 }} >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: 1,
                                            justifyContent: "flex-end",
                                            marginTop: { xs: "1em", md: 0 } // Añade margen solo en pantallas pequeñas
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            onClick={() => setAlignment('ESTRA')}
                                            sx={{
                                                width: { xs: "100%", md: "200px", }, // 100% para xs y 200px para md y superior
                                                borderRadius: "8px",
                                                borderColor: "#e4e4e7",
                                                borderWidth: "2px",
                                                backgroundColor: alignment === 'ESTRA' ? "#1976d2" : "white",
                                                color: alignment === 'ESTRA' ? "white" : "black",
                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                '&:hover': {
                                                    borderColor: "#e4e4e7",
                                                    backgroundColor: alignment === 'ESTRA' ? "#115293" : "transparent",
                                                    boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)',
                                                }
                                            }}
                                        >
                                            Estrategicos
                                        </Button>

                                        <Button
                                            variant="outlined"
                                            onClick={() => setAlignment('OPERA')}
                                            sx={{
                                                width: { xs: "100%", md: "200px", }, // 100% para xs y 200px para md y superior
                                                borderRadius: "8px",
                                                borderColor: "#e4e4e7",
                                                borderWidth: "2px",
                                                backgroundColor: alignment === 'OPERA' ? "#1976d2" : "white",
                                                color: alignment === 'OPERA' ? "white" : "black",
                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                '&:hover': {
                                                    borderColor: "#e4e4e7",
                                                    backgroundColor: alignment === 'OPERA' ? "#115293" : "transparent",
                                                    boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)',
                                                }
                                            }}
                                        >
                                            Operativos
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid size={12}>
                        {/* <Grid container spacing={2} sx={{ background: "yellow" }}> */}
                        <VistaEmpresa indicadores={indicadores} alignment={alignment}
                            selectAñoIncial={selectAñoIncial} mes={mes} />
                        {/* </Grid> */}
                    </Grid>
                </Grid>
            )}


        </Box>
    )
}

export default Indicadores





