import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { Box, Breadcrumbs, Button, Checkbox, Fab, Paper, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import newRequest from '../../../utils/newRequest';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import ComputerIcon from '@mui/icons-material/Computer';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import logoBancolombia from "./img/bancolombiaLogo.png"
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Filters from './components/Filters';
import DetalleFactura from './components/DetalleFactura';
import usePagosWompi from './hooks/usePagosWompi';

const PagosWompi = () => {
    const navigate = useNavigate();

    // Obtiene el día de hoy y el día anterior
    const hoy = new Date();
    const diaAnterior = new Date();
    diaAnterior.setDate(hoy.getDate() - 1);
    const [datos, setDatos] = useState([])
    const {
        referencia,
        handleSearchChange,
        fechaInicio,
        handleFechaInicioChange,
        fechaFin,
        handleFechaFinChange,
        estado,
        handleEstadoChange,
        checked,
        handleChange,
        mostrarDetalles,
        datosReferencia,
        setDatosReferencia,
        setMostrarDetalles,
        loading,
        setLoading,
        error,
        setError
    } = usePagosWompi();

    // const [mostrarDetalles, setMostrarDetalles] = useState(false); // Estado para controlar la visibilidad de los detalles
    const [selectedRows, setSelectedRows] = useState([]);

    const obtenerTransferencia = useCallback(async () => {
        try {
            // Si alguna de las fechas está vacía, no aplicamos el filtro de fechas
            const params = {
                referencia,
                estado,
                checked,
                ...(fechaInicio && fechaFin ? { fechaInicio, fechaFin } : {}),
            };

            const res = await newRequest.get(`/api/Financiera/financieraRoutes/obtenerTransferencias`, {
                params,
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                },
            });
            setDatos(res.data);
        } catch (error) {
            console.error("Error al obtener transferencias:", error);
        }
    }, [fechaFin, fechaInicio, referencia, estado, checked]);

    // Función para obtener los datos de referencia de la factura seleccionada
    const obtenerReferencia = useCallback(async (referencia) => {
        setLoading(true);
        setError(null);
        try {
            const res = await newRequest.get(`/api/Financiera/financieraRoutes/obtenerReferencia`, {
                params: { referencia },
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                },
            });
            const data = res.data?.data?.[0] || null;
            setDatosReferencia(data);
            setMostrarDetalles(true); // Mostrar detalles al obtenerlos
        } catch (error) {
            console.error("Error al obtener referencia:", error);
            setError('No se pudo cargar la referencia.');
        } finally {
            setLoading(false);
        }
    }, []);

    const validarFechas = (inicio, fin) => {
        if (new Date(inicio) > new Date(fin)) {
            alert("La fecha inicial no puede ser mayor que la fecha final.");
            return false;
        }
        if (new Date(fin) < new Date(inicio)) {
            alert("La fecha final no puede ser menor que la fecha inicial.");
            return false;
        }
        return true;
    };

    // const handleFechaInicioChange = (e) => {
    //     const nuevaFechaInicio = e.target.value;
    //     // Si el valor es vacío, asignamos una fecha predeterminada o simplemente no hacemos nada.
    //     if (nuevaFechaInicio === "") {
    //         setFechaInicio(""); // O podrías poner un valor predeterminado como la fecha de hoy
    //     } else if (validarFechas(nuevaFechaInicio, fechaFin)) {
    //         setFechaInicio(nuevaFechaInicio); // Solo actualiza si la fecha es válida
    //     }
    // };

    // const handleFechaFinChange = (e) => {
    //     const nuevaFechaFin = e.target.value;
    //     // Si el valor es vacío, asignamos una fecha predeterminada o simplemente no hacemos nada.
    //     if (nuevaFechaFin === "") {
    //         setFechaFin(""); // O podrías poner un valor predeterminado como la fecha de hoy
    //     } else if (validarFechas(fechaInicio, nuevaFechaFin)) {
    //         setFechaFin(nuevaFechaFin); // Solo actualiza si la fecha es válida
    //     }
    // };

    // const handleSearchChange = useCallback((e) => {
    //     setReferencia(e.target.value); // Actualiza el estado con el valor del input
    // }, []);

    // const handleEstadoChange = (event) => {
    //     setEstado(event.target.value);
    // };

    useEffect(() => {
        obtenerTransferencia();
    }, [obtenerTransferencia, referencia, estado]); // Solo se ejecuta cuando cambia "obtenerTransferencia"

    const formatoFecha = useCallback((fechaISO) => {
        const fecha = new Date(fechaISO);
        return format(fecha, "d MMM, h:mm a", { locale: es });
    }, []);

    function formatoFechaCorta(fecha) {
        // Verifica si la fecha es válida
        if (!fecha || isNaN(new Date(fecha).getTime())) {
            return '';  // Retorna una cadena vacía si la fecha es inválida
        }

        const opciones = { day: 'numeric', month: 'short' }; // Día y mes
        const fechaAjustada = new Date(`${fecha}T12:00:00`); // Forzar hora para evitar errores de zona horaria
        return new Intl.DateTimeFormat('es-ES', opciones).format(fechaAjustada);
    }

    // Formato de moneda
    const formatoMoneda = useCallback((valor) => {
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0,
        }).format(valor);
    }, []);

    // Estilo del estado la transacción 
    const obtenerEstiloEstado = useCallback((estado) => {
        switch (estado) {
            case "APPROVED":
                return { backgroundColor: "#b0f2ae" };
            case "DECLINED":
                return { backgroundColor: "#f03232", color: "white" };
            case "ERROR":
                return { backgroundColor: "#ffdfdf" };
            default:
                return { backgroundColor: "gray" };
        }
    }, []);

    // const handleChange = useCallback((event) => {
    //     setChecked(event.target.checked);
    // }, []);

    const formatoEstado = (estado, cruce) => {
        if (cruce === null && estado === "APPROVED") {
            // return "Pagada - Cruzada";
            return <div>
                Pagada - <strong>Cruzada</strong>
            </div>;
        } else {
            switch (estado) {
                case "APPROVED":
                    return "Pagada";
                case "DECLINED":
                    return "Declinada";
                case "ERROR":
                    return "Error";
                default:
                    return estado; // Retorna el valor original si no coincide con ningún caso
            }
        }
    };

    // Estilo de la cabezera de la tabla
    const getCommonMuiTableHeadCellProps = (backgroundColor) => ({
        align: 'left',
        sx: {
            backgroundColor,
        },
    });

    const columns = [
        {
            accessorKey: 'A',
            header: '',
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#F5F5F7'),
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ cell }) => {
                const handleCheckboxChange = (event) => {
                    const isSelected = event.target.checked;
                    const rowId = cell.row.original.tbwpid; // El identificador único de la fila

                    setSelectedRows((prevSelectedRows) => {
                        if (isSelected) {
                            return [...prevSelectedRows, rowId]; // Añadir a la lista si se selecciona
                        } else {
                            return prevSelectedRows.filter(id => id !== rowId); // Eliminar de la lista si se deselecciona
                        }
                    });
                };

                const isChecked = selectedRows.includes(cell.row.original.tbwpid); // Verificar si esta fila está seleccionada
                const rowStatus = cell.row.original.tbwpesttran; // Obtener el estado de la fila
                // const isDisabled = rowStatus === 'DECLINED' || rowStatus === 'ERROR'; // Deshabilitar el checkbox si el estado es DECLINED o ERROR

                const isDisabled = rowStatus === 'DECLINED' || rowStatus === 'ERROR' || cell.row.original.cruce === null; // Deshabilitar el checkbox si el estado es DECLINED, ERROR o 'cruce' es null

                return (
                    <div>
                        <Checkbox
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            disabled={isDisabled} // Deshabilitar el checkbox si la fila está en DECLINED o ERROR

                        // disabled={cell.row.original.cruce === null} // Aquí se verifica si 'cruce' es null

                        />
                    </div>
                );
            },
            size: 30,
        },
        {
            accessorKey: 'Documento',
            header: 'Factura',
            size: 18,
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#F5F5F7'),
        },
        {
            accessorKey: 'tbwpesttran',
            header: 'Estado',
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#F5F5F7'),
            Cell: ({ cell }) => (
                <Box style={{
                    ...obtenerEstiloEstado(cell.row.original.tbwpesttran),
                    borderRadius: "4px",
                    textAlign: "center",
                    // width: "100px"
                }} >
                    {/* {formatoEstado(cell.row.original.tbwpesttran)} */}
                    {formatoEstado(cell.row.original.tbwpesttran, cell.row.original.cruce)}

                </Box>
            ),
            size: 30,
        },
        {
            accessorKey: 'tbwpvalor',
            header: 'valor individual',
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#F5F5F7'),
            Cell: ({ cell }) => `COP ${formatoMoneda(cell.row.original?.tbwpvalor[0])}`,
            size: 30,
        },
        {
            accessorKey: 'tbwprefval',
            header: 'Monto y cliente total',
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#F5F5F7'),
            Cell: ({ cell }) => (
                <Typography variant='body2' align='left'>
                    <Box display={'flex'} sx={{ gap: 2, alignItems: "center", marginRight: "20px" }}>
                        <img src={logoBancolombia} alt='' width={"18px"} />
                        <Typography variant='body2' align='left'>
                            COP {formatoMoneda(cell.row.original.tbwprefval)} <br />
                            {cell.row.original.tbwpnom}
                        </Typography>
                    </Box>
                </Typography>
            ),
            size: 180,
        },
        {
            accessorKey: 'tbwpidtrans',
            header: 'Datos del pago',
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#F5F5F7'),
            Cell: ({ cell }) => (
                <Box display={'flex'} sx={{ gap: 1, alignItems: "center", }}>
                    <ComputerIcon sx={{ width: "18px" }} />
                    <Typography title={cell.row.original.tbwpref} variant='body2' align='left' noWrap width={'300px'}>
                        # {cell.row.original.tbwpidtrans} <br />
                        <span style={{ textDecoration: "underline", marginRight: "4px" }}>
                            Ref:</span>{cell.row.original.tbwpref}
                    </Typography>
                </Box>
            ),
            size: 30,
        },
        {
            accessorKey: 'tbwpfecfin',
            header: 'Hora y fecha',
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#F5F5F7'),
            Cell: ({ cell }) => (
                <Box display={'flex'} sx={{ gap: 1, alignItems: "center", }}>
                    <AccessTimeIcon />
                    <Typography variant='body2'>
                        {formatoFecha(cell.row.original.tbwpfecfin)}
                    </Typography>
                </Box>
            ),
            // size: 30,
        },
        {
            accessorKey: 'vermas',
            header: 'Detalle de la factura',
            enableColumnActions: false,
            enableSorting: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: "#F5F5F7"
                },
            },
            muiTableBodyCellProps: {
                align: 'center'
            },
            Cell: ({ cell }) => (
                <Button variant="text" startIcon={
                    <InfoOutlinedIcon />
                }
                    onClick={() => obtenerReferencia(cell.row.original.tbwpref)}>
                    Ver mas
                </Button>
            ),
            // size: 30,
        },
        // {
        //     accessorKey: 'Cruzar',
        //     header: 'Cruzar',
        //     enableColumnActions: false,
        //     enableSorting: false,
        //     muiTableHeadCellProps: {
        //         align: 'center',
        //         sx: {
        //             backgroundColor: "#F5F5F7"
        //         },
        //     },
        //     Cell: ({ cell }) => (
        //         <Box display={'flex'} sx={{ gap: 1, alignItems: "center", }}>
        //             <Button variant="contained" size='small'
        //                 disabled={cell.row.original.cruce === null} // Aquí se verifica si 'cruce' es null
        //                 onClick={() => obtenerReferencia(cell.row.original.tbwpref)}
        //                 startIcon={
        //                     <SyncAltIcon />
        //                 }
        //                 sx={{
        //                     // backgroundColor: "#118B50",
        //                     borderRadius: "8px",
        //                     "& .MuiOutlinedInput-root": {
        //                         borderRadius: "8px",
        //                     },
        //                 }}
        //             >
        //                 Cruzar
        //             </Button>
        //         </Box>
        //     ),
        //     size: 30,
        // },
    ];

    console.log("datos", datos);

    // Estructura de la tabla de la transacción
    const table = useMaterialReactTable({
        columns,
        data: datos,
        muiTablePaperProps: {
            elevation: 0
        },
        enableStickyHeader: true,
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableStickyFooter: true,
        enableFilters: false,
        enableHiding: false,
        renderTopToolbarCustomActions: () => (
            <Box>
                <Typography variant='h6' fontSize={16} fontWeight="bold">
                    Transacciones entre ( {formatoFechaCorta(fechaInicio)} a {formatoFechaCorta(fechaFin)})
                </Typography>
            </Box>
        ),
        renderBottomToolbarCustomActions: () => (
            <Box>
                {selectedRows.length ? <Typography>Facturas seleccionadas: {selectedRows.length}</Typography> : ""}
            </Box>
        ),

        // enableRowSelection: true,

        localization: MRT_Localization_ES,
        initialState: { density: 'compact' },
        muiTableContainerProps: { sx: { height: "50vh", flexGrow: 1, } }
    });

    // Exportar Excel
    const exportToExcel = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const fileName = 'cruce_de_pagos';

        // Filtrar los datos seleccionados
        const selectedData = datos.filter((row) => selectedRows.includes(row.tbwpid));


        console.log("selectedData selectedData selectedData", selectedData);

        // Formatear los datos que se exportarán (puedes agregar o quitar columnas según sea necesario)
        const formattedData = selectedData.map((row) => ({
            // "ID": row.tbwpid,                // tbwpid
            "Numero Factura": row.Documento,   // tbwpfacsec
            "Abono": row.tbwprefval,            // tbwprefval
            "Descuento": 0,
            "RETEFUENT": 0,
            "DIF PA": 0,
            "RETICA": 0,
            "AJUS PESO": 0,
            "ESTAMP": 0,
            "RET IVA": 0,
            "SDO FAV": 0,
            "CRUCE SF": 0,
            "DEVRFTE": 0,
            "DEVRTICA": 0,
            "APROV": 0,
            "ESP FISIC": 0,
            "OXIGENO": 0,
            "REICA21": 0,
            "RET BCO": 0,
            "COM FACT": 0,
            "REFTE21": 0,
            "Nit": '',
            "Tipo": '',
            "Numero": '',
            // "Fecha": '0',
            "Fecha": '', // tbwpfecfin
        }));

        // Crear la hoja de trabajo de Excel con los datos seleccionados
        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

        // Generar el archivo Excel
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        saveAs(data, fileName + fileExtension);
    };

    return (
        <div>
            <Grid container spacing={2} >
                <Grid xs={12} sm={6} md={12}>
                    <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "1em" }}>
                            <Fab onClick={() => navigate(-1)} size='small'
                                style={{ background: "white", borderRadius: 13 }}>
                                <ChevronLeftIcon />
                            </Fab>
                            <Breadcrumbs aria-label="breadcrumb" style={{ paddingLeft: "15px" }}>
                                <Typography color="text.primary">Financiera</Typography>
                                <Typography color="text.primary">Pagos wompi</Typography>
                            </Breadcrumbs>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Filters referencia={referencia}
                handleSearchChange={handleSearchChange}
                fechaInicio={fechaInicio}
                handleFechaInicioChange={handleFechaInicioChange}
                fechaFin={fechaFin}
                handleFechaFinChange={handleFechaFinChange}
                estado={estado}
                handleEstadoChange={handleEstadoChange}
                selectedRows={selectedRows}
                exportToExcel={exportToExcel}
                checked={checked}
                handleChange={handleChange}
            />

            <Grid container spacing={2}>
                <Grid item size={mostrarDetalles ? 7 : 12}>
                    {/* <Paper variant="outlined" sx={{ p: 2, borderRadius: "8px", marginTop: 1, height: "69vh", overflow: "auto" }}> */}
                    <Paper variant="outlined" sx={{ padding: 1, marginTop: 1, borderRadius: "8px" }}>
                        <MaterialReactTable
                            table={table} //only pass in table instead of all table options
                            localization={MRT_Localization_ES}
                        />
                    </Paper>
                </Grid>


                <DetalleFactura mostrarDetalles={mostrarDetalles}
                    datosReferencia={datosReferencia}
                    setDatosReferencia={setDatosReferencia}
                    setMostrarDetalles={setMostrarDetalles}
                    loading={loading}
                    error={error}
                />
            </Grid>

        </div>
    )
}

export default PagosWompi