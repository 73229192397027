import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import { Grid } from '@mui/material';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import UploadIcon from '@mui/icons-material/Upload';
import CustomGridItem from '../../components/CustomGridItem';
import Grid from '@mui/material/Grid2';

const CalidadPrincipal = () => {


    const gridItems = [
        {
            icon: <ManageAccountsIcon style={{ fontSize: "45px" }} />, title: "Calidad",
            link: "/Farmanet/ParametrosCalidad",
            ruta: "Calidad"
        },
        {
            icon: <UploadIcon style={{ fontSize: "45px" }} />,
            title: " Insertar indicador",
            link: "/Farmanet/InsetarIndicadorCalidad",
            ruta: "Calidad"
        },
    ];

    return (

        <Box >
            <Grid size={{ xs: 12, sm: 6, md: 12 }} >
                <Box sx={{ alignContent: "center", justifyContent: "start", display: "flex", alignItems: "center" }}>
                    <Typography variant="body1" color="black" gutterBottom style={{
                        display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center",
                        marginBottom: "1em",
                    }}>
                        <DoneAllRoundedIcon style={{ marginRight: "5px", color: "#FE0000" }} />
                        Calidad
                    </Typography>
                </Box>
            </Grid>

            <Grid container spacing={2}>
                {gridItems.map((item, index) => (
                    <CustomGridItem key={index} {...item} />
                ))}
            </Grid>
        </Box>

    )
}

export default CalidadPrincipal