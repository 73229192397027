import React, { useState } from 'react'
import QRCode from 'qrcode.react';
import { Box, Button, Modal, TextField } from '@mui/material';

const Index = ({ open, handleClose }) => {

    const [url, setUrl] = useState('');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '0.5px solid #000',
        borderRadius: "8px",
        boxShadow: 24,
        p: 4,
    };

    const generateQRCode = () => {
        // Aquí puedes personalizar la lógica para generar el código QR según el valor de 'url'
        // Por ahora, simplemente usaremos el valor actual de 'url'
        return (
            <QRCode
                value={url}
                size={300}
                fgColor="#000000"
                // bgColor="#ffffff"
                level="L"
                id={"sameId_as_QRCode_compoent_id"}
            />
        );
    };

    const downloadCode = () => {
        const canvas = document.getElementById("sameId_as_QRCode_compoent_id");
        if (canvas) {
            const pngUrl = canvas
                .toDataURL("image/jpg")
                .replace("image/jpg", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl
            downloadLink.download = `qr${url}.jpg`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            setUrl("")
        }
    }


    return (
        <Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <TextField label="Ingresa la url" autoComplete="off" fullWidth
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        size='small' variant="outlined"
                        sx={{ marginTop: 2, marginRight: 2 }} />

                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        {generateQRCode()}
                    </div>

                    <Button variant="contained" sx={{ marginTop: 2, width: '100%' }} onClick={() => downloadCode()}>
                        Descargar
                    </Button>
                </Box>
            </Modal>
        </Box>

    );
}

export default Index;