import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import PersonPinRoundedIcon from '@mui/icons-material/PersonPinRounded';
import DirectionsBoatFilledRoundedIcon from '@mui/icons-material/DirectionsBoatFilledRounded';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BiotechIcon from '@mui/icons-material/Biotech';
import { ROUTES } from './routes';

export const NAV_ITEMS = [
    {
        path: ROUTES.dashboard,
        text: 'Dashboard',
        icon: DashboardIcon,
        index: 0
    },
    {
        path: ROUTES.gerenciaVisual,
        text: 'Gerencia Visual',
        icon: BarChartRoundedIcon,
        index: 1
    },
    {
        path: ROUTES.calidad,
        text: 'Calidad',
        icon: DoneAllRoundedIcon,
        index: 2
    },
    {
        path: ROUTES.gestionComercial,
        text: 'Gestión Comercial',
        icon: PersonPinRoundedIcon,
        index: 3
    },
    {
        path: ROUTES.importaciones,
        text: 'Importaciones',
        icon: DirectionsBoatFilledRoundedIcon,
        index: 4
    },
    {
        path: ROUTES.financiera,
        text: 'Financiera',
        icon: AttachMoneyIcon,
        index: 5
    },
    {
        path: ROUTES.tecnica,
        text: 'Técnica',
        icon: BiotechIcon,
        index: 6
    }
];