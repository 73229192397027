import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { useCallback } from 'react'

const TablaPatrimonio = ({ data }) => {


    console.log("data", data);

    const formatCurrency = useCallback((value) => {
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
        }).format(value);
    }, []);

    // Calcular el total global
    const calculateGrandTotal = (rows) => {
        return rows.reduce((sum, row) => sum + row.Total, 0);
    };

    const renderSection = (rows, label, prefix) => {
        let total = 0;
        const sectionRows = rows.filter(row => row.puccod.startsWith(prefix));

        const rowElements = sectionRows.map((row) => {
            total += row.Total;
            return (
                <TableRow key={row.puccod} sx={{ borderBottom: 'none' }}>
                    <TableCell align='left' sx={{ border: 'none' }}> {row.puccod}</TableCell>
                    <TableCell align="left" sx={{ border: 'none' }}>
                        <Typography noWrap width={"250px"} title={row.pucNom}>
                            {row.pucNom}
                        </Typography>
                    </TableCell>
                    <TableCell sx={{ border: 'none' }} align="right">{formatCurrency(row.Total)}</TableCell>
                </TableRow >
            );
        });

        return (
            <>
                <TableRow>
                    <TableCell align="left" colSpan={2} sx={{ fontWeight: 'bold', backgroundColor: '#F5F5F5', border: 'none' }}>
                        {label}
                    </TableCell>
                    <TableCell align="right" colSpan={1} sx={{ fontWeight: 'bold', backgroundColor: '#F5F5F5', border: 'none' }}>
                        {formatCurrency(total)}
                    </TableCell>
                </TableRow>
                {rowElements}
            </>
        );
    };

    // Calcular el total general
    const grandTotal = calculateGrandTotal(data);

    return (
        <div >
            <Typography variant="h6" style={{
                fontWeight: "bold",
                marginBottom: "0.5em", fontSize: "18px"
            }}>
                Patrimonio
            </Typography>
            <Table stickyHeader sx={{ minWidth: 500, mb: 2 }} size="small">
                <TableHead>
                    <TableRow >
                        <TableCell sx={{ fontWeight: "bold", color: "#71717a" }} >Cuenta</TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "#71717a" }}>Descripción</TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "#71717a" }} align='right' >Valor</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderSection(data, "Capital social", "31")}
                    {renderSection(data, "Reservas", "33")}
                    {renderSection(data, "Revalorización del patrimonio", "34")}
                    {renderSection(data, "Utilidad del ejercicio", "36")}
                    {renderSection(data, "Resultados de ejercicios anteriores", "37")}
                </TableBody>
            </Table>

            <Typography variant='h6' sx={{ fontWeight: "bold", textAlign: 'left', mt: 2, fontSize: 17 }}>
                Total patrimonio:  {formatCurrency(grandTotal)}
            </Typography>

        </div>
    )
}

export default TablaPatrimonio