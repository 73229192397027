import { Box, List, Divider, Drawer, styled } from '@mui/material';

// import { DrawerHeader } from './DrawerHeader';
// import { DrawerLateral } from './DrawerLateral';
// import { NAV_ITEMS } from '../constants/navItems';
// import { CustomListItem } from './CustomListItem';

import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';
import { NAV_ITEMS } from '../constants/navItems';
import DrawerLateral from './DrawerLateral';
import CustomListItem from './CustomListItem';

export const DrawerList = ({
    open,
    isMobile,
    selectedIndex,
    handleListItemClick,
    handleDrawerClose,
    toggleDrawer,
    openMobile
}) => {
    const theme = useTheme();

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const drawerContent = (isDesktop = false) => (
        <Box sx={{ width: 250, marginTop: isDesktop ? 0 : "3em" }} role="presentation">
            {isDesktop && (
                <>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                </>
            )}
            <List>
                {NAV_ITEMS.map((item) => (
                    <CustomListItem
                        key={item.text}
                        to={item.path}
                        text={item.text}
                        icon={item.icon}
                        index={item.index}
                        open={isDesktop ? open : true}
                        selectedIndex={selectedIndex}
                        handleListItemClick={handleListItemClick}
                        handleDrawerClose={handleDrawerClose}
                    />
                ))}
            </List>
            <Divider />
        </Box>
    );

    return (
        <>
            {/* Desktop Drawer */}
            <DrawerLateral
                variant={isMobile ? "temporary" : "permanent"}
                open={open}
                onClose={handleDrawerClose}
                sx={{
                    display: isMobile && !open ? "none" : "block",
                }}
            >
                {drawerContent(true)}
            </DrawerLateral>

            {/* Mobile Drawer */}
            <Drawer
                anchor="left"
                open={openMobile}
                onClose={toggleDrawer(false)}
                onClick={toggleDrawer(false)}
            >
                {drawerContent()}
            </Drawer>
        </>
    );
};

export default DrawerList;