import React, { useRef, useEffect } from 'react';

const WompiButton = ({ hash, referencias, sumaSaldo, autoClick }) => {
    const buttonContainerRef = useRef(null);

    useEffect(() => {
        if (!hash || !referencias || !sumaSaldo || !buttonContainerRef.current) return;

        const script = document.createElement('script');
        script.src = 'https://checkout.wompi.co/widget.js';
        script.setAttribute('data-render', 'button');
        // script.setAttribute('data-public-key', 'pub_test_WUTaW2XqEtzHtf1khZYBLMaTUyVddgwl'); // Sandbox
        script.setAttribute('data-public-key', 'pub_prod_PUCfoyJNEGPImNPeTzD5lO5r6TmBYP6C'); // Producción
        script.setAttribute('data-currency', 'COP');
        script.setAttribute('data-amount-in-cents', sumaSaldo);
        script.setAttribute('data-reference', referencias);
        script.setAttribute('data-signature:integrity', hash);
        // script.setAttribute('data-redirect-url', 'http://localhost:3000/PagosFactura'); // Producción
        script.setAttribute('data-redirect-url', 'https://www.laboratoriofarmacomercial.com/PagosFactura'); 

        buttonContainerRef.current.innerHTML = '';
        buttonContainerRef.current.appendChild(script);

        if (autoClick) {
            // Espera un pequeño delay para asegurarse que el botón se renderiza
            setTimeout(() => {
                const button = buttonContainerRef.current.querySelector('button');
                if (button) button.click();
            }, 500);
        }
    }, [hash, referencias, sumaSaldo, autoClick]);

    return <div style={{ display: "none" }} ref={buttonContainerRef}></div>;
};

export default WompiButton;

