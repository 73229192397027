import { Typography, Select, MenuItem, Box } from '@mui/material'
import React, { useState } from 'react'
import newRequest from '../../utils/newRequest'
import { useEffect } from 'react'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import CustomGridItem from '../CustomGridItem';
import Grid from '@mui/material/Grid2';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
const Adminitrador = () => {

    const [perfiles, setPerfiles] = useState([])
    const [selectedRoute, setSelectedRoute] = useState(''); // Estado para almacenar la ruta seleccionada


    const ObtenerPerfiles = async () => {
        try {
            const res = await newRequest.get("/api/admin/Perfiles");
            console.log("res adm", res.data)
            setPerfiles(res.data)
        } catch (error) {

        }
    }

    useEffect(() => {
        ObtenerPerfiles();
    }, [])


    const routes = [
        '/admin',
        '/admin/perfil/:id',
        // Agrega más rutas según sea necesario
    ];

    console.log("perfiles", perfiles)

    // Manejar el cambio en el Select
    const handleRouteChange = (event) => {
        const selectedRoute = event.target.value;
        setSelectedRoute(selectedRoute);
        // navigate(selectedRoute); // Navegar a la ruta seleccionada
    };

    const gridItems = [
        {
            icon: <ConfirmationNumberIcon style={{ fontSize: "45px" }} />,
            title: "Tickets",
            link: "/Farmanet/Administrador/Tickets",
            ruta: "Administrador"
        }
    ];

    return (
        <Box>
            <Grid size={{ xs: 12, sm: 6, md: 12 }} >
                <Box sx={{ alignContent: "center", justifyContent: "start", display: "flex", alignItems: "center" }}>
                    <Typography variant="body1" color="black" gutterBottom style={{
                        display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center",
                        marginBottom: "1em",
                    }}>
                        <AdminPanelSettingsIcon sx={{
                            marginRight: "5px",
                            color: "#FE0000",
                        }} />
                        Administrador
                    </Typography>
                </Box>
            </Grid>

            <Grid container spacing={2}>
                {gridItems.map((item, index) => (
                    <CustomGridItem key={index} {...item} />
                ))}
            </Grid>
        </Box >


    )
}

export default Adminitrador