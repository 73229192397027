import React, { useMemo } from 'react';
import CartaIndicador from './CartaIndicador/CartaIndicador';
import Grid from '@mui/material/Grid2';

const VistaEmpresa = React.memo(({ indicadores, alignment, selectAñoIncial, mes }) => {
    // Memoizar el mapeo de indicadores para evitar renders innecesarios
    const renderIndicadores = useMemo(() => (
        indicadores.map((item) => (
            <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3 }}
                key={item.ProEmpSec}>
                <CartaIndicador
                    mes={mes}
                    item={item}
                    alignment={alignment}
                    selectAñoIncial={selectAñoIncial}
                    chartId={`chart-${item.ProEmpSec}`}
                />
            </Grid>
        ))
    ), [indicadores, alignment, selectAñoIncial, mes]);

    return (
        <Grid container spacing={2}>
            {renderIndicadores}
        </Grid>
    );
});

export default VistaEmpresa;
