import React from "react";
import { Typography, Container, Box, FormControl, Select, MenuItem, TextField, Button, } from "@mui/material";
import Grid from '@mui/material/Grid2';

const LoginClient = ({
    facturas,
    dos,
    documento,
    setDocumento,
    identificacion,
    setIdentificacion,
    handleIniciarSesion,
}) => (
    <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}
            sx={{
                backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(255, 255, 255, 0.6)), url(${facturas})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                filter: 'opacity(0.9)', // Ajusta la opacidad general de la imagen
            }}
        >
            <Typography
                color="white"
                sx={{
                    fontSize: { xs: '5rem', sm: '3rem', md: '7rem' },
                    fontWeight: 'bold',
                    padding: { xs: '20px', sm: '20px', md: '20px' },
                    textAlign: { xs: 'left', md: 'left' },
                    lineHeight: 1.2
                }}
            >
                Paga tu factura con nosotros
            </Typography>
        </Grid>

        {/* Right Side - Form */}
        <Grid size={{ xs: 12, md: 6 }}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: { xs: '70vh', md: '100vh' },
                p: { xs: 2, md: 4 }
            }}
        >
            <Container maxWidth="sm" sx={{ py: { xs: 4, md: 0 } }}>
                <Box sx={{ width: '100%', maxWidth: '450px', mx: 'auto' }}>
                    {/* Logo */}
                    <Box sx={{ textAlign: 'center', mb: 4 }}>
                        <img
                            src={dos}
                            alt="Logo"
                            style={{
                                width: '100%',
                                maxWidth: '300px',
                                height: 'auto'
                            }}
                        />
                    </Box>

                    {/* Form */}
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Seleccione el tipo de documento:
                        </Typography>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <Select
                                value={documento}
                                onChange={(e) => setDocumento(e.target.value)}
                            >
                                <MenuItem value="31">Nit</MenuItem>
                                <MenuItem value="13">Cédula</MenuItem>
                                <MenuItem value="22">Cédula Extranjera</MenuItem>
                                <MenuItem value="42">Documento Extranjero</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                label="Ingrese tu número"
                                value={identificacion}
                                onChange={(e) => setIdentificacion(e.target.value)}
                                fullWidth
                            />
                        </FormControl>

                        <Button
                            onClick={handleIniciarSesion}
                            variant="contained"
                            fullWidth
                            size="large"
                            sx={{
                                bgcolor: '#212B36',
                                py: 1.5,
                                mb: 3
                            }}
                        >
                            Continuar
                        </Button>

                        {/* Contact Info */}
                        <Typography
                            variant="body2"
                            sx={{
                                textAlign: 'left',
                                color: 'text.secondary',
                                '& span': {
                                    color: '#3056d3',
                                    fontWeight: 'bold',
                                    display: 'block',
                                    mt: 1
                                }
                            }}
                        >
                            Tienes problemas para iniciar sesión. Comunícate con nosotros al correo
                            <span>servicioalcliente@laboratoriofarmacomercial.com</span>
                            o al número
                            <span>316 290 8167</span>
                        </Typography>
                    </form>
                </Box>
            </Container>
        </Grid>
    </Grid>
);
export default LoginClient;